import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import 'date-fns';
import { useSnackbar, withSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import CpEditarCirurgia from '../../components/cp-cirurgias/cp-editar-cirurgia';
import AssistidoService from '../../services/assistidos';

const PgEditarCirurgia = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const [assistido, setAssistido] = useState(null);

    const service = new AssistidoService();

    useEffect(() => {
        const cirurgiaId = props.match.params.id;
        if (cirurgiaId) {
            service.procurarAssistidoPorCirurgiaId(cirurgiaId)
                .then(res => {
                    console.log("Assistido encontrado", res);
                    setAssistido(res);
                }).catch(err => {
                    enqueueSnackbar(`Ocorreu um problema ao obter a cirurgia ${cirurgiaId}`, {
                        variant: 'error',
                    });
                    console.log("Erro ao carregar cirurgia", err);
                });
        }
    }, [props.match.params.id]);

    if (!assistido) {
        return <></>;
    }
    
    const cirurgia = assistido.cirurgias?.find(c => c.id == props.match.params.id);
    
    return (
        <>
            <Paper className="acoes" style={{ padding: '10px 20px', margin: '10px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h4'>Editar Cirurgia</Typography>
            </Paper>
            <Paper className="app-form">
                <CpEditarCirurgia cirurgia={cirurgia} assistido={assistido} cirurgias={assistido.cirurgias} />
            </Paper>
        </>
    );
}

export default withSnackbar(PgEditarCirurgia);