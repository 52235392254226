import {
    Grid,
    Paper,
    Typography,
    makeStyles
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import FeriadosService from '../../services/feriados';
import CpEditarFeriado from './cp-editar-feriado';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
}));

const PgEditarFeriados = () => {
    const classes = useStyles();
    const { id } = useParams();
    const service = new FeriadosService();
    const history = useHistory();

    const [feriados, setFeriados] = useState([]);

    useEffect(() => {
        if (id) {
            service.obterLista()
                .then(res => {
                    if (!res.length || res.filter(c => c.id == id).length !== 1) {
                        history.push('/feriados');
                    } else {
                        console.log(res);
                        setFeriados(res);
                    }
                })
                .catch(err => {
                    history.push('/feriados');
                });
        }
    }, [id]);

    return (
        <>
            <Paper className="acoes" style={{ padding: '10px 20px', margin: '10px 0', backgroundColor: '', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h4'>Editar Feriado</Typography>
            </Paper>
            <Paper className="app-form">
                <div className={classes.root}>
                    {!!feriados ? (
                        <CpEditarFeriado lista={feriados} id={id} />
                    ) : (
                        <h2>Carregando...</h2>
                    )}
                </div>
            </Paper>
        </>
    );
};

export default PgEditarFeriados;