import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';

const useStyles = makeStyles((theme) => ({
    accordion: {
        width: '100%',
    },
    accordionSummary: {
        flexDirection: 'row-reverse',
        padding: '0 8px', // Reduced padding
        minHeight: '30px !important', // Reduced minHeight
        '&.Mui-expanded': {
            minHeight: '30px !important',
        },
    },
    accordionSummaryContent: {
        margin: '4px 0', // Reduced margin
        '&.Mui-expanded': {
            margin: '4px 0', // Consistent margin when expanded
        },
    },
    expandIcon: {
        order: -1,
    },
    accordionDetails: {
        display: 'block',
        padding: '8px 8px', // Reduced padding
    },
}));

export default function CpDescricaoSintoma({ sintoma }) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);

    const handleChange = () => {
        setExpanded((prev) => !prev);
    };

    return (
        <Accordion key={sintoma} className={classes.accordion} expanded={expanded} onChange={handleChange}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                classes={{
                    root: classes.accordionSummary,
                    expandIcon: classes.expandIcon,
                    content: classes.accordionSummaryContent,
                }}
            >
                {expanded ? (
                    <div>
                        <Typography variant="body2">
                            <strong>Resumo dos sintomas</strong>
                        </Typography>
                        <Typography variant="body2">
                            {sintoma.descricao ? sintoma.descricao : 'Não informado'}
                        </Typography>
                    </div>
                ) : (
                    <Typography variant="body2">
                        {!!sintoma.descricao
                            ? `${sintoma.descricao.substring(0, 500)}${sintoma.descricao.length > 500 ? '...' : ''}`
                            : `${sintoma.descricaoAssistido.substring(0, 500)}${sintoma.descricaoAssistido.length > 500 ? '...' : ''}`}
                    </Typography>
                )}
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
                <div>
                    <Typography variant="body2">
                        <strong>Sintomas enviados pelo assistido</strong>
                    </Typography>
                    <Typography variant="body2">
                        {sintoma.descricaoAssistido ? sintoma.descricaoAssistido : 'Não informado'}
                    </Typography>
                </div>
            </AccordionDetails>
        </Accordion>
    );
}
