import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import 'date-fns';
import { withSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import CpEditarSintoma from '../../components/cp-sintomas/cp-editar-sintoma';
import SintomaService from '../../services/sintomas';

const PgEditarSintoma = (props) => {
    const [sintoma, setSintoma] = useState(null);
    const [assistidoId, setAssistidoId] = useState(null);
    const service = new SintomaService();

    useEffect(() => {
        const id = props.match.params.id;
        if (id) {
            service.procurarSintomaPorId(id)
                .then(res => {
                    setSintoma(res);
                    setAssistidoId(res.assistidoId);
                })
                .catch(err => {
                    console.log("Erro ao carregar sintoma", err);
                });
        }
    }, [props.match.params.id]);

    if (!assistidoId) {
        return <h2>Carregando...</h2>;
    }

    return (
        <main>
            <Paper className="acoes" style={{ padding: '10px 20px', margin: '10px 0', backgroundColor: '', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h4'>Editar Sintoma</Typography>
            </Paper>
            <Paper className="app-form">
                <CpEditarSintoma sintoma={sintoma} assistidoId={assistidoId} />
            </Paper>
        </main>
    );
}

export default withSnackbar(PgEditarSintoma);