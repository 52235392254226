import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AssistidoIcon from '@material-ui/icons/ContactMailOutlined';
import RelatorioPresencialIcon from '@material-ui/icons/DescriptionOutlined';
import TratamentosIcon from '@material-ui/icons/HealingOutlined';
import CirurgiasIcon from '@material-ui/icons/LocalHospitalOutlined';
import RelatorioEmailIcon from '@material-ui/icons/MailOutlineOutlined';
import SintomasIcon from '@material-ui/icons/RecordVoiceOverOutlined';
import PesquisarAssistidoIcon from '@material-ui/icons/SearchOutlined';
import React, { useState } from "react";

const Acao = {
    PesquisarAssistido: 'PesquisarAssistido',
    Assistido: 'Assistido',
    Sintomas: 'Sintomas',
    Cirurgias: 'Cirurgias',
    Tratamentos: 'Tratamentos',
    RelatorioPresencial: 'RelatorioPresencial',
    RelatorioEmail: 'RelatorioEmail',
};

const CpAcoesAssistido = ({ assistido, iconSize, destacarAcao }) => {
    const [openPresencial, setOpenPresencial] = useState(false);
    const [openEmail, setOpenEmail] = useState(false);

    const togglePresencial = () => {
        setOpenPresencial(!openPresencial);
    };

    const toggleEmail = () => {
        setOpenEmail(!openEmail);
    };

    const getButtonStyle = (acao) => ({
        backgroundColor: destacarAcao === acao ? 'lavender' : '',
    });

    if (!assistido?.id) {
        return <></>;
    }

    return (
        <div>
            <Tooltip title="Assistido">
                <IconButton href={`/assistidos/${assistido.id}`} style={getButtonStyle(Acao.Assistido)}>
                    <AssistidoIcon fontSize={iconSize} color='primary' />
                </IconButton>
            </Tooltip>
            <Tooltip title="Sintomas">
                <IconButton href={`/assistidos/${assistido.id}/sintomas`} style={getButtonStyle(Acao.Sintomas)}>
                    <SintomasIcon fontSize={iconSize} color='primary' />
                </IconButton>
            </Tooltip>
            <Tooltip title="Cirurgias">
                <IconButton href={`/assistidos/${assistido.id}/cirurgias`} style={getButtonStyle(Acao.Cirurgias)}>
                    <CirurgiasIcon fontSize={iconSize} color='primary' />
                </IconButton>
            </Tooltip>
            <Tooltip title="Tratamentos">
                <IconButton href={`/assistidos/${assistido.id}/tratamentos`} style={getButtonStyle(Acao.Tratamentos)}>
                    <TratamentosIcon fontSize={iconSize} color='primary' />
                </IconButton>
            </Tooltip>
            <Tooltip title="Relatório Presencial">
                <IconButton href={`/assistidos/${assistido.id}/relatorio-presencial`} style={getButtonStyle(Acao.RelatorioPresencial)}>
                    <RelatorioPresencialIcon fontSize={iconSize} style={{ color: '#cd8d00' }} />
                </IconButton>
            </Tooltip>
            <Tooltip title="Relatório Email">
                <IconButton href={`/assistidos/${assistido.id}/relatorio-email`} style={getButtonStyle(Acao.RelatorioEmail)}>
                    <RelatorioEmailIcon fontSize={iconSize} style={{ color: '#cd8d00' }} />
                </IconButton>
            </Tooltip>
        </div>
    );
};

export default CpAcoesAssistido;
export { Acao };