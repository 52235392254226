import api from "../api_env_config";
import { URL_CADASTROS_INTERNET } from "../api_env_config";

class CadastrosInternetService {

    aprovarCadastro = async (id) => {
        return new Promise((resolve, reject) => {
            const url = `${URL_CADASTROS_INTERNET}/${id}`;

            api.post(url).then(response => {
                resolve(response.data.data);
            })
            .catch(err => {
                reject("Error: " + err);
            });
        });
    };

    editarEAprovarCadastro = async (id, cadastro) => {
        return new Promise((resolve, reject) => {
            const url = `${URL_CADASTROS_INTERNET}/${id}/aprovar`;

            api.put(url, cadastro).then(response => {
                resolve(response.data.data);
            })
            .catch(err => {
                reject("Error: " + err);
            });
        });
    };

    listarCadastrosInternet = async (pagina, quantidade) => {
        return new Promise((resolve, reject) => {
            if (pagina === undefined || pagina === null || pagina === "") {
                pagina = 1
            }

            if (quantidade === undefined || quantidade === null || quantidade === "") {
                quantidade = 10
            }

            const url = `${URL_CADASTROS_INTERNET}?pagina=${pagina}&quantidade_por_pagina=${quantidade}`;

            api.get(url).then(response => {
                resolve(response.data.data);
            })
            .catch(err => {
                reject("Error: " + err);
            })
        })
    };

    procurarCadastroPorId = async (id) => {
        return new Promise((resolve, reject) => {
            const url = `${URL_CADASTROS_INTERNET}/${id}`;

            api.get(url).then(response => {
                resolve(response.data.data);
            })
            .catch(err => {
                reject("Error: " + err);
            });
        });
    }

}

export default CadastrosInternetService;
 