import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import PropTypes from 'prop-types';
import React from 'react';
import { isoDateToStrDate } from '../../services/date-utils';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center'
    }
}));

export default function CpDeletarCirurgiaAlert({ abrirModalDeletar, fecharModalDeletar, cirurgiasParaDeletar, onConfirmarDeletarCirurgia }) {
    const [modalAberto, setModalAberto] = React.useState(false);

    const classes = useStyles();

    const onClose = () => {
        setModalAberto(false);
        if (fecharModalDeletar) {
            fecharModalDeletar();
        }
    };

    const handleClose = () => {
        onClose();
    };

    const onConfirmarDeletar = () => {
        if (onConfirmarDeletarCirurgia && typeof onConfirmarDeletarCirurgia === 'function') {
            onConfirmarDeletarCirurgia();
        } else {
            console.error('Nenhum callback para remover a cirurgia foi informado');
            // Aqui você pode exibir uma mensagem de erro ao usuário, se necessário
        }
    };

    React.useEffect(() => {
        setModalAberto(abrirModalDeletar === true);
    }, [abrirModalDeletar]);

    let cirurgiasParaDeletarList = null;
    if (cirurgiasParaDeletar && cirurgiasParaDeletar.length > 0) {
        cirurgiasParaDeletarList = (
            <ul>
                {cirurgiasParaDeletar.map(cirurgia => (
                    <li key={cirurgia.id}>
                        <strong>{isoDateToStrDate(cirurgia.dataAgendada)} {cirurgia.horarioAgendado.split(':').slice(0, 2).join(':')}h - {cirurgia.descricao}</strong>
                    </li>
                ))}
            </ul>
        );
    }

    return (
        <div>
            <Dialog
                open={modalAberto}
                onClose={onClose}
            >
                <DialogTitle id="alert-dialog-title">
                    <div className={classes.titleContainer}>
                        <WarningIcon color="error" fontSize="large" />
                        <span style={{ marginLeft: '0.5em' }}>{cirurgiasParaDeletar.length === 1 ? 'Remover cirurgia' : 'Remover cirurgias'}</span>
                    </div>
                </DialogTitle>
                <DialogContent>
                    Tem certeza que deseja deletar as seguintes cirurgias?
                    <br />
                    {cirurgiasParaDeletarList}
                    * Esta ação não poderá ser desfeita!
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        startIcon={<DeleteIcon />}
                        onClick={onConfirmarDeletar}
                    >
                        Deletar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

CpDeletarCirurgiaAlert.propTypes = {
    abrirModalDeletar: PropTypes.bool.isRequired,
    fecharModalDeletar: PropTypes.func,
    cirurgiasParaDeletar: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        descricao: PropTypes.string.isRequired,
        dataAgendada: PropTypes.string.isRequired,
        horarioAgendado: PropTypes.string.isRequired,
    })),
    onConfirmarDeletarCirurgia: PropTypes.func,
};
