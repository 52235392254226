import { withSnackbar } from 'notistack';
import React from 'react';
import CpRelatorioCirurgias from '../../components/cp-cirurgias/cp-relatorio-cirurgias';

const PgRelatorioCirurgias = ({ }) => {

    return (
        <div className="App">
            <h1>Relatório de Cirurgias</h1>
            <CpRelatorioCirurgias />
        </div>
    );
}

export default withSnackbar(PgRelatorioCirurgias);
