import DateFnsUtils from '@date-io/date-fns';
import { MenuItem, Select, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import AddBoxIcon from '@material-ui/icons/AddBox';
import SaveIcon from '@material-ui/icons/Save';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { addDays, format, parseISO } from 'date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from "react";
import { useHotkeys } from 'react-hotkeys-hook';
import { Link, useHistory } from "react-router-dom";
import Atalhos from '../../pages/pg-cirurgias/atalhos-teclado';
import CirurgiaService from '../../services/cirurgias';
import { DatePattern, isoDateAndJsDatetimeToDatetime, isoDateToJsDate, isoDateToStrDate, isoDateToStrDayAndStrDate, isoTimeToStrTime, jsDateToIsoDate, jsDateToIsoTime, strDateAndStrTimeToJsDate, strTimeToIsoTime } from '../../services/date-utils';
import { Local, Posicao } from '../assistidos/enums';


export default function CpEditarCirurgia(props) {
    const service = new CirurgiaService();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const editando = !!props.cirurgia;

    const valoresIniciais = editando ? {
        descCirurgia: props.cirurgia.descricao,
        preparacaoCostas: props.cirurgia.preparacaoCostas,
        preparacaoBrucos: props.cirurgia.preparacaoBrucos,
        preparacaoLado: props.cirurgia.preparacaoLado,
        preparacaoMaisConfortavel: props.cirurgia.preparacaoMaisConfortavel,
        data: isoDateToJsDate(props.cirurgia.dataAgendada),
        hora: props.cirurgia.horarioAgendado,
        local: Local.porDbVal(props.cirurgia.localAtendimento).boolVal,
    } : {
        descCirurgia: '',
        preparacaoCostas: false,
        preparacaoBrucos: false,
        preparacaoLado: false,
        preparacaoMaisConfortavel: false,
        data: null,
        hora: '',
        local: props.assistido.ultimoSintoma ? Local.porDbVal(props.assistido.ultimoSintoma.localTratamento).boolVal : false
    };

    const [descCirurgia, setDescCirurgia] = useState(valoresIniciais.descCirurgia);
    const [preparacaoCostas, setPreparacaoCostas] = useState(valoresIniciais.preparacaoCostas);
    const [preparacaoBrucos, setPreparacaoBrucos] = useState(valoresIniciais.preparacaoBrucos);
    const [preparacaoLado, setPreparacaoLado] = useState(valoresIniciais.preparacaoLado);
    const [preparacaoMaisConfortavel, setPreparacaoMaisConfortavel] = useState(valoresIniciais.preparacaoMaisConfortavel);
    const [local, setLocal] = useState(valoresIniciais.local);
    const [agendamentoAutomatico, setAgendamentoAutomatico] = useState(!editando);
    const [novasCirurgias, setNovasCirurgias] = useState([]);

    // Dados dos feriados
    const [considerarFeriados, setConsiderarFeriados] = useState(true);
    const [feriadosPorDataSubstituicao, setFeriadosPorDataSubstituicao] = useState({});
    const [feriadosPorDataFeriado, setFeriadosPorDataFeriado] = useState({});

    // Selecao manual de data/hora
    const [data, setData] = useState(valoresIniciais.data);
    const [hora, setHora] = useState(valoresIniciais.hora);

    // Selecao automatica de data/hora
    const [dataInicio, setDataInicio] = useState('');
    const [datasProximasPorData, setDatasProximasPorData] = useState({});

    const cirurgiaRef = useRef();

    const salvarRedirecionarSintomas = () => {
        salvarCirurgia(`/assistidos/${props.assistido.id}/sintomas`);
    }

    const salvarRedirecionarTratamentos = () => {
        salvarCirurgia(`/assistidos/${props.assistido.id}/tratamentos`);
    }

    const salvarCirurgiaOnClick = () => {
        if (editando) {
            salvarCirurgia(`/assistidos/${props.assistido.id}/cirurgias`);
        } else {
            salvarCirurgia('');
        }
    }

    useEffect(() => {
        cirurgiaRef.current.focus();
    }, []);

    useEffect(() => {
        if (!editando) {
            const tipo = props.assistido.tipo;
            const localVal = Local.porBoolVal(local).dbVal;
            service.obterProximaData(tipo, localVal)
                .then(data => {
                    if (!!data.feriados?.length) {
                        const feriadosPorDataFeriado = data.feriados.reduce((map, feriado) => {
                            map[feriado.dataFeriado] = feriado;
                            return map;
                        }, {});

                        setFeriadosPorDataFeriado(feriadosPorDataFeriado);

                        const feriadosPorDataSubstituicao = data.feriados.reduce((map, feriado) => {
                            map[feriado.dataSubstituicao] = feriado;
                            return map;
                        }, {});
                        setFeriadosPorDataSubstituicao(feriadosPorDataSubstituicao);
                    }
                    if (!!data.datas?.length) {
                        //cria hashmap para calculo das datas
                        let primeiraDataDisponivel = '';
                        let primeiraHoraDisponivel = '';
                        const datasProximasPorData = {};
                        data.datas.forEach(d => {
                            const [date, time] = d.datahora.split("T");
                            if (!primeiraDataDisponivel) {
                                primeiraDataDisponivel = date;
                                primeiraHoraDisponivel = time;
                            }
                            if (!datasProximasPorData[date]) {
                                datasProximasPorData[date] = [];
                            }
                            d['hora'] = time;
                            datasProximasPorData[date].push(d);
                        });
                        setDatasProximasPorData(datasProximasPorData);

                        //pre seleciona primeira data disponivel no listbox
                        setDataInicio(primeiraDataDisponivel);
                        //Deixa data manual pre programada
                        setData(new Date(primeiraDataDisponivel));
                        setHora(primeiraHoraDisponivel);
                    } else {
                        setAgendamentoAutomatico(false);
                        enqueueSnackbar('Não existem datas disponíveis para agendamento automático!', {
                            variant: 'error',
                        });
                    }
                }).catch(err => {
                    setAgendamentoAutomatico(false);
                    enqueueSnackbar('Não foi possível obter as datas disponíveis para agendamento automático!', {
                        variant: 'error',
                    });
                });
        }
    }, [local, props.cirurgias]);

    useEffect(() => {
        const dadosCalculoAgendamento = extractDadosCalculoAgendamento();
        setNovasCirurgias(calcularNovasCirurgias(dadosCalculoAgendamento));
    }, [descCirurgia, preparacaoCostas, preparacaoBrucos, preparacaoLado, preparacaoMaisConfortavel, dataInicio, data, hora, local, agendamentoAutomatico, considerarFeriados, props.cirurgias]);

    const onHorarioAutomaticoChange = (e) => {
        const newValue = !agendamentoAutomatico;
        setAgendamentoAutomatico(newValue);
        if (newValue) {
            setConsiderarFeriados(true);
        }
    }

    const onDescCirurgiaChange = (e) => {
        setDescCirurgia(e.target.value);
    }

    const MULTI_CIRURGIAS_PATTERN = /(\d*)[dD]?\s*\(\s*?(\d+)[xX]\s*?(\d+)[dD][dD]?\s*?\)\s*([^\[]+)(?:\[(.+)\])?/;

    const extractDadosCalculoAgendamento = () => {
        const dadosAgendamento = [];
        if (!editando) {
            const linhas = descCirurgia.split('\n');

            linhas.forEach((linha) => {
                const match = MULTI_CIRURGIAS_PATTERN.exec(linha.trim());
                if (match !== null) {
                    const deslocamento = match[1] ? parseInt(match[1]) : 0;
                    const quantidade = parseInt(match[2]);
                    const frequencia = parseInt(match[3]);
                    const desc = match[4].trim();
                    const matchedPosicoes = match[5];

                    let posicaoLado = matchedPosicoes ? matchedPosicoes.toUpperCase().includes('L') : preparacaoLado;
                    let posicaoCostas = matchedPosicoes ? matchedPosicoes.toUpperCase().includes('C') : preparacaoCostas;
                    let posicaoBrucos = matchedPosicoes ? matchedPosicoes.toUpperCase().includes('B') : preparacaoBrucos;
                    let posicaoMaisConfortavel = matchedPosicoes ? matchedPosicoes.toUpperCase().includes('M') : preparacaoMaisConfortavel;

                    dadosAgendamento.push({
                        deslocamento,
                        quantidade,
                        frequencia,
                        desc,
                        preparacaoLado: posicaoLado,
                        preparacaoCostas: posicaoCostas,
                        preparacaoBrucos: posicaoBrucos,
                        preparacaoMaisConfortavel: posicaoMaisConfortavel
                    });
                }
            });
        }
        if (!dadosAgendamento.length && descCirurgia) {
            dadosAgendamento.push({
                deslocamento: 0,
                quantidade: 1,
                frequencia: 1,
                desc: descCirurgia,
                preparacaoLado,
                preparacaoCostas,
                preparacaoBrucos,
                preparacaoMaisConfortavel
            });

        }
        return dadosAgendamento;
    };

    const simpleHash = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            const char = str.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }

    const substituirIntervalo = {
        15: 14,
        30: 28
    }
    const calcularNovasCirurgias = (dadosCalculoAgendamento) => {
        if (!dadosCalculoAgendamento.length || (!agendamentoAutomatico && (!data || !hora))) {
            return [];
        }
        const novasCirurgias = [];
        dadosCalculoAgendamento.forEach(dadosCalculo => {
            const deslocamento = substituirIntervalo.hasOwnProperty(dadosCalculo.deslocamento) ? substituirIntervalo[dadosCalculo.deslocamento] : dadosCalculo.deslocamento;
            const frequencia = substituirIntervalo.hasOwnProperty(dadosCalculo.frequencia) ? substituirIntervalo[dadosCalculo.frequencia] : dadosCalculo.frequencia;
            const quantidade = dadosCalculo.quantidade;


            //TODO usar somente strings para melhorar performance
            let dataInicial = agendamentoAutomatico ? parseISO(dataInicio) : strDateAndStrTimeToJsDate(data, hora);

            // Se a data inicial for feriado, volta a data de calculo para a data do feriado para que os intervalos entre as datas sejam calculados corretamente
            // A substituicao sera aplicada novamente no final da logica
            if (considerarFeriados) {
                const dataInicialKey = format(dataInicial, DatePattern.YYYY_MM_DD);
                const feriado = feriadosPorDataSubstituicao[dataInicialKey];
                if (feriado) {
                    dataInicial = isoDateAndJsDatetimeToDatetime(feriado.dataFeriado, dataInicial);
                }
            }

            if (dadosCalculo.deslocamento) {
                dataInicial = addDays(dataInicial, deslocamento);
            }
            const datas = obterDatasParaAgendamento(frequencia, quantidade, dataInicial);

            datas.forEach(dataNovaCirurgia => {
                const datahoraCirurgia = dataNovaCirurgia;
                let dataCirurgiaStr = jsDateToIsoDate(datahoraCirurgia);
                let dataOriginal = null;
                let nomeFeriado = null;
                if (considerarFeriados && feriadosPorDataFeriado[dataCirurgiaStr]) {
                    dataOriginal = dataCirurgiaStr;
                    dataCirurgiaStr = feriadosPorDataFeriado[dataOriginal].dataSubstituicao;
                    nomeFeriado = feriadosPorDataFeriado[dataOriginal].nome;
                }

                let horaCirurgiaStr = jsDateToIsoTime(datahoraCirurgia);

                let erro = '';
                if (props.cirurgias.find(c => c.dataAgendada === dataCirurgiaStr && (!editando || c.id !== props.cirurgia.id))) {
                    erro = 'Já existe uma cirurgia cadastrada para esta data!';
                }
                if (agendamentoAutomatico) {
                    const dataDisponivel = datasProximasPorData[dataCirurgiaStr];
                    if (dataDisponivel) {
                        const horaDisponivel = datasProximasPorData[dataCirurgiaStr][0]['hora'];
                        if (horaDisponivel) {
                            horaCirurgiaStr = horaDisponivel;
                        } else {
                            erro = `Não existem horários disponíveis!`;
                        }
                    } else {
                        erro = `Não existem horários disponíveis!`;
                    }
                }

                const novaCirurgia = {
                    assistidoId: props.assistido.id,
                    descricao: dadosCalculo.desc,
                    dataAgendada: dataCirurgiaStr,
                    horarioAgendado: horaCirurgiaStr,
                    localAtendimento: Local.porBoolVal(local).dbVal,
                    preparacaoLado: dadosCalculo.preparacaoLado,
                    preparacaoCostas: dadosCalculo.preparacaoCostas,
                    preparacaoBrucos: dadosCalculo.preparacaoBrucos,
                    preparacaoMaisConfortavel: dadosCalculo.preparacaoMaisConfortavel,
                    dataOriginal,
                    nomeFeriado,
                    erro
                };
                novasCirurgias.push(novaCirurgia);
            });

            //React hack
            novasCirurgias.forEach(cirurgia => {
                cirurgia.hash = simpleHash(cirurgia.descricao) +
                    cirurgia.dataAgendada +
                    cirurgia.horarioAgendado +
                    cirurgia.localAtendimento +
                    cirurgia.preparacaoLado +
                    cirurgia.preparacaoCostas +
                    cirurgia.preparacaoBrucos +
                    cirurgia.preparacaoMaisConfortavel;
            });

        });

        novasCirurgias.sort((a, b) => {
            let dateA = new Date(`${a.dataAgendada}T${a.horarioAgendado}`);
            let dateB = new Date(`${b.dataAgendada}T${b.horarioAgendado}`);
            return dateA - dateB;
        });

        return novasCirurgias;
    }

    const obterDatasParaAgendamento = (frequencia, quantidade, dataInicial) => {
        const datas = [];
        for (let i = 0; i < quantidade; i++) {
            if (i === 0) {
                datas.push(dataInicial);
            } else {
                const diasParaAdicionar = i * frequencia;
                const proximaData = new Date(dataInicial);
                proximaData.setDate(proximaData.getDate() + diasParaAdicionar);
                datas.push(proximaData);
            }
        }
        return datas;
    }

    const criarCirurgia = (redirectUrl, novasCirurgias) => {
        //TODO move to a generic file
        const handleError = (error) => {
            console.log(error);
        };
        service.adicionarCirurgias(novasCirurgias, false)
            .then((resCirurgias) => {
                if (props.onAdicionarCirurgia && typeof props.onAdicionarCirurgia === 'function') {
                    props.onAdicionarCirurgia(resCirurgias);
                }
                limparCampos();
                if (redirectUrl) {
                    history.push(redirectUrl);
                }
                enqueueSnackbar('Cirurgias adicionadas com sucesso!', {
                    variant: 'success',
                });
            })
            .catch(handleError);
    }

    const atualizarCirurgia = (redirectUrl, id, novaCirurgia) => {
        service.editarCirurgia(id, novaCirurgia)
            .then(resCirurgias => {
                history.push(redirectUrl);
                enqueueSnackbar('Cirurgia atualizada com sucesso', {
                    variant: 'success',
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    const validarCirurgia = (cirurgia) => {
        let valido = true;
        if (cirurgia.erro) {
            enqueueSnackbar('Existem erros relacionados à(s) cirurgia(s)!', {
                variant: 'error',
            });
            valido = false;
        }
        if (!cirurgia.descricao) {
            enqueueSnackbar('Informe a descrição da cirurgia!', {
                variant: 'error',
            });
            valido = false;
        }
        if (!cirurgia.preparacaoCostas && !cirurgia.preparacaoBrucos && !cirurgia.preparacaoLado && !cirurgia.preparacaoMaisConfortavel) {
            const descErrorMsg = !!cirurgia.descricao ? `Informe uma posição para a cirurgia ${cirurgia.descricao}!` : `Informe uma posição!`;
            enqueueSnackbar(descErrorMsg, {
                variant: 'error',
            });
            valido = false;
        }
        if (agendamentoAutomatico && !dataInicio) {
            enqueueSnackbar('Informe a Data Cirurgia!', {
                variant: 'error',
            });
            valido = false;
        }
        console.log({ data, hora });
        if (!agendamentoAutomatico && (!data || !hora)) {
            enqueueSnackbar('Informe a Data e Hora da cirurgia!', {
                variant: 'error',
            });
            valido = false;
        }
        if (!agendamentoAutomatico && hora) {
            const [horas, minutos] = hora.split(':');
            if (minutos % 15 !== 0) {
                enqueueSnackbar('Informe um horário válido em intervalos de 15 minutos. Ex: 19:00, 19:15, 19:30, 19:45', {
                    variant: 'error',
                });
                valido = false;
            }
        }
        return valido;
    }

    const salvarCirurgia = (redirectUrl) => {
        if (editando) {
            const cirurgiaParaAtualizar = {
                assistidoId: props.assistido.id,
                descricao: descCirurgia, //TODO normalizar
                dataAgendada: data ? jsDateToIsoDate(data) : data,
                horarioAgendado: hora ? strTimeToIsoTime(hora) : hora,
                localAtendimento: local ? Local.porBoolVal(local).dbVal : local,
                preparacaoLado: preparacaoLado,
                preparacaoCostas: preparacaoCostas,
                preparacaoBrucos: preparacaoBrucos,
                preparacaoMaisConfortavel: preparacaoMaisConfortavel
            };
            if (!validarCirurgia(cirurgiaParaAtualizar)) {
                return;
            }

            atualizarCirurgia(redirectUrl, props.cirurgia.id, cirurgiaParaAtualizar);
        } else if (!novasCirurgias.length) {
            enqueueSnackbar('Informe todos os campos para criação da cirurgia!', {
                variant: 'error',
            });
        } else {
            const cirurgiasParaSalvar = [];
            let valido = true;
            for (let i = 0; i < novasCirurgias.length; i++) {
                const novaCirurgia = novasCirurgias[i];
                if (validarCirurgia(novaCirurgia)) {
                    cirurgiasParaSalvar.push({
                        assistidoId: novaCirurgia.assistidoId,
                        descricao: novaCirurgia.descricao,
                        dataAgendada: novaCirurgia.dataAgendada,
                        horarioAgendado: novaCirurgia.horarioAgendado,
                        localAtendimento: novaCirurgia.localAtendimento,
                        preparacaoLado: novaCirurgia.preparacaoLado,
                        preparacaoCostas: novaCirurgia.preparacaoCostas,
                        preparacaoBrucos: novaCirurgia.preparacaoBrucos,
                        preparacaoMaisConfortavel: novaCirurgia.preparacaoMaisConfortavel
                    });
                } else {
                    valido = false;
                }
            }
            if (!valido) {
                return;
            }
            criarCirurgia(redirectUrl, cirurgiasParaSalvar);
        }
    };

    const limparCampos = () => {
        setDescCirurgia(valoresIniciais.descCirurgia);
        setPreparacaoCostas(valoresIniciais.preparacaoCostas);
        setPreparacaoBrucos(valoresIniciais.preparacaoBrucos);
        setPreparacaoLado(valoresIniciais.preparacaoLado);
        setPreparacaoMaisConfortavel(valoresIniciais.preparacaoMaisConfortavel);

        setData(valoresIniciais.data);
        setLocal(valoresIniciais.local);
    }

    useHotkeys(Atalhos.SALVAR_IR_SINTOMAS.sequence, salvarRedirecionarSintomas, { keyup: true });
    useHotkeys(Atalhos.SALVAR_IR_TRATAMENTOS.sequence, salvarRedirecionarTratamentos, { keyup: true });
    useHotkeys(Atalhos.FOCAR_CIRURGIA.sequence, () => cirurgiaRef.current.focus(), { keyup: true });
    useHotkeys(Atalhos.TOGGLE_LOCAL.sequence, () => setLocal(!local), { keyup: true });
    useHotkeys(Atalhos.TOGGLE_COSTAS.sequence, () => setPreparacaoCostas(!preparacaoCostas), { keyup: true });
    useHotkeys(Atalhos.TOGGLE_LADO.sequence, () => setPreparacaoLado(!preparacaoLado), { keyup: true });
    useHotkeys(Atalhos.TOGGLE_BRUCOS.sequence, () => setPreparacaoBrucos(!preparacaoBrucos), { keyup: true });
    useHotkeys(Atalhos.TOGGLE_MAIS_CONFORTAVEL.sequence, () => setPreparacaoMaisConfortavel(!preparacaoMaisConfortavel), { keyup: true });

    const renderNovasCirurgias = () => {
        if (editando || !novasCirurgias?.length) {
            return <></>
        }
        return (
            <Paper elevation={2} style={{ padding: '5px' }}>
                {novasCirurgias.map((novaCirurgia, index) => (
                    <div key={novaCirurgia.hash} style={novaCirurgia.erro ? { margin: '5px', fontWeight: 'bold', color: 'red' } : { margin: '5px' }}>
                        &emsp;{index + 1}&nbsp;-&nbsp;
                        <strong>{isoDateToStrDayAndStrDate(novaCirurgia.dataAgendada)}</strong> {isoTimeToStrTime(novaCirurgia.horarioAgendado)}h {novaCirurgia.descricao}
                        &nbsp;[{Posicao.getListaStr(novaCirurgia)}]
                        {novaCirurgia.dataOriginal && (
                            <span style={{ color: 'blue', fontWeight: 'bold' }}>
                                &nbsp;Remarcação Feriado: {isoDateToStrDate(novaCirurgia.dataOriginal)} - {novaCirurgia.nomeFeriado}
                            </span>
                        )}
                        {novaCirurgia.erro && ` Erro: ${novaCirurgia.erro}`}
                    </div>
                ))}
            </Paper>
        )
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={4} sm={4} lg={3} style={!editando ? {} : { display: 'none' }}>
                <FormControl fullWidth>
                    <InputLabel shrink>
                        Agendamento
                    </InputLabel>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={agendamentoAutomatico}
                                onChange={onHorarioAutomaticoChange}
                                value={agendamentoAutomatico}
                                disabled={editando}
                                color="primary"
                            />
                        }
                        className="bottom-offset"
                        label={"Automático"}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={4} sm={4} lg={3}>
                <FormControl fullWidth>
                    <InputLabel shrink>
                        Local do tratamento
                    </InputLabel>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={local}
                                onChange={() => setLocal(!local)}
                                value={local}
                                color="primary"
                            />
                        }
                        label="Casa do Consolador"
                        className="bottom-offset"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={4} sm={4} lg={3} style={!editando ? {} : { display: 'none' }}>
                <FormControl fullWidth>
                    <InputLabel shrink>
                        Feriados
                    </InputLabel>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={considerarFeriados}
                                onChange={() => setConsiderarFeriados(!considerarFeriados)}
                                value={considerarFeriados}
                                color="primary"
                            />
                        }
                        disabled={agendamentoAutomatico}
                        label="Considerar Feriados"
                        className="bottom-offset"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
            </Grid>
            {!agendamentoAutomatico ? (
                <>
                    <Grid item xs={12} sm={12} lg={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                            <KeyboardDatePicker
                                id="data"
                                label="Data Cirurgia *"
                                name="data"
                                format="dd/MM/yyyy"
                                disableToolbar={true}
                                value={data}
                                onChange={(e) => { setData(e) }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'alterar data',
                                }}
                                invalidDateMessage="Formato de data inválido"
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={3}>
                        <TextField
                            type="time"
                            label="Hora *"
                            name="hora"
                            value={agendamentoAutomatico ? '' : hora}
                            onChange={(e) => { setHora(e.target.value) }} //TODO tratar hora invalida
                            fullWidth
                            disabled={!editando && agendamentoAutomatico}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                </>

            ) : (
                <>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormControl fullWidth>
                            <InputLabel id="data-inicio-label">Data Cirurgia *</InputLabel>
                            <Select
                                labelId="data-inicio-label"
                                value={dataInicio}
                                onChange={(event) => setDataInicio(event.target.value)}
                            >
                                {Object.keys(datasProximasPorData).slice(0, 12).map((data, index) => (
                                    <MenuItem key={data} value={data}>
                                        <span style={{ marginRight: '8px', color: 'gray' }}>{index + 1}</span>
                                        <strong>{isoDateToStrDayAndStrDate(data)}</strong>
                                        {feriadosPorDataSubstituicao[data] && (<>
                                            &nbsp; Remarcação Feriado: {isoDateToStrDate(feriadosPorDataSubstituicao[data].dataFeriado)} - {feriadosPorDataSubstituicao[data].nome}
                                        </>)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </>
            )}
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                    id="cirurgia"
                    name="cirurgia"
                    label="Cirurgia * (alt+c)"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    value={descCirurgia}
                    onChange={onDescCirurgiaChange}
                    multiline={true}
                    inputProps={{ maxLength: 500, minLength: 2 }}
                    inputRef={cirurgiaRef}
                    InputProps={{
                        autoComplete: 'off',
                    }}
                    autoFocus
                />
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
                {renderNovasCirurgias()}
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
                <Typography variant='subtitle2'>Preparação</Typography>
                <FormControl component="fieldset" fullWidth>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={preparacaoCostas}
                                    onChange={(e) => {
                                        setPreparacaoCostas(!preparacaoCostas);
                                    }}
                                    value={preparacaoCostas}
                                    color="primary"
                                />
                            }
                            label="Costas"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={preparacaoBrucos}
                                    onChange={(e) => {
                                        setPreparacaoBrucos(!preparacaoBrucos);
                                    }}
                                    value={preparacaoBrucos}
                                    color="primary"
                                />
                            }
                            label="Bruços"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={preparacaoLado}
                                    onChange={(e) => {
                                        setPreparacaoLado(!preparacaoLado);
                                    }}
                                    value={preparacaoLado}
                                    color="primary"
                                />
                            }
                            label="Lado"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={preparacaoMaisConfortavel}
                                    onChange={(e) => {
                                        setPreparacaoMaisConfortavel(!preparacaoMaisConfortavel);
                                    }}
                                    value={preparacaoMaisConfortavel}
                                    color="primary"
                                />
                            }
                            label="Mais Confortável"
                        />
                    </FormGroup>
                </FormControl>
            </Grid>
            <Grid item xs={4} sm={4} lg={12}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={salvarCirurgiaOnClick}
                    startIcon={editando ? <SaveIcon /> : <AddBoxIcon />}
                >
                    {editando ? 'Salvar' : 'Adicionar'}
                </Button>
                {editando && <>&nbsp; <Button component={Link} to={`/assistidos/${props.assistido.id}/cirurgias`} className="button" color="secondary">Cancelar</Button></>}
            </Grid>
        </Grid>
    );
}
