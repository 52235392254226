import api, { URL_TRATAMENTOS } from "../api_env_config";

class TratamentosService {

    obterLista = async () => {
        return new Promise((resolve, reject) => {
            const url = `${URL_TRATAMENTOS}`;
            api.get(url).then(response => {
                resolve(response.data.data);
            }).catch(err => {
                reject("Error: " + err);
            });
        });
    };

    salvar = async (tratamento) => {
        return new Promise((resolve, reject) => {
            const url = `${URL_TRATAMENTOS}`;
            api.post(url, tratamento).then(response => {
                resolve(response.data.data);
            })
            .catch(err => {
                reject("Error: " + err);
            });
        });
    };

    deletar = async (id) => {
        return new Promise((resolve, reject) => {
            const url = `${URL_TRATAMENTOS}/${id}`;
            api.delete(url).then(response => {
                resolve(response.data.data);
            })
            .catch(err => {
                reject("Error: " + err);
            });
        });
    };

    procurarPorId = async (id) => {
        return new Promise((resolve, reject) => {
            const url = `${URL_TRATAMENTOS}/${id}`;
            api.get(url).then(response => {
                resolve(response.data.data);
            }).catch(err => {
                reject("Error: " + err);
            })
        })
    };

}

export default TratamentosService;