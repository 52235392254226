import { decorateAssistido } from '../../components/assistidos/utils';
import api, { URL_ASSISTIDOS, URL_SINTOMAS } from "../api_env_config";


class AssistidoService {

    salvarAssistido = async (assistido) => {
        return new Promise((resolve, reject) => {
            console.log("Salvando assistido", assistido);
            const url = `${URL_ASSISTIDOS}/`;
            api.post(url, assistido).then(response => {
                resolve(response.data.data);
                decorateAssistido(assistido);
            }).catch(err => {
                reject("Error: " + err)
            });
        });
    };

    procurarAssistidoPorCirurgiaId = async (id) => {
        return new Promise((resolve, reject) => {
            const url = `${URL_ASSISTIDOS}/cirurgia/${id}`;
            api.get(url).then(response => {
                const assistido = response.data.data;
                decorateAssistido(assistido);
                resolve(assistido);
            }).catch(err => {
                reject("Error: " + err);
            })
        })
    };

    procurarAssistidoPorId = async (id) => {
        return new Promise((resolve, reject) => {
            const url = `${URL_ASSISTIDOS}/${id}`;
            api.get(url).then(response => {
                const assistido = response.data.data;
                decorateAssistido(assistido);
                resolve(assistido);
            }).catch(err => {
                reject("Error: " + err);
            })
        })
    };

    //busca com paginacao de todos os assistidos. Filtra por tipo assitido e intervalo de dias. Para buscar dentro do ultimo ano especifique 365 por ex.
    buscaDinamica = async (termoBusca, params, numeroPagina = 0, tamanhoPagina = 0) => {
        return new Promise((resolve, reject) => {

            const urlParams = new URLSearchParams({
                n_pagina: numeroPagina,
                t_pagina: tamanhoPagina,
            });

            // Dynamically add all params
            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    urlParams.append(key, params[key]);
                }
            }

            const url = `${URL_ASSISTIDOS}/query/${termoBusca}?${urlParams.toString()}`;

            api.get(url).then(response => {
                const data = response.data.data;
                if (data.assistidos) {
                    data.assistidos = data.assistidos.map(assistido => decorateAssistido(assistido));
                }
                console.log(data);
                resolve(data);
            }).catch(err => {
                reject("Error: " + err);
            });
        });
    };

    procurarAssistidoPorCpf = async (cpf) => {
        return this.buscaDinamica(cpf, { campo: 'cpf' });
    };

    procurarAssistidoPorNome = async (nome) => {
        return this.buscaDinamica(nome, { campo: 'nome', match: 'parte' });
    };

    contagemAssistidos = async () => {
        return this.buscaDinamica('', { count: true });
    };

    adicionarSintoma = async (sintoma) => {
        return new Promise((resolve, reject) => {
            api.post(URL_SINTOMAS, sintoma).then(response => {
                console.log(response);
                resolve(response.data.data);
            }).catch(err => {
                reject("Error: " + err)
            });
        });
    }

    listarHistoricoCadastroAssistido = async (id) => {
        return new Promise((resolve, reject) => {
            const url = `${URL_ASSISTIDOS}/hist/${id}`;
            api.get(url).then(response => {
                resolve(response.data.data);
            }).catch(err => {
                reject("Error: " + err)
            });
        });
    };

    buscarAssistidosVibracionaisLote = async (dataInicio, dataFim) => {
        return new Promise((resolve, reject) => {
            const url = `${URL_ASSISTIDOS}/vibracional-lote`;
            const params = new URLSearchParams({
                data_inicio: dataInicio,
                data_fim: dataFim
            });

            api.get(`${url}?${params}`).then(response => {
                const assistidos = response.data.data;
                const decoratedAssistidos = assistidos.map(assistido => decorateAssistido(assistido));
                resolve(decoratedAssistidos);
            }).catch(err => {
                reject("Error: " + err);
            });
        });
    };

}

export default AssistidoService;
