import Grid from '@material-ui/core/Grid';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import ConfiguracoesCirurgiaService from '../../services/configuracao_cirurgia/index';
import CpEditarConfiguracaoCirurgia from './cp-editar-configuracao-cirurgias';
import CpListaConfiguracoesCirurgia from './cp-lista-configuracao-cirurgias';
import { Paper } from '@material-ui/core';

function CpConfiguracoesCirurgia() {
    const { enqueueSnackbar } = useSnackbar();
    const [configuracoes, setConfiguracoes] = useState([]);

    const service = new ConfiguracoesCirurgiaService();

    useEffect(() => {
        service.obterConfiguracoes()
            .then(res => {
                setConfiguracoes(res || []);
            })
            .catch(err => {
                enqueueSnackbar('Erro ao carregar as configurações!', {
                    variant: 'error',
                });
            });
    }, []);

    const atualizarConfiguracoes = (novasConfiguracoes) => {
        setConfiguracoes(novasConfiguracoes);
    };

    return (
        <>
            <Paper className="app-form">
                <CpEditarConfiguracaoCirurgia configuracoes={configuracoes} onSalvarConfiguracao={atualizarConfiguracoes} />
            </Paper>
            <Paper className="app-form" style={{ margin: '5px 0' }}>
                <CpListaConfiguracoesCirurgia
                    configuracoes={configuracoes}
                    atualizarConfiguracoes={atualizarConfiguracoes}
                />
            </Paper>
        </>
    );
}

export default CpConfiguracoesCirurgia;