import axios from "axios";

let url = "";

switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'local':
        url = process.env.REACT_APP_ENDPOINT;  // Use the REACT_APP_ENDPOINT variable
        break;
    case 'dev':
        url = 'https://i5e4b6gkq1.execute-api.sa-east-1.amazonaws.com/dev';
        break;
    case 'prod':
        url = 'https://6sz2ozmn6a.execute-api.sa-east-1.amazonaws.com/prod';
        break;
    default:
        url = process.env.REACT_APP_ENDPOINT || 'http://127.0.0.1:5000';  // Fallback
}

export const URL_BASE = url
export const URL_AGENDA = URL_BASE + "/1/agenda";
export const URL_ASSISTIDOS = URL_BASE + "/1/assistidos";
export const URL_CADASTROS_INTERNET = URL_BASE + "/1/cadastros-externos";
export const URL_CONFIG_CIRURGIAS = URL_BASE + "/1/configcirurgias";
export const URL_FERIADOS = URL_BASE + "/1/feriados";
export const URL_CIRURGIAS = URL_BASE + "/1/cirurgias";
export const URL_CROMO = `${URL_BASE}/1/cromoterapias`;
export const URL_LOGIN = URL_BASE + "/1/auth/login";
export const URL_SINTOMAS = URL_BASE + "/1/sintomas";
export const URL_TRATAMENTOS = `${URL_BASE}/1/tratamentos`;


const api = axios.create({});
api.defaults.timeout = 60000;
api.defaults.headers.post["Content-Type"] = "application/json";

api.interceptors.request.use(function (config) {
    let token = localStorage.getItem("access_token");

    if (token !== null) {
        config.crossdomain = true;
        config.headers.common.Authorization = `Bearer ${token}`;
        api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    document.body.classList.add('loading-indicator');

    return config
}, function (error) {
    console.log("Error request.use: ", error);
    document.body.classList.remove('loading-indicator');

    return Promise.reject(error);
});
  
api.interceptors.response.use(function (response) {
    console.log("response.use: ", response);
    document.body.classList.remove('loading-indicator');

    return response;
}, function (error) {
    console.log("Error response.use: ", error);
    document.body.classList.remove('loading-indicator');
    if (error.response.status === 401 || error.response.status === 403) {
        localStorage.removeItem("access_token");
        window.location.href = "/login";
    }
    return Promise.reject(error);
});

export default api;
