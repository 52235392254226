import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    shrinkLabel: {
        color: theme.palette.text.secondary,
        fontSize: '1rem',
        fontWeight: 400,
        textTransform: 'none',
        transform: 'translate(0, 1.5px) scale(0.75)', // This transform is typically used in shrink labels
        transformOrigin: 'top left',
        padding: 0,
        lineHeight: 1,
        letterSpacing: '0.00938em'
    },
}));

const ShrinkLabelTypography = ({ children }) => {
    const classes = useStyles();

    return (
        <Typography className={classes.shrinkLabel}>
            {children}
        </Typography>
    );
};

export default ShrinkLabelTypography;
