import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { Local, Posicao } from '../assistidos/enums';
import { renderInfo, renderSubTitle, renderTitle } from './report-utils';
import { parse, isWithinInterval } from 'date-fns';
import { isoDateToStrDate, isoTimeToStrTime } from '../../services/date-utils';

//TODO mover para um arquivo com funcoes de data
export const parseData = (dataAgendada) => {
    const [day, month, year] = dataAgendada.split('/').map(Number);
    return new Date(year, month - 1, day, 0, 0, 0, 0);
}

//TODO mover para um arquivo com funcoes de data
export const DiaDaSemana = {
    SEGUNDA: 1,
    TERCA: 2,
    QUARTA: 3,
    QUINTA: 4,
    SEXTA: 5,
    SABADO: 6,
    DOMINGO: 7
}

//TODO mover para um arquivo com funcoes de data
export const proximaData = (diaDaSemana, semanasDoMes = [1, 2, 3, 4, 5], dataInicio = new Date()) => {
    let proximaData = new Date(dataInicio);
    proximaData.setDate(dataInicio.getDate() + (7 - dataInicio.getDay() + diaDaSemana) % 7);
    let semanaDoMesProximaData = Math.ceil(proximaData.getDate() / 7);

    if (semanasDoMes.includes(semanaDoMesProximaData)) {
        return proximaData;
    }

    let proximaSemana = Math.max(...semanasDoMes);
    while (true) {
        proximaData.setDate(proximaData.getDate() + 7);
        semanaDoMesProximaData = Math.ceil(proximaData.getDate() / 7);
        if (semanaDoMesProximaData > proximaSemana) {
            proximaSemana = Math.max(...semanasDoMes.filter(semana => semana > proximaSemana));
            if (!proximaSemana) break;
        }
        if (semanasDoMes.includes(semanaDoMesProximaData)) {
            return proximaData;
        }
    }

    return null;
}

export default function CpRelatorioTratamento({ assistido, tratamento }) {
    const [cirurgiasTratamento, setCirurgiasTratamento] = useState([]);
    const [semanasTratamento, setSemanasTratamento] = useState(0);
    const [possuiCirurgiaNoLar, setPossuiCirurgiaNoLar] = useState(false);
    const [possuiCirurgiaNaCDC, setPossuiCirurgiaNaCDC] = useState(false);
    const [preparacao, setPreparacao] = useState(false);

    const tratamentos = assistido.tratamentos;

    useEffect(() => {
        if (tratamento) {
            if (!!assistido.cirurgias.length) {
                const dataInicio = parse(tratamento.dataPrescricao, 'yyyy-MM-dd', new Date());
                const dataFim = parse(tratamento.dataFim, 'yyyy-MM-dd', new Date());

                const cirurgiasTratamento = assistido.cirurgias.filter((cirurgia) => {
                    const dataAgendada = new Date(cirurgia.dataAgendada);
                    return isWithinInterval(dataAgendada, { start: dataInicio, end: dataFim });
                });
                setCirurgiasTratamento(cirurgiasTratamento);
                const cirurgiasNoLar = cirurgiasTratamento.some(c => c.localAtendimento === 'ASSISTIDO');
                setPossuiCirurgiaNoLar(cirurgiasNoLar);
                const cirurgiasNaCDC = cirurgiasTratamento.some(c => c.localAtendimento !== 'ASSISTIDO');
                setPossuiCirurgiaNaCDC(cirurgiasNaCDC);
            }
            if (!!tratamentos?.length) {
                setSemanasTratamento(tratamento.quantidadeCromos);
                setPreparacao(!tratamento.preparacaoNaoSeAplica);
            }
        }
        const propertiesToCheck = [
            tratamentos.cromoterapia,
            tratamentos.cromoDiaria,
            tratamentos.desobsessao,
            tratamentos.psicanalise,
            tratamentos.desobsessao,
            tratamentos.reiki,
            tratamentos.karuna,
            tratamentos.tabagismo,
            tratamentos.umbanda,
            tratamentos.vibracional,
            tratamentos.xamanismo,
            tratamentos.outrosTratamentos,
        ];
    }, [tratamento]);

    const formatarData = (data, options = { year: '2-digit', month: '2-digit', day: '2-digit' }) => {
        return new Intl.DateTimeFormat('pt-BR', options).format(data);
    }

    const getDiaDaSemanaStr = (data) => {
        let dataFormatada = formatarData(data, { weekday: 'long' });
        dataFormatada = dataFormatada.charAt(0).toUpperCase() + dataFormatada.slice(1);
        return dataFormatada.replace(/-feira$/, '');
    }

    const renderTratamentoRow = (tratamentoConfig) => {
        const tipo = tratamentoConfig.tipo;
        const dataInicio = formatarData(tratamentoConfig.dataInicio);
        const diaSemana = getDiaDaSemanaStr(tratamentoConfig.dataInicio);
        const sessoes = tratamentoConfig.sessoes;
        return (
            <tr>
                <td>{tipo}</td>
                <td>{diaSemana}</td>
                <td>{dataInicio}</td>
                {sessoes ? (
                    Array.from({ length: semanasTratamento }, (_, index) => (
                        <td key={index} style={{ textAlign: 'center' }}>
                            {index + 1 <= sessoes && index + 1}
                        </td>
                    ))
                ) : (
                    <td colSpan={semanasTratamento}></td>
                )}
            </tr>
        )
    }

    const renderTabelaTratamentos = () => {
        const tratamentoConfig = {
            cromoterapia: {
                tipo: "Cromoterapia",
                dataInicio: proximaData(DiaDaSemana.TERCA),
                sessoes: semanasTratamento,
            },
            desobsessao: {
                tipo: "Desobsessão",
                dataInicio: proximaData(DiaDaSemana.SABADO, [2, 4]),
                sessoes: 7,
            },
            reiki_usui: {
                tipo: "Reiki Usui",
                dataInicio: proximaData(DiaDaSemana.SEGUNDA),
                sessoes: semanasTratamento,
            },
            reiki_karuna: {
                tipo: "Reiki Karuna",
                dataInicio: proximaData(DiaDaSemana.SEGUNDA),
                sessoes: semanasTratamento,
            },
            tabagismo: {
                tipo: "Tabagismo",
                dataInicio: proximaData(DiaDaSemana.TERCA),
                sessoes: 7,
            },
            umbanda: {
                tipo: "Umbanda",
                dataInicio: proximaData(DiaDaSemana.QUINTA),
                sessoes: 0,
            },
            vibracional: {
                tipo: "Vibracional",
                dataInicio: proximaData(DiaDaSemana.SEGUNDA),
                sessoes: 7,
            },
            xamanismo: {
                tipo: "Xamanismo",
                dataInicio: proximaData(DiaDaSemana.QUARTA),
                sessoes: semanasTratamento,
            },
        }

        return (
            <React.Fragment>
                <table className="report-table">
                    <thead>
                        <tr>
                            <th>
                                Tratamento
                            </th>
                            <th>
                                Dia
                            </th>
                            <th>
                                Data Início
                            </th>
                            <th colSpan={semanasTratamento}>
                                Número de sessões
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(tratamento.cromoterapia || tratamento.cromoDiaria) && renderTratamentoRow(tratamentoConfig.cromoterapia)}
                        {tratamento.desobsessao && renderTratamentoRow(tratamentoConfig.desobsessao)}
                        {tratamento.reiki && renderTratamentoRow(tratamentoConfig.reiki_usui)}
                        {tratamento.karuna && renderTratamentoRow(tratamentoConfig.reiki_karuna)}
                        {tratamento.tabagismo && renderTratamentoRow(tratamentoConfig.tabagismo)}
                        {tratamento.vibracional && renderTratamentoRow(tratamentoConfig.vibracional)}
                        {tratamento.xamanismo && renderTratamentoRow(tratamentoConfig.xamanismo)}
                        {tratamento.umbanda && renderTratamentoRow(tratamentoConfig.umbanda)}
                        {tratamento.psicanalise && (
                            <tr>
                                <td>Psicanálise</td>
                                <td>Quarta</td>
                                <td colSpan={semanasTratamento + 1}>Entrar em contato com Margarete Aquila</td>
                            </tr>
                        )}
                        {tratamento.outrosTratamentos && (
                            <tr>
                                <td colSpan={semanasTratamento + 3}>{tratamento.outrosTratamentos}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </React.Fragment>
        )
    }

    const renderAguaFluidaInfo = () => {
        const titulo = 'Água Fluída';
        const texto = (
            <>
                Coloque uma garrafa ou jarra de água potável com o nome de quem irá beber a água. Acenda uma vela branca simples, ore 3 vezes o "Pai Nosso", pedindo a presença da corrente do Dr Espanhol.
                Quando a vela acabar, estará pronta para ser bebida.
                <strong> Não pode ser fervida e nem gelada. Deve ser bebida totalmente em 3 dias. Não acrescente água à garrafa. </strong>
            </>
        );

        return renderInfo(titulo, texto);
    }

    const renderTEDNaCasaDoConsoladorInfo = () => {
        const titulo = 'O Tratamento Espiritual Dirigido (TED) na casa do consolador';
        const texto = (
            <>
                Chegue até 18:00 horas. Traga 2 lençóis claros e limpos, 1 garrafa de pelo menos 1,5 litros de água potável que deve ser bebida em 24 horas, após o procedimento.
                Não ingerir carnes ou bebidas alcoólicas no dia da intervenção. Beber muita água fluida. Retornar em consulta após a conclusão de todas as sessões de cromoterapia.
            </>
        );
        return renderInfo(titulo, texto);
    }

    const renderTEDNoLarInfo = () => {
        const titulo = 'O Tratamento Espiritual Dirigido (TED) no lar';
        const texto = (
            <>
                <strong>Preparação no dia:</strong> Evite ingerir carnes e bebidas alcoólicas. Use lençóis e roupas de cor clara <br />
                <strong>Início da cirurgia:</strong> Próximo da cabeceira, coloque uma garrafa ou um jarro com no mínimo 1,5 litros de água potável, ela estará fluidificada ao final da cirurgia e deverá ser bebida em até 24 horas, depois desse prazo se ainda sobrar água descarte nas plantas.
                Ao lado da cama, coloque sobre um pano claro, uma bacia com água de torneira. Se possível tenha 2 pessoas vibrando durante o tempo da Cirurgia. Podem estar com você ou em lugares diferentes.
                Caso contrário, mantenha-se tranquilo e com fé. Permaneça em oração durante 1 hora.
                Reze 3 "Pai Nosso", pedindo a presença da Corrente do Dr. Espanhol. Após {assistido.tipo == 'H' ? '1 hora' : '15 minutos'} de oração/vibração jogue a água da bacia na pia ou no vaso sanitário. Pode fazer no dia seguinte ao acordar, se dormir durante a cirurgia.
                Se possível tome apenas água fluída durante todo o tratamento. Evite fazer muito esforço no dia seguinte. Retornar em consulta após a conclusão de todas as sessões de cromoterapia.
            </>
        );
        return renderInfo(titulo, texto);
    }

    const renderPreparacaoTEDInfo = () => {
        const titulo = 'Preparação diária para o Tratamento Espiritual Dirigido (TED)';
        const texto = (
            <>
                É o curativo diário de seu tratamento com <strong>duração de 15 minutos</strong>. Coloque um copo com água potável perto de sua cabeceira, quando for dormir.
                Deite-se, ore 3 vezes o "Pai Nosso", pedindo a presença da Corrente do Dr. Espanhol. Na manhã seguinte, em jejum, beba metade do copo d'água e à noite, beba o restante.
                Repita isso nos dias marcados a seguir, durante todas as semanas de tratamento.
            </>
        );
        return renderInfo(titulo, texto);
    }

    const renderPosicaoTratamentoTable = () => {
        const posicao = Posicao.getListaStr({
            preparacaoLado: tratamento.preparacaoLado,
            preparacaoCostas: tratamento.preparacaoCostas,
            preparacaoBrucos: tratamento.preparacaoBrucos,
            preparacaoMaisConfortavel: tratamento.preparacaoMaisConfortavel,
        });

        return (
            <React.Fragment>
                <Grid item xs={12} lg={12}>
                    <br />
                    <table className='report-table'>
                        <thead>
                            <tr>
                                <th>Posição</th>
                                <th>Segunda</th>
                                <th>Terça</th>
                                <th>Quarta</th>
                                <th>Quinta</th>
                                <th>Sexta</th>
                                <th>Sábado</th>
                                <th>Domingo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr key={1} className="">
                                <td align="center">
                                    {posicao}
                                </td>
                                <td align="center">
                                    &#x2713;
                                </td>
                                <td align="center">
                                    &#x2713;
                                </td>
                                <td align="center">
                                    &#x2713;
                                </td>
                                <td align="center">
                                    &#x2713;
                                </td>
                                <td align="center">
                                    &#x2713;
                                </td>
                                <td align="center">
                                    &#x2713;
                                </td>
                                <td align="center">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Grid>
            </React.Fragment>
        )
    }

    const renderTabelaCirurgias = () => {
        return (
            <Grid item xs={12} lg={12}>
                <table className='report-table'>
                    <thead>
                        <tr>
                            <th>Data</th>
                            <th>Local</th>
                            <th>Órgão</th>
                            <th>Posição</th>
                            <th>Horário</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cirurgiasTratamento.reverse().map(c => (
                            <tr key={c.id} className="">
                                <td>
                                    {isoDateToStrDate(c.dataAgendada)}
                                </td>
                                <td>
                                    {Local.porDbVal(c.localAtendimento).desc}
                                </td>
                                <td align="left">
                                    {c.descricao}
                                </td>
                                <td align="left">
                                    {Posicao.ofCirurgia(c)}
                                </td>
                                <td align="left">
                                    {isoTimeToStrTime(c.horarioAgendado)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Grid>
        )
    }

    if (!tratamento) {
        return (
            <></>
        )
    }

    return (
        <Grid item xs={12} lg={12}>
            <Grid item xs={12} lg={12}>
                {renderTitle("TRATAMENTOS ESPIRITUAIS")}
            </Grid>
            <Grid item xs={12} lg={12}>
                {renderSubTitle("NO FINAL DAS SESSÕES VOCÊ DEVERÁ VOLTAR PARA FAZER UMA CONSULTA DE RETORNO")}
            </Grid>
            {renderTabelaTratamentos()}
            {preparacao && renderPreparacaoTEDInfo()}
            {preparacao && renderPosicaoTratamentoTable()}
            {!!cirurgiasTratamento.length && renderAguaFluidaInfo()}
            {possuiCirurgiaNoLar && renderTEDNoLarInfo()}
            {possuiCirurgiaNaCDC && renderTEDNaCasaDoConsoladorInfo()}
            {!!cirurgiasTratamento.length && renderTabelaCirurgias()}
        </Grid>
    )
}
