import Grid from '@material-ui/core/Grid';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import CpEditarFeriado from './cp-editar-feriado';
import CpListaFeriados from './cp-lista-feriados';
import FeriadosService from '../../services/feriados/index';
import { Paper } from '@material-ui/core';

function CpFeriados() {
    const { enqueueSnackbar } = useSnackbar();
    const [feriados, setFeriados] = useState([]);

    const service = new FeriadosService();

    useEffect(() => {
        service.obterLista()
            .then(res => {
                setFeriados(res);
            })
            .catch(err => {
                enqueueSnackbar('Erro ao carregar as configurações!', {
                    variant: 'error',
                });
            });
    }, []);

    const atualizarFeriados = (feriados) => {
        setFeriados(feriados);
    };

    return (
        <>
            <Paper className="app-form">
                <CpEditarFeriado lista={feriados} onSalvar={atualizarFeriados} />
            </Paper>
            <Paper className="app-form" style={{ margin: '5px 0' }}>
                <CpListaFeriados
                    lista={feriados}
                    onAtualizarLista={atualizarFeriados}
                />
            </Paper>
        </>
    );
}

export default CpFeriados;