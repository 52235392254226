import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { withSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import AssistidoObrigatorio from '../../components/assistido-obrigatorio';
import { Acao } from '../../components/assistidos/cp-acoes-assistido';
import CpBarraAcoes from '../../components/assistidos/cp-barra-acoes';
import CpResumoAssistido from '../../components/assistidos/cp-resumo-assistido';
import CpAjudaSintomas from '../../components/cp-ajuda/cp-ajuda-sintomas';
import CpSintomas from '../../components/cp-sintomas/cp-sintomas';
import AssistidoService from '../../services/assistidos';
import Atalhos from './atalhos-teclado';

const PgSintomas = ({ match, enqueueSnackbar }) => {
    const [assistido, setAssistido] = useState(null);
    const [id, setId] = useState(match.params.id);
    const [mostrarAjuda, setMostrarAjuda] = useState(false);

    const service = new AssistidoService();

    const carregarAssistido = (id) => {
        service.procurarAssistidoPorId(id)
            .then(res => {
                console.log("Assistido encontrado", res);
                setAssistido(res);
            })
            .catch(err => {
                enqueueSnackbar(`Ocorreu um problema ao obter o assistido número de controle ${id}`, {
                    variant: 'error',
                });
                console.log("Erro ao carregar assistido", err);
            });
    }

    const ajudaOnClick = () => {
        setMostrarAjuda(!mostrarAjuda);
    }

    const ajudaOnClose = () => {
        setMostrarAjuda(!mostrarAjuda);
    }

    useEffect(() => {
        if (id) {
            carregarAssistido(id);
        } else {
            enqueueSnackbar('Escolha um Assistido!', {
                variant: 'info',
            });
        }
    }, []);

    useHotkeys(Atalhos.AJUDA.sequence, ajudaOnClick);

    return (
        <AssistidoObrigatorio idAssistido={id} assistido={assistido}>
            {mostrarAjuda && <CpAjudaSintomas onClose={ajudaOnClose} />}

            <CpResumoAssistido assistido={assistido} />

            <CpBarraAcoes assistido={assistido} destacarAcao={Acao.Sintomas}>
                <Tooltip title="Mostra informações e atalhos da tela (f1)" disableFocusListener >
                    <Button
                        variant="contained"
                        color="default"
                        size="small"
                        startIcon={<HelpOutlineIcon />}
                        onClick={ajudaOnClick}
                    >
                        Ajuda
                    </Button>
                </Tooltip>
            </CpBarraAcoes>

            <CpSintomas assistido={assistido} />
        </AssistidoObrigatorio>
    );
}

export default withSnackbar(PgSintomas);

