import DateFnsUtils from '@date-io/date-fns';
import {
    Button,
    Checkbox,
    CircularProgress,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PrintIcon from '@material-ui/icons/Print';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { format, parseISO } from 'date-fns';
import React, { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import AssistidoService from '../../services/assistidos';
import CpRelatorioVibracional from '../report/cp-relatorio-vibracional';
import { isoDateToStrDate } from '../../services/date-utils';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    paper: {
        padding: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    datePickersContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: theme.spacing(3),
    },
    datePicker: {
        marginRight: theme.spacing(2),
    },
    button: {
        marginLeft: theme.spacing(2),
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '200px',
    },
    noResultsContainer: {
        textAlign: 'center',
        padding: theme.spacing(3),
    },
}));

const CpRelatorioVibracionalLote = () => {
    const classes = useStyles();
    const service = new AssistidoService();
    const [dataInicio, setDataInicio] = useState(null);
    const [dataFim, setDataFim] = useState(null);
    const [assistidos, setAssistidos] = useState([]);
    const [selecionados, setSelecionados] = useState({});
    const [tratamentosSelecionados, setTratamentosSelecionados] = useState({});
    const [carregando, setCarregando] = useState(false);

    const componenteParaImpressaoRef = useRef();

    const buscarAssistidos = async () => {
        if (dataInicio && dataFim) {
            setCarregando(true);
            try {
                const dataInicioReq = format(dataInicio, 'yyyy-MM-dd');
                const dataFimReq = format(dataFim, 'yyyy-MM-dd');
                const assistidosData = await service.buscarAssistidosVibracionaisLote(dataInicioReq, dataFimReq);

                setAssistidos(assistidosData);

                const novosSelecionados = {};
                const novosTratamentosSelecionados = {};
                assistidosData.forEach((assistido) => {
                    novosSelecionados[assistido.id] = false;
                    const tratamentosVibracionais = assistido.tratamentos.filter(t => t.vibracional);
                    if (tratamentosVibracionais.length > 0) {
                        const tratamentoMaisRecente = tratamentosVibracionais.reduce((prev, current) => {
                            return parseISO(current.data_prescricao) > parseISO(prev.data_prescricao) ? current : prev;
                        });
                        novosTratamentosSelecionados[assistido.id] = tratamentoMaisRecente.id;
                    }
                });
                setSelecionados(novosSelecionados);
                setTratamentosSelecionados(novosTratamentosSelecionados);
            } catch (error) {
                console.error("Erro ao buscar assistidos:", error);
                // adicionar uma notificação de erro para o usuário
            } finally {
                setCarregando(false);
            }
        }
    };

    const handleCheckboxChange = (id) => {
        setSelecionados((prev) => ({ ...prev, [id]: !prev[id] }));
    };

    const handleSelectAll = (event) => {
        const novosSelecionados = {};
        assistidos.forEach((assistido) => {
            novosSelecionados[assistido.id] = event.target.checked;
        });
        setSelecionados(novosSelecionados);
    };

    const handleTratamentoChange = (id, tratamentoId) => {
        setTratamentosSelecionados((prev) => ({ ...prev, [id]: tratamentoId }));
    };

    const handlePrint = useReactToPrint({
        content: () => componenteParaImpressaoRef.current,
        pageStyle: `
            @page {
                size: A4;
                margin: 20mm;
            }
            @media print {
                .pagina-relatorio {
                    page-break-after: always;
                }
            }
        `,
        copyStyles: false,
    });

    const imprimirSelecionados = () => {
        handlePrint();
    };

    const imprimirIndividual = (assistido) => {
        const tratamentoSelecionado = assistido.tratamentos.find(
            (t) => t.id === tratamentosSelecionados[assistido.id]
        );
        setSelecionados({ [assistido.id]: true });
        setTimeout(handlePrint, 100);
    };

    const todosAssistidosSelecionados = assistidos.length > 0 && assistidos.every(a => selecionados[a.id]);
    const algunsAssistidosSelecionados = assistidos.some(a => selecionados[a.id]);

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Paper className={classes.paper}>
                <Typography variant="h6" gutterBottom>
                    Relatório Vibracional em Lote
                </Typography>
                <div className={classes.datePickersContainer}>
                    <KeyboardDatePicker
                        disableToolbar
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inicio"
                        label="Data Início"
                        value={dataInicio}
                        onChange={setDataInicio}
                        KeyboardButtonProps={{
                            'aria-label': 'alterar data início',
                        }}
                        invalidDateMessage="Formato de data inválido"
                        className={classes.datePicker}
                    />
                    <KeyboardDatePicker
                        disableToolbar
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-fim"
                        label="Data Fim"
                        value={dataFim}
                        onChange={setDataFim}
                        KeyboardButtonProps={{
                            'aria-label': 'alterar data fim',
                        }}
                        invalidDateMessage="Formato de data inválido"
                        className={classes.datePicker}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={buscarAssistidos}
                        className={classes.button}
                    >
                        Buscar
                    </Button>
                </div>

                {carregando ? (
                    <div className={classes.loadingContainer}>
                        <CircularProgress />
                    </div>
                ) : assistidos.length === 0 ? (
                    <Paper className={classes.noResultsContainer}>
                        <Typography variant="subtitle2">
                            Nenhum assistido encontrado. Verifique o período selecionado e tente novamente.
                        </Typography>
                    </Paper>
                ) : (
                    <>
                        <Grid container justifyContent="space-between" alignItems="center" style={{ margin: '20px 0' }}>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={imprimirSelecionados}
                                    startIcon={<PrintIcon />}
                                    disabled={!algunsAssistidosSelecionados}
                                >
                                    Imprimir Selecionados
                                </Button>
                            </Grid>
                        </Grid>

                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                indeterminate={algunsAssistidosSelecionados && !todosAssistidosSelecionados}
                                                checked={todosAssistidosSelecionados}
                                                onChange={handleSelectAll}
                                            />
                                        </TableCell>
                                        <TableCell>Assistido</TableCell>
                                        <TableCell>Tratamento</TableCell>
                                        <TableCell align="center">Ações</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {assistidos.map((assistido) => {
                                        const tratamentosVibracionais = assistido.tratamentos.filter(t => t.vibracional);
                                        return (
                                            <TableRow key={assistido.id}>
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={!!selecionados[assistido.id]}
                                                        onChange={() => handleCheckboxChange(assistido.id)}
                                                    />
                                                </TableCell>
                                                <TableCell>{assistido.nome.toUpperCase()} ({assistido.id})</TableCell>
                                                <TableCell>
                                                    <Select
                                                        value={tratamentosSelecionados[assistido.id] || ''}
                                                        onChange={(e) => handleTratamentoChange(assistido.id, e.target.value)}
                                                        style={{ width: '100%' }}
                                                    >
                                                        {tratamentosVibracionais
                                                            .sort((a, b) => parseISO(b.dataPrescricao) - parseISO(a.dataPrescricao))
                                                            .map((tratamento) => (
                                                                <MenuItem key={tratamento.id} value={tratamento.id}>
                                                                    {isoDateToStrDate(tratamento.dataPrescricao)}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <IconButton
                                                        onClick={() => imprimirIndividual(assistido)}
                                                        size="small"
                                                    >
                                                        <PrintIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}

                <div style={{ display: 'none' }}>
                    <div ref={componenteParaImpressaoRef}>
                        {assistidos.filter(a => selecionados[a.id]).map((assistido, index) => {
                            const tratamentoSelecionado = assistido.tratamentos.find(
                                (t) => t.id === tratamentosSelecionados[assistido.id]
                            );
                            return (
                                <div key={assistido.id} className="pagina-relatorio">
                                    <CpRelatorioVibracional
                                        assistido={assistido}
                                        tratamento={tratamentoSelecionado}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Paper>
        </MuiPickersUtilsProvider>
    );
};

export default CpRelatorioVibracionalLote;