export const keyMap = {
    FOCO_NOME: { name: 'Foco no campo Nome', sequences: ['alt+n', 'alt+N'] },
    FOCO_SEXO_MASC: { name: 'Marcar como sexo masculino', sequences: ['alt+m', 'alt+M'] },
    FOCO_SEXO_FEM: { name: 'Marcar como sexo feminino', sequences: ['alt+f', 'alt+F'] },
    FOCO_ENDERECO: { name: 'Foco no campo Endereço', sequences: ['alt+e', 'alt+E'] },
    FOCO_ESTADO: { name: 'Foco no campo Estado', sequences: ['alt+t', 'alt+T'] },
    FOCO_CIDADE: { name: 'Foco no campo Cidade', sequences: ['alt+c', 'alt+C'] },
    FOCO_CEP: { name: 'Foco no campo CEP', sequences: ['alt+p', 'alt+P'] },
    FOCO_CPF: { name: 'Foco no campo CPF', sequences: ['alt+x', 'alt+X'] },
    FOCO_EMAIL: { name: 'Foco no campo e-mail', sequences: ['alt+l', 'alt+L'] },
    FOCO_ORIGEM_INTERNET: { name: 'Marcar como origem Internet', sequences: ['alt+i', 'alt+I'] },
    FOCO_ORIGEM_PCD: { name: 'Marcar como origem PCD', sequences: ['alt+v', 'alt+V'] },
    FOCO_ANIMAL: { name: 'Marcar como tipo Animal', sequences: ['alt+a', 'alt+A'] },
    FOCO_PLANTA: { name: 'Marcar como tipo Planta', sequences: ['alt+w', 'alt+W'] },
    FOCO_HUMANO: { name: 'Marcar como tipo Humano', sequences: ['alt+h', 'alt+H'] },
    FOCO_SINTOMAS: { name: 'Foco no campo de sintomas', sequences: ['alt+s', 'alt+S'] },
    FOCO_LOCAL: { name: 'Marcar como local da cirurgia preferido Casa do Consolador', sequences: ['alt+q', 'alt+Q'] },
    SALVAR: { name: 'Salvar o assistido', sequence: 'pagedown' },
    SALVAR_IR_SINTOMA: { name: 'Salvar o assistido e ir para o cadastro de sintomas', sequence: 'pageup'},
    AJUDA: { name: 'Mostra os atalhos da página', sequence: 'f1' }
};
