import DateFnsUtils from '@date-io/date-fns';
import {
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Paper, Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ptBR from 'date-fns/locale/pt-BR';
import React, { useEffect, useMemo, useState } from 'react';
import { Local, TipoAssistido } from '../../components/assistidos/enums';
import CirurgiaService from '../../services/cirurgias';
import { jsDateToIsoDate, isoDateToStrDate, isoTimeToStrTime } from '../../services/date-utils';
import CpAcoesAssistido from '../assistidos/cp-acoes-assistido';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    boldCell: {
        fontWeight: 'bold',
    },
    groupHeader: {
        backgroundColor: theme.palette.action.hover,
        fontWeight: 'bold',
    },
    subGroupHeader: {
        backgroundColor: theme.palette.action.selected,
        fontWeight: 'bold',
    },
    assistidoDetails: {
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    datePickerContainer: {
        marginBottom: theme.spacing(2),
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '200px',
    },
    filtersContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        marginBottom: theme.spacing(2),
    },
}));

const CpRelatorioCirurgias = () => {
    const classes = useStyles();
    const service = new CirurgiaService();

    const [dataSelecionada, setDataSelecionada] = useState(new Date());
    const [cirurgias, setCirurgias] = useState([]);
    const [carregando, setCarregando] = useState(false);
    const [filtroLocal, setFiltroLocal] = useState('');
    const [filtroHora, setFiltroHora] = useState('');
    const [filtroTipoAssistido, setFiltroTipoAssistido] = useState('');
    const [mostrarAssistidos, setMostrarAssistidos] = useState(false);

    useEffect(() => {
        const carregarDados = async () => {
            setCarregando(true);
            try {
                const response = await service.obterRelatorioCirurgias(jsDateToIsoDate(dataSelecionada));
                const dadosFormatados = response.data.map(c => {
                    return {
                        "assistido_id": c.assistido_id,
                        "cirurgia_id": c.cirurgia_id,
                        "data": isoDateToStrDate(c.data),
                        "descricao": c.descricao,
                        "hora": isoTimeToStrTime(c.hora),
                        "local": Local.porDbVal(c.local).desc,
                        "nome_assistido": c.nome_assistido,
                        "tipo_assistido": TipoAssistido.porDbVal(c.tipo_assistido).desc
                    };
                });
                setCirurgias(dadosFormatados);
            } catch (error) {
                console.error("Erro ao carregar dados:", error);
                //adicionar uma lógica para exibir uma mensagem de erro para o usuário
            } finally {
                setCarregando(false);
            }
        };

        carregarDados();
    }, [dataSelecionada]);

    const valoresUnicos = useMemo(() => {
        return {
            locais: [...new Set(cirurgias.map(c => c.local))].sort(),
            horas: [...new Set(cirurgias.map(c => c.hora))].sort(),
            tiposAssistido: [...new Set(cirurgias.map(c => c.tipo_assistido))].sort(),
        };
    }, [cirurgias]);

    const cirurgiasFiltradas = useMemo(() => {
        return cirurgias.filter((cirurgia) => {
            return (
                (!filtroLocal || cirurgia.local === filtroLocal) &&
                (!filtroHora || cirurgia.hora === filtroHora) &&
                (!filtroTipoAssistido || cirurgia.tipo_assistido === filtroTipoAssistido)
            );
        });
    }, [cirurgias, filtroLocal, filtroHora, filtroTipoAssistido]);

    const cirurgiasAgrupadas = useMemo(() => {
        const grupos = cirurgiasFiltradas.reduce((acc, cirurgia) => {
            if (!acc[cirurgia.local]) {
                acc[cirurgia.local] = {};
            }
            if (!acc[cirurgia.local][cirurgia.hora]) {
                acc[cirurgia.local][cirurgia.hora] = {};
            }
            if (!acc[cirurgia.local][cirurgia.hora][cirurgia.tipo_assistido]) {
                acc[cirurgia.local][cirurgia.hora][cirurgia.tipo_assistido] = [];
            }
            acc[cirurgia.local][cirurgia.hora][cirurgia.tipo_assistido].push(cirurgia);
            return acc;
        }, {});

        return Object.entries(grupos).map(([local, horas]) => ({
            local,
            horas: Object.entries(horas).map(([hora, tipos]) => ({
                hora,
                tipos: Object.entries(tipos).map(([tipo, cirurgias]) => ({
                    tipo,
                    cirurgias: cirurgias.sort((a, b) => a.nome_assistido.localeCompare(b.nome_assistido)),
                    quantidade: cirurgias.length
                })),
                quantidade: Object.values(tipos).reduce((sum, cirurgias) => sum + cirurgias.length, 0)
            })),
            quantidade: Object.values(horas).reduce((sum, tipos) =>
                sum + Object.values(tipos).reduce((innerSum, cirurgias) => innerSum + cirurgias.length, 0), 0)
        }));
    }, [cirurgiasFiltradas]);

    const renderizarLinhas = () => {
        return cirurgiasAgrupadas.flatMap((grupoLocal, indexLocal) => {
            const linhasLocal = [
                <TableRow key={`local-${indexLocal}`} className={classes.groupHeader}>
                    <TableCell className={classes.boldCell}>{grupoLocal.local}</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell className={classes.boldCell}>{grupoLocal.quantidade}</TableCell>
                </TableRow>
            ];

            grupoLocal.horas.forEach((grupoHora, indexHora) => {

                linhasLocal.push(
                    <TableRow key={`hora-${indexLocal}-${indexHora}`} className={classes.subGroupHeader}>
                        <TableCell></TableCell>
                        <TableCell className={classes.boldCell}>{grupoHora.hora}</TableCell>
                        <TableCell></TableCell>
                        <TableCell className={classes.boldCell}>{grupoHora.quantidade}</TableCell>
                    </TableRow>
                );

                grupoHora.tipos.forEach((grupoTipo, indexTipo) => {
                    if (mostrarAssistidos) {
                        linhasLocal.push(
                            <TableRow key={`tipo-${indexLocal}-${indexHora}-${indexTipo}`}>
                                <TableCell>{grupoLocal.local}</TableCell>
                                <TableCell>{grupoHora.hora}</TableCell>
                                <TableCell>{grupoTipo.tipo}</TableCell>
                                <TableCell>{grupoTipo.quantidade}</TableCell>
                            </TableRow>
                        );
                        grupoTipo.cirurgias.forEach((cirurgia, indexCirurgia) => {
                            linhasLocal.push(
                                <TableRow key={`assistido-${indexLocal}-${indexHora}-${indexTipo}-${indexCirurgia}`} className={classes.assistidoDetails}>
                                    <TableCell colSpan={3}>
                                        {`${cirurgia.nome_assistido.toUpperCase()} (${cirurgia.assistido_id})`}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'right' }}>
                                        <CpAcoesAssistido assistido={{ id: cirurgia.assistido_id }} iconSize={'small'} />
                                    </TableCell>
                                </TableRow>
                            );
                        });
                    }else{
                        linhasLocal.push(
                            <TableRow key={`tipo-${indexLocal}-${indexHora}-${indexTipo}`}>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>{grupoTipo.tipo}</TableCell>
                                <TableCell>{grupoTipo.quantidade}</TableCell>
                            </TableRow>
                        );
                    }
                });
            });

            return linhasLocal;
        });
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <Paper className={classes.root}>
                <div className={classes.datePickerContainer}>
                    <KeyboardDatePicker
                        disableToolbar
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker"
                        label="Selecione a data"
                        value={dataSelecionada}
                        onChange={setDataSelecionada}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        KeyboardButtonProps={{
                            'aria-label': 'alterar data',
                        }}
                        invalidDateMessage="Formato de data inválido"
                    />
                </div>
                {carregando ? (
                    <div className={classes.loadingContainer}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <div className={classes.filtersContainer}>
                            <FormControl className={classes.formControl}>
                                <InputLabel>Local</InputLabel>
                                <Select
                                    value={filtroLocal}
                                    onChange={(e) => setFiltroLocal(e.target.value)}
                                >
                                    <MenuItem value="">Todos</MenuItem>
                                    {valoresUnicos.locais.map((local) => (
                                        <MenuItem key={local} value={local}>{local}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <InputLabel>Hora</InputLabel>
                                <Select
                                    value={filtroHora}
                                    onChange={(e) => setFiltroHora(e.target.value)}
                                >
                                    <MenuItem value="">Todas</MenuItem>
                                    {valoresUnicos.horas.map((hora) => (
                                        <MenuItem key={hora} value={hora}>{hora}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <InputLabel>Tipo de Assistido</InputLabel>
                                <Select
                                    value={filtroTipoAssistido}
                                    onChange={(e) => setFiltroTipoAssistido(e.target.value)}
                                >
                                    <MenuItem value="">Todos</MenuItem>
                                    {valoresUnicos.tiposAssistido.map((tipo) => (
                                        <MenuItem key={tipo} value={tipo}>{tipo}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={mostrarAssistidos}
                                        onChange={(e) => setMostrarAssistidos(e.target.checked)}
                                        name="mostrarAssistidos"
                                    />
                                }
                                label="Mostrar Assistidos"
                            />
                        </div>
                        <Table className={classes.table} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.boldCell}>Local</TableCell>
                                    <TableCell className={classes.boldCell}>Hora</TableCell>
                                    <TableCell className={classes.boldCell}>Tipo de Assistido</TableCell>
                                    <TableCell className={classes.boldCell}>Quantidade de Cirurgias</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {renderizarLinhas()}
                            </TableBody>
                        </Table>
                    </>
                )}
            </Paper>
        </MuiPickersUtilsProvider>
    );
};

export default CpRelatorioCirurgias;