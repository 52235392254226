import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import AddBoxIcon from '@material-ui/icons/AddBoxOutlined';
import TipoAssistidoHumanoIcon from '@material-ui/icons/EmojiPeople';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import HistoryIcon from '@material-ui/icons/History';
import TipoAssistidoPlantaIcon from '@material-ui/icons/LocalFlorist';
import TipoAssistidoAnimalIcon from '@material-ui/icons/Pets';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import 'date-fns';
import { format } from 'date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import { withSnackbar } from 'notistack';
import React from 'react';
import { HotKeys, configure } from 'react-hotkeys';
import CpBarraAcoes from '../../components/assistidos/cp-barra-acoes';
import CpModalAssistidos from '../../components/assistidos/cp-modal-assistidos';
import AjudaNovoAssistido from '../../components/cp-ajuda/cp-ajuda-novo-assistido';
import CpEmailAutocomplete from '../../components/cp-autocomplete/cp-autocomplete-emails';
import AssistidoService from '../../services/assistidos';
import CepService from '../../services/cep';
import { keyMap } from './atalhos-teclado';

import { IconButton, InputAdornment, Typography } from '@material-ui/core';
import { Acao } from '../../components/assistidos/cp-acoes-assistido';
import CpHistoricoAssistido from '../../components/assistidos/cp-historico-assistido';
import { Local } from '../../components/assistidos/enums';
import { calcularIdade } from '../../components/assistidos/utils';
import PhoneNumber from '../../components/cp-phone-number/cp-phone-number';
import CpListaSintomas, { ColunaSintoma } from '../../components/cp-sintomas/cp-lista-sintomas';
import { jsDateToIsoDate } from '../../services/date-utils';
import './pg-assistidos.css';

configure({
    ignoreTags: []
})

class PgAssistido extends React.Component {
    constructor(props) {
        super(props);

        this.nomeInput = React.createRef();
        this.enderecoInput = React.createRef();
        this.cpfInput = React.createRef();
        this.cepInput = React.createRef();
        this.cidadeInput = React.createRef();
        this.estadoInput = React.createRef();
        this.emailInput = React.createRef();
        this.tipoInput = React.createRef();
        this.nomeProtetorInput = React.createRef();
        this.salvarInput = React.createRef();
        this.ajudaInput = React.createRef();
        this.localInput = React.createRef();
        this.descricaoSintomaRef = React.createRef();
        this.dataConsultaRef = React.createRef();

        this.service = new AssistidoService();
        this.cepService = new CepService();

        this.state = {
            id: this.props.match.params.id,
            nome: '',
            sexo: '',
            endereco: '',
            cpf: '',
            cep: '',
            nascimento: null,
            email: '',
            origem: 'P',
            cidade: 'SAO PAULO',
            estado: 'SP',
            tipo: '',
            telefone: '',
            nomeProtetor: '',
            mostrarMensagemErro: false,
            mostrarAjuda: false,
            mostrarHistorico: false,
            idade: '',
            hospitalizado: false,
            descricaoSintoma: "",
            descricaoSintomaAssistido: "",
            sintomas: [],
            local: false,
            campoAssistidoExistente: '',
            assistidosJaCadastrados: []
        }
    }

    carregarAssistido = (id) => {
        this.service.procurarAssistidoPorId(id)
            .then(res => {
                console.log('assistido', res);
                this.setState({
                    assistido: res,
                    id: res.id,
                    nome: res.nome,
                    sexo: res.sexo,
                    telefone: res.telefone,
                    endereco: res.endereco,
                    cpf: res.cpf,
                    cep: res.cep,
                    cidade: res.cidade,
                    estado: res.estado,
                    email: res.email,
                    origem: res.origem === "PCD" || res.origem === "P" ? "P" : "I",
                    tipo: res.tipo,
                    nascimento: res.dataNascimento ? new Date(res.dataNascimento) : null,
                    idade: res.idade,
                    nomeProtetor: res.nomeProtetor,
                    sintoma: '',
                    sintomas: res.sintomas,
                    hospitalizado: false,
                });
            })
            .catch(err => {
                this.props.enqueueSnackbar(`Ocorreu um problema ao obter o assistido número de controle ${this.state.id}`, {
                    variant: 'error',
                });
                console.log(err);
            });
    }

    onFecharModalAssistidos = (e) => {
        this.setState({
            campoAssistidoExistente: '',
            assistidosJaCadastrados: []
        });
    }

    verificarAssistidoExistente = (campo, valorAtual, apiCall) => {
        const valorNormalizado = this.normalizarString(valorAtual);
        if (valorAtual !== valorNormalizado) {
            const stateToUpdate = {};
            stateToUpdate[campo] = valorNormalizado;
            this.setState(stateToUpdate);
        }
        if (valorNormalizado) {
            apiCall(valorNormalizado)
                .then(data => {
                    if (!!data.assistidos.length && !!this.state.id) {
                        data.assistidos = data.assistidos.filter(assistido => assistido.id !== this.state.id);
                    }
                    if (!!data.assistidos.length) {
                        this.setState({
                            campoAssistidoExistente: campo,
                            assistidosJaCadastrados: data.assistidos,
                        });
                    } else {
                        this.props.enqueueSnackbar(`Não existem outros assistidos com o ${campo} informado!`, {
                            variant: 'info',
                        });
                    }
                }).catch(err => {
                    console.error(err);
                });
        }
    }

    verificarNomeExistente = (e) => {
        this.verificarAssistidoExistente('nome', this.state.nome, this.service.procurarAssistidoPorNome);
    }

    verificarCpfExistente = (e) => {
        this.verificarAssistidoExistente('cpf', this.state.cpf, this.service.procurarAssistidoPorCpf);
    }

    montarSintomaParaSalvar = (assistidoId) => {
        const descricaoNormalizada = this.normalizarString(this.state.descricaoSintoma);
        const descricaoAssistidoNormalizada = this.normalizarString(this.state.descricaoSintomaAssistido);
        if (!descricaoNormalizada && !descricaoAssistidoNormalizada) {
            return null;
        }
        return {
            assistidoId: assistidoId,
            descricao: descricaoNormalizada,
            descricaoAssistido: descricaoAssistidoNormalizada,
            dataConsulta: null,
            alta: false,
            hospitalizado: this.state.hospitalizado,
            localTratamento: Local.porBoolVal(this.state.local).dbVal
        };
    }

    salvar = (redirectUrl = '') => {
        const assistido = this.montarAssistidoParaSalvar();
        const sintoma = this.montarSintomaParaSalvar();
        if (!this.validarDadosDoAssistido(assistido)) {
            return;
        }

        this.service.salvarAssistido(assistido)
            .then(resAssistido => {
                const message = !!assistido.id ? 'Assistido atualizado com sucesso!' : 'Assistido cadastrado com sucesso!';
                this.props.enqueueSnackbar(message, {
                    variant: 'success',
                });

                if (sintoma) {
                    sintoma['assistidoId'] = resAssistido.id;
                    this.service.adicionarSintoma(sintoma)
                        .then(resSintoma => {
                            this.props.enqueueSnackbar(`Sintoma adicionado com sucesso!`, {
                                variant: 'success',
                            });
                            this.setState({
                                sintomas: resSintoma,
                                descricaoSintoma: '',
                                descricaoSintomaAssistido: '',
                                local: false,
                                hospitalizado: false,
                            });
                        }).catch(err => {
                            this.props.enqueueSnackbar('Não foi possível salvar o sintoma', {
                                variant: 'error',
                            });
                        }).finally(() => {
                            if (redirectUrl) {
                                window.location.href = redirectUrl;
                            } else {
                                this.setState({
                                    id: resAssistido.id,
                                });
                            }
                        });
                } else if (redirectUrl) {
                    window.location.href = redirectUrl;
                } else {
                    this.setState({
                        id: resAssistido.id,
                    });
                }
            }).catch(err => {
                this.props.enqueueSnackbar('Ocorreu um erro inesperado ao salvar o assistido', {
                    variant: 'error',
                });
            });
    }

    validarCPF(cpf) {
        cpf = cpf.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos

        if (cpf.length !== 11 ||
            cpf === '00000000000' ||
            cpf === '11111111111' ||
            cpf === '22222222222' ||
            cpf === '33333333333' ||
            cpf === '44444444444' ||
            cpf === '55555555555' ||
            cpf === '66666666666' ||
            cpf === '77777777777' ||
            cpf === '88888888888' ||
            cpf === '99999999999') {
            return false;
        }

        // Verifica o primeiro dígito verificador
        let soma = 0;
        for (let i = 0; i < 9; i++) {
            soma += parseInt(cpf.charAt(i)) * (10 - i);
        }
        let resto = 11 - (soma % 11);
        let digitoVerificador1 = resto === 10 || resto === 11 ? 0 : resto;

        if (digitoVerificador1 !== parseInt(cpf.charAt(9))) {
            return false;
        }

        // Verifica o segundo dígito verificador
        soma = 0;
        for (let i = 0; i < 10; i++) {
            soma += parseInt(cpf.charAt(i)) * (11 - i);
        }
        resto = 11 - (soma % 11);
        let digitoVerificador2 = resto === 10 || resto === 11 ? 0 : resto;

        if (digitoVerificador2 !== parseInt(cpf.charAt(10))) {
            return false;
        }

        return true;
    }

    validarDadosDoAssistido = (assistido) => {
        let valido = true;

        if (!assistido.tipo) {
            this.props.enqueueSnackbar('Informe o tipo do assistido!', {
                variant: 'error',
            });
            valido = false;
        }

        if (!assistido.nome) {
            this.props.enqueueSnackbar('Informe o nome do assistido!', {
                variant: 'error',
            });
            valido = false;
        }

        if (!assistido.endereco) {
            this.props.enqueueSnackbar('Informe o endereço do assistido!', {
                variant: 'error',
            });
            valido = false;
        }

        if (!assistido.dataNascimento) {
            this.props.enqueueSnackbar('Informe a data de nascimento do assistido!', {
                variant: 'error',
            });
            valido = false;
        }

        if (assistido.tipo && assistido.tipo !== "P" && !assistido.sexo) {
            this.props.enqueueSnackbar('Informe o sexo do assistido!', {
                variant: 'error',
            });
            valido = false;
        }

        if (assistido.tipo && assistido.tipo !== "H") {
            if (!assistido.nomeProtetor) {
                this.props.enqueueSnackbar('Informe o nome do protetor!', {
                    variant: 'error',
                });
                valido = false;
            } else {
                // Valida padrão de nomes para animais e plantas: Espoleta (Cão) Mariana Silva
                const pattern = /.+?\(.+?\).+?/;
                if (!pattern.test(assistido.nome)) {
                    const exemploPadrao = assistido.tipo === 'A' ? "Nome do animal (Tipo de animal) Nome do protetor" : "Planta (Tipo da planta) Nome do Protetor"
                    this.props.enqueueSnackbar('O Nome do assistido deve seguir o padrão: ' + exemploPadrao, {
                        variant: 'error',
                    });
                    valido = false;
                }
            }
        }

        if (assistido.cpf && !this.validarCPF(assistido.cpf)) {
            this.props.enqueueSnackbar('Número de CPF inválido!', {
                variant: 'error',
            });
            valido = false;
        }

        return valido;
    }

    normalizarString(str) {
        // Remover espaços duplos e fazer trim
        return str && typeof str === 'string' ? str.replace(/\s{2,}/g, ' ').trim() : str;
    }

    montarAssistidoParaSalvar = () => {

        let dataNascimentoIso = null;
        try {
            dataNascimentoIso = format(this.state.nascimento, "yyyy-MM-dd");
        }
        catch (er) {
        }

        return {
            id: this.state.id,
            nome: this.normalizarString(this.state.nome),
            dataNascimento: dataNascimentoIso,
            sexo: this.state.sexo,
            telefone: this.state.telefone,
            endereco: this.normalizarString(this.state.endereco),
            cep: this.normalizarString(this.state.cep),
            cpf: this.normalizarString(this.state.cpf),
            cidade: this.normalizarString(this.state.cidade),
            estado: this.normalizarString(this.state.estado),
            email: this.normalizarString(this.state.email),
            origem: this.state.origem,
            tipo: this.state.tipo,
            nomeProtetor: this.normalizarString(this.state.nomeProtetor)
        };
    }

    componentDidMount() {
        if (this.state.id) {
            this.carregarAssistido(this.state.id);
        }

        this.tipoInput.current.focus();
    }

    onTipoChange = (e) => {
        const state = e.target.value === 'H' ?
            {
                tipo: e.target.value,
                nomeProtetor: ''
            } : {
                tipo: e.target.value
            };
        this.setState(state);
    }

    onLocalChange = (e) => {
        this.setState({
            local: !this.state.local
        });
    }

    onCpfChange = (event) => {
        const { value } = event.target;
        const digitsOnly = value.replace(/\D/g, '');
        this.setState({ cpf: digitsOnly });
    };

    verificarCepExistente = () => {
        const cep = this.state.cep;
        this.cepService.procurarCep(cep)
            .then(res => {
                if (res.erro) {
                    this.props.enqueueSnackbar('Não foi possível obter os dados do endereço com o CEP informado', {
                        variant: 'warning',
                    });
                } else {
                    let cidadeAtualizada = res.localidade;
                    let estadoAtualizado = res.uf;
                    let enderecoAtualizado = res.logradouro;
                    this.props.enqueueSnackbar('Cidade, estado e endereço atualizados com dados do CEP!', {
                        variant: 'info',
                    });
                    this.setState({
                        cidade: cidadeAtualizada,
                        estado: estadoAtualizado,
                        endereco: enderecoAtualizado,
                        cep: cep
                    });
                }
            })
            .catch(err => {
                this.props.enqueueSnackbar('Não foi possível obter os dados do endereço com o CEP informado', {
                    variant: 'warning',
                });
            })
    }

    onCepChange = (e) => {
        const { value } = e.target;
        const digitsOnly = value.replace(/\D/g, '');
        this.setState({ cep: digitsOnly });
    }

    onCidadeChange = (e) => {
        this.setState({
            cidade: e.target.value
        });
    }

    onEmailChange = (newValue) => {
        this.setState({
            email: newValue ? newValue.toLowerCase() : ''
        });
    }

    onEnderecoChange = (e) => {
        this.setState({
            endereco: e.target.value
        });
    }

    onEstadoChange = (e) => {
        this.setState({
            estado: e.target.value
        });
    }

    onNascimentoChange = (e) => {
        this.setState({
            nascimento: e
        });

        try {
            const dataNascimentoIso = jsDateToIsoDate(e);
            const idade = calcularIdade(dataNascimentoIso);
            this.setState({
                idade: idade
            });
        } catch {
            this.setState({
                idade: ''
            });
        }
    }

    onNomeChange = (e) => {
        const { value } = e.target;
        const nomeNormalizado = value.replace(/\s{2,}/g, '');
        this.setState({
            nome: nomeNormalizado
        });
    }

    onNomeProtetorChange = (e) => {
        this.setState({
            nomeProtetor: e.target.value
        });
    }

    onDescricaoSintomaChange = (e) => {
        this.setState({
            descricaoSintoma: e.target.value
        });
    }

    onDescricaoSintomaAssistidoChange = (e) => {
        this.setState({
            descricaoSintomaAssistido: e.target.value
        });
    }

    onOrigemChange = (e) => {
        this.setState({
            origem: e.target.value
        });
    }

    onSexoChange = (e) => {
        this.setState({
            sexo: e.target.value
        });
    }

    onHospitalizadoChange = (e) => {
        this.setState({
            hospitalizado: !this.state.hospitalizado
        });
    }

    onTelefoneChange = (e) => {
        this.setState({
            telefone: e
        });
    }

    onNomeFocus = (e) => {
        e.preventDefault();
        this.nomeInput.current.focus();
    }

    onFemininoFocus = (e) => {
        e.preventDefault();
        this.setState({
            sexo: 'F'
        });
    }

    onMasculinoFocus = (e) => {
        e.preventDefault();
        this.setState({
            sexo: 'M'
        });
    }

    onPCDFocus = (e) => {
        e.preventDefault();
        this.setState({
            origem: 'P'
        });
    }

    onInternetFocus = (e) => {
        e.preventDefault();
        this.setState({
            origem: 'I'
        });
    }

    onEnderecoFocus = (e) => {
        e.preventDefault();
        this.enderecoInput.current.focus();
    }

    onEstadoFocus = (e) => {
        e.preventDefault();
        this.estadoInput.current.focus();
    }

    onCidadeFocus = (e) => {
        e.preventDefault();
        this.cidadeInput.current.focus();
    }

    onCepFocus = (e) => {
        e.preventDefault();
        this.cepInput.current.focus();
    }

    onCpfFocus = (e) => {
        e.preventDefault();
        this.cpfInput.current.focus();
    }

    onEmailFocus = (e) => {
        e.preventDefault();
        this.emailInput.current.focus();
    }

    onHumanoFocus = (e) => {
        e.preventDefault();
        this.setState({
            tipo: "H"
        });
    }

    onAnimalFocus = (e) => {
        e.preventDefault();
        this.setState({
            tipo: "A"
        });
    }

    onPlantaFocus = (e) => {
        e.preventDefault();
        this.setState({
            tipo: "P"
        });
    }

    onLocalFocus = (e) => {
        e.preventDefault();
        this.onLocalChange(e);
    }

    onnomeProtetorFocus = (e) => {
        e.preventDefault();
        this.nomeProtetorInput.current.focus();
    }

    onSintomasFocus = (e) => {
        e.preventDefault();
        this.sintomaRef.current.focus();
    }

    onSalvarFocus = (e) => {
        e.preventDefault();
        this.salvar(`/assistidos`);
    }

    onSalvarESintomaFocus = (e) => {
        e.preventDefault();
        this.salvar(`/assistidos/${this.state.id}/sintomas`);
    }

    onAjudaFocus = (e) => {
        e.preventDefault();
        this.mostrarAjuda();
    }

    onMostrarMensagemErroClose = (e) => {
        this.setState({
            mostrarMensagemErro: false
        });
    }

    mostrarMensagemErro = () => {
        this.setState({
            mostrarMensagemErro: true
        });
    }

    mostrarAjuda = () => {
        this.setState({
            mostrarAjuda: true
        });
    }

    mostrarHistorico = () => {
        this.setState({
            mostrarHistorico: true
        });
    }

    onModalAjudaClose = () => {
        this.setState({
            mostrarAjuda: false
        });
    };

    onModalHistoricoClose = () => {
        this.setState({
            mostrarHistorico: false
        });
    };

    handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            const fieldName = event.target.name;
            if (fieldName === 'nome') {
                this.verificarNomeExistente();
            } else if (fieldName === 'cpf') {
                this.verificarCpfExistente();
            } else if (fieldName === 'cep') {
                this.verificarCepExistente();
            }
        }
    };

    render() {
        const handlers = {
            FOCO_NOME: this.onNomeFocus,
            FOCO_SEXO_MASC: this.onMasculinoFocus,
            FOCO_SEXO_FEM: this.onFemininoFocus,
            FOCO_ENDERECO: this.onEnderecoFocus,
            FOCO_ESTADO: this.onEstadoFocus,
            FOCO_CIDADE: this.onCidadeFocus,
            FOCO_CEP: this.onCepFocus,
            FOCO_CPF: this.onCpfFocus,
            FOCO_EMAIL: this.onEmailFocus,
            FOCO_ORIGEM_INTERNET: this.onInternetFocus,
            FOCO_ORIGEM_PCD: this.onPCDFocus,
            FOCO_ANIMAL: this.onAnimalFocus,
            FOCO_HUMANO: this.onHumanoFocus,
            FOCO_PLANTA: this.onPlantaFocus,
            FOCO_SINTOMAS: this.onSintomasFocus,
            FOCO_LOCAL: this.onLocalFocus,
            SALVAR: this.onSalvarFocus,
            SALVAR_IR_SINTOMA: this.onSalvarESintomaFocus,
            AJUDA: this.onAjudaFocus,
        };

        return (
            <HotKeys
                keyMap={keyMap}
                handlers={handlers}
            >
                <main>
                    {this.state.mostrarAjuda && <AjudaNovoAssistido onClose={this.onModalAjudaClose} />}
                    {this.state.mostrarHistorico && <CpHistoricoAssistido assistidoId={this.state.id} onClose={this.onModalHistoricoClose} />}
                    {<CpModalAssistidos
                        open={!!this.state.campoAssistidoExistente}
                        campo={this.state.campoAssistidoExistente}
                        assistidos={this.state.assistidosJaCadastrados}
                        onFecharModalAssistidos={this.onFecharModalAssistidos} />}

                    <CpBarraAcoes assistido={{ id: this.state.id }} destacarAcao={Acao.Assistido}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<SaveIcon />}
                            ref={this.salvarInput}
                            onClick={() => this.salvar()}
                        >
                            Salvar
                        </Button>
                        &nbsp;
                        {this.state.id &&
                            <>
                                <Button
                                    variant="contained"
                                    size="small"
                                    startIcon={<AddBoxIcon />}
                                    href='/assistidos'
                                >
                                    Novo
                                </Button>
                                &nbsp;
                                <Button
                                    variant="contained"
                                    color="default"
                                    size="small"
                                    startIcon={<HistoryIcon />}
                                    onClick={this.mostrarHistorico}
                                >
                                    Histórico
                                </Button>
                                &nbsp;
                            </>
                        }
                        <Button
                            variant="contained"
                            color="default"
                            size="small"
                            startIcon={<HelpOutlineIcon />}
                            ref={this.ajudaInput}
                            onClick={this.mostrarAjuda}
                        >
                            Ajuda
                        </Button>
                    </CpBarraAcoes>

                    <Paper className="app-form">
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} lg={4}>
                                <TextField
                                    id="id"
                                    name="id"
                                    label="Nº Controle"
                                    fullWidth
                                    value={this.state.id ? this.state.id : ''}
                                    disabled
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    className='numero-controle'
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={8}>
                                <FormControl>
                                    <InputLabel shrink>
                                        Origem *
                                    </InputLabel>
                                    <RadioGroup
                                        aria-label="origem"
                                        name="origem"
                                        value={this.state.origem}
                                        onChange={this.onOrigemChange}
                                        className="bottom-offset"
                                        row
                                    >
                                        <FormControlLabel
                                            value="I"
                                            control={<Radio color="primary" />}
                                            label="Internet"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="P"
                                            control={<Radio color="primary" />}
                                            label="PCD"
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={4}>
                                <FormControl fullWidth>
                                    <InputLabel shrink id="tipoAssistidoLabel">
                                        Tipo assistido *
                                    </InputLabel>
                                    <Select
                                        autoFocus
                                        labelId="tipoAssistidoLabel"
                                        id="tipoAssistido"
                                        value={this.state.tipo}
                                        onChange={this.onTipoChange}
                                        ref={this.tipoInput}>
                                        <MenuItem value={"H"}>
                                            <TipoAssistidoHumanoIcon style={{ fontSize: '18px', verticalAlign: 'middle', margin: '2px 8px 0 0' }} />
                                            Humano
                                        </MenuItem>
                                        <MenuItem value={"A"}>
                                            <TipoAssistidoAnimalIcon style={{ fontSize: '18px', verticalAlign: 'middle', margin: '2px 8px 0 0' }} />
                                            Animal
                                        </MenuItem>
                                        <MenuItem value={"P"}>
                                            <TipoAssistidoPlantaIcon style={{ fontSize: '18px', verticalAlign: 'middle', margin: '2px 8px 0 0' }} />
                                            Planta
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={4}>
                                <TextField
                                    id="nomeProtetor"
                                    name="nomeProtetor"
                                    label={"Nome do protetor"}
                                    required={!!this.state.tipo && this.state.tipo !== "H"}
                                    fullWidth
                                    value={this.state.nomeProtetor}
                                    inputProps={{ maxLength: 80 }}
                                    onChange={this.onNomeProtetorChange}
                                    onBlur={(e) => this.setState({ nomeProtetor: this.normalizarString(e.target.value) })}
                                    ref={this.nomeProtetorInput}
                                    disabled={!this.state.tipo || this.state.tipo === "H"}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        autoComplete: 'off',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={4}>
                                <FormControl component="fieldset">
                                    <InputLabel shrink>
                                        Sexo do assistido {this.state.tipo !== 'P' ? '*' : ''}
                                    </InputLabel>
                                    <div>
                                        <RadioGroup
                                            name="sexo"
                                            value={this.state.sexo}
                                            onChange={this.onSexoChange}
                                            className="bottom-offset"
                                            row
                                        >
                                            <FormControlLabel
                                                value="M"
                                                control={<Radio color="primary" />}
                                                label={this.state.tipo === 'A' ? 'Macho' : 'Masculino'}
                                                labelPlacement="end" />
                                            <FormControlLabel
                                                value="F"
                                                control={<Radio color="primary" />}
                                                label={this.state.tipo === 'A' ? 'Fêmea' : 'Feminino'}
                                                labelPlacement="end" />
                                        </RadioGroup>
                                    </div>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={8}>
                                <TextField
                                    required
                                    id="nome"
                                    name="nome"
                                    label="Nome do assistido"
                                    fullWidth
                                    ref={this.nomeInput}
                                    value={this.state.nome}
                                    inputProps={{ maxLength: 80 }}
                                    onChange={this.onNomeChange}
                                    onKeyDown={this.handleKeyDown}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        autoComplete: 'off',
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={this.verificarNomeExistente} edge="end" color='primary'>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={3}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                                    <KeyboardDatePicker
                                        id="nascimento"
                                        label="Data de nascimento"
                                        name="nascimento"
                                        disableToolbar
                                        value={this.state.nascimento}
                                        onChange={this.onNascimentoChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        format="dd/MM/yyyy"
                                        invalidDateMessage="Formato de data inválido. Utilize dd/mm/yyyy"
                                        required
                                        inputProps={{
                                            autocomplete: 'off'
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={1}>
                                <TextField
                                    id="idade"
                                    name="idade"
                                    label="Idade"
                                    fullWidth
                                    value={this.state.idade}
                                    disabled
                                    InputProps={{
                                        disableUnderline: true,
                                        style: {
                                            color: '#0000009e',
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                        style: {
                                            color: '#626262',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <CpEmailAutocomplete
                                    id="email"
                                    name="email"
                                    label="E-mail"
                                    fullWidth
                                    value={this.state.email}
                                    inputProps={{ maxLength: 120 }}
                                    onChange={this.onEmailChange}
                                    onBlur={(e) => this.setState({ email: this.normalizarString(e.target.value) })}
                                    inputRef={this.emailInput}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={4}>
                                <PhoneNumber
                                    id="telefone"
                                    label="Telefone"
                                    fullWidth
                                    autoComplete="telefone"
                                    defaultCountry={"br"}
                                    value={this.state.telefone}
                                    inputProps={{ maxLength: 20 }}
                                    onBlur={(e) => this.setState({ telefone: this.normalizarString(e.target.value) })}
                                    onChange={this.onTelefoneChange}
                                    InputProps={{
                                        autoComplete: 'off',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={4}>
                                <TextField
                                    id="cpf"
                                    name="cpf"
                                    label="CPF"
                                    fullWidth
                                    value={this.state.cpf}
                                    inputProps={{ maxLength: 11 }}
                                    onChange={this.onCpfChange}
                                    onBlur={this.normalizarCpf}
                                    ref={this.cpfInput}
                                    onKeyDown={this.handleKeyDown}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        autoComplete: 'off',
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={this.verificarCpfExistente} edge="end" color='primary'>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} lg={4}>
                                <TextField
                                    id="cep"
                                    name="cep"
                                    label="CEP"
                                    fullWidth
                                    autoComplete="cep"
                                    value={this.state.cep}
                                    inputProps={{ maxLength: 9 }}
                                    onChange={this.onCepChange}
                                    ref={this.cepInput}
                                    onKeyDown={this.handleKeyDown}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        autoComplete: 'off',
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={this.verificarCepExistente} edge="end" color='primary'>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    id="cidade"
                                    name="cidade"
                                    label="Cidade"
                                    fullWidth
                                    autoComplete="cidade"
                                    value={this.state.cidade}
                                    inputProps={{ maxLength: 60 }}
                                    onChange={this.onCidadeChange}
                                    onBlur={(e) => this.setState({ cidade: this.normalizarString(e.target.value) })}
                                    ref={this.cidadeInput}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        autoComplete: 'off',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    id="estado"
                                    name="estado"
                                    label="Estado"
                                    fullWidth
                                    autoComplete="estado"
                                    value={this.state.estado}
                                    inputProps={{ maxLength: 20 }}
                                    onChange={this.onEstadoChange}
                                    onBlur={(e) => this.setState({ estado: this.normalizarString(e.target.value) })}
                                    ref={this.estadoInput}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        autoComplete: 'off',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12}>
                                <TextField
                                    required
                                    id="endereco"
                                    name="endereco"
                                    label="Endereço"
                                    fullWidth
                                    value={this.state.endereco}
                                    inputProps={{ maxLength: 200 }}
                                    onChange={this.onEnderecoChange}
                                    onBlur={(e) => this.setState({ endereco: this.normalizarString(e.target.value) })}
                                    ref={this.enderecoInput}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        autoComplete: 'off',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper className="app-form" style={{ margin: '5px 0' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} lg={12}>
                                <Typography variant='subtitle2' >Adicionar Sintomas</Typography>
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <FormControl style={{ width: '100%' }}>
                                    <InputLabel shrink >
                                        Local preferencial do tratamento
                                    </InputLabel>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.local}
                                                onChange={this.onLocalChange}
                                                value={this.state.local}
                                                color="primary"
                                                ref={this.localInput}
                                            />
                                        }
                                        label="Casa do Consolador"
                                        className="bottom-offset"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} sm={6} lg={3}>
                                <FormControl component="fieldset" style={{ width: '100%' }}>
                                    <InputLabel shrink >
                                        Situação do assistido
                                    </InputLabel>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.hospitalizado}
                                                onChange={this.onHospitalizadoChange}
                                                value={this.state.hospitalizado}
                                                color="primary"
                                            />
                                        }
                                        label="Hospitalizado"
                                        className="bottom-offset"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} >
                                <TextField
                                    id="descricaoSintoma"
                                    name="descricaoSintoma"
                                    label="Resumo dos sintomas"
                                    fullWidth
                                    value={this.state.descricaoSintoma}
                                    ref={this.descricaoSintomaRef}
                                    onChange={this.onDescricaoSintomaChange}
                                    multiline
                                    InputProps={{
                                        autoComplete: 'off',
                                    }}
                                    inputProps={{
                                        maxLength: 5000,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12}>
                                <TextField
                                    id="descricaoSintomaAssistido"
                                    name="descricaoSintomaAssistido"
                                    label="Sintomas enviados pelo assistido"
                                    fullWidth
                                    value={this.state.descricaoSintomaAssistido}
                                    onChange={this.onDescricaoSintomaAssistidoChange}
                                    multiline
                                    InputProps={{
                                        autoComplete: 'off',
                                    }}
                                    inputProps={{
                                        maxLength: 5000,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            {!!this.state.sintomas.length && (
                                <Grid item xs={12} lg={12}>
                                    <CpListaSintomas
                                        sintomas={this.state.sintomas}
                                        colunas={[ColunaSintoma.DATA_CADASTRO, ColunaSintoma.ALTA, ColunaSintoma.DESC]}
                                        altaReadOnly={true}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Paper>
                </main>
            </HotKeys>
        )
    }
}


export default withSnackbar(PgAssistido);
