import { differenceInMonths, differenceInWeeks, differenceInYears, format, nextMonday, parseISO, previousMonday, previousSaturday } from "date-fns";

export class AssistidoDecorator {
    constructor(assistido) {
        this.assistido = assistido;
        this.undecorate();
        this.decorate();
    }

    montarEndereco = () => {
        let endereco = '';
        if (this.assistido.endereco) {
            endereco = this.assistido.endereco;
            if (this.assistido.cidade) {
                endereco += `, ${this.assistido.cidade}`;
            }
            if (this.assistido.estado) {
                endereco += ` - ${this.assistido.estado}`;
            }
        }
        return endereco;
    };

    decorate() {
        if (this.assistido.dataNascimento) {
            this.assistido.strDataNascimento = format(new Date(this.assistido.dataNascimento), 'dd/MM/yyyy');
            this.assistido.idade = calcularIdade(this.assistido.dataNascimento);
            this.assistido.enderecoCompleto = this.montarEndereco();
        } 
        if (!!this.assistido.sintomas?.length) {
            this.assistido.ultimoSintoma = this.assistido.sintomas[this.assistido.sintomas.length - 1];
        }
        if (!!this.assistido.tratamentos?.length) {
            const inicioDoDia = zerarTempo(new Date());
            const tratamentosEmAndamento = this.assistido.tratamentos.filter(tratamento => {
                const dataPrescricao = zerarTempo(new Date(tratamento.dataPrescricao));
                const dataFim = zerarTempo(new Date(tratamento.dataFim));
                return dataPrescricao <= inicioDoDia && dataFim >= inicioDoDia;
            });
            if(!!tratamentosEmAndamento.length){
                this.assistido.tratamentosEmAndamento = tratamentosEmAndamento;
                if(tratamentosEmAndamento.length == 1){
                    const tratamento = tratamentosEmAndamento[0];
                    const weekDiff = differenceInWeeks(new Date(tratamento.dataFim),previousSaturday(new Date()));
                    this.assistido.semanasRestantesTratamento = Math.min(weekDiff,tratamento.quantidadeCromos);
                }else{
                    this.assistido.semanasRestantesTratamento = '...';
                }
            }
        }
    }

    undecorate() {
        delete this.assistido.strDataNascimento;
        delete this.assistido.idade;
        delete this.assistido.enderecoCompleto;
        delete this.assistido.tratamentosEmAndamento;
        delete this.assistido.semanasRestantesTratamento;
    }
}


export const zerarTempo = (data) => {
    const novaData = new Date(data);
    novaData.setHours(0, 0, 0, 0);
    return novaData;
};

export const decorateAssistido = (assistido) => {
    new AssistidoDecorator(assistido);
    return assistido;
}

export const calcularIdade = (dataNascimento) => {
    let idade = "";
    if (dataNascimento) {
        const isoDate = parseISO(dataNascimento);
        const mesesIdade = differenceInMonths(new Date(), isoDate);
        if (mesesIdade === 0) {
            idade = '1 mês';
        } else if (mesesIdade <= 11) {
            idade = mesesIdade + (mesesIdade <= 1 ? ' mês' : ' meses');
        } else {
            const anosIdade = differenceInYears(new Date(), isoDate);
            idade = anosIdade === 1 ? anosIdade + ' ano' : anosIdade + ' anos';
        }
        return idade;
    }
    return idade;
}
