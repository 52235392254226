import React from 'react';
import { Grid, Paper } from '@material-ui/core';

const PrintableReport = ({ assistido, tratamento, CpRelatorioTratamento, CpRelatorioCromo }) => {
  return (
    <div className="printable-report">
      <div className="page">
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Paper elevation={0}>
              <CpRelatorioTratamento assistido={assistido} tratamento={tratamento} />
            </Paper>
          </Grid>
          {tratamento?.cromoterapias?.length > 0 && (
            <Grid item xs={6}>
              <Paper elevation={0}>
                <CpRelatorioCromo assistido={assistido} tratamento={tratamento} />
              </Paper>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default PrintableReport;