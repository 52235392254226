import {
    IconButton,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/DeleteForeverOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { isoToStrDate } from "../../services/date-utils";
import SintomaService from "../../services/sintomas";
import { Local } from "../assistidos/enums";
import CpDeletarDialog from "../cp-deletar-dialog";
import CpDescricaoSintoma from "./cp-descricao-sintoma";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    table: {
        tableLayout: "auto",
    },
    semAlta: {
        backgroundColor: "#F2F0DF",
    },
    alta: {
        backgroundColor: "#C2E5D3",
    },
    textTableCell: {
        textAlign: 'left',
        paddingTop: "25px",
        verticalAlign: "top",
    },
    componentTableCell: {
        textAlign: 'left',
        paddingTop: "15px",
        verticalAlign: "top",
    },
    tableCell: {
        textAlign: 'left',
        verticalAlign: "top",
    },
    acoesCell: {
        textAlign: 'left',
        verticalAlign: "top",
        paddingTop: "8px",
        minWidth: 150,
    },
    switchCell: {
        textAlign: 'left',
        verticalAlign: "top",
        padding: "15px 0",
    },
}));

export const ColunaSintoma = {
    DATA_CADASTRO: 'Cadastro',
    DATA_CONSULTA: 'Consulta',
    ALTA: 'Alta',
    HOSPITALIZADO: 'Hospitalizado',
    LOCAL: 'Local',
    DESC: 'Descrição',
    ACOES: 'Ações'
};

export default function CpListaSintomas({ sintomas, atualizarSintomas, colunas, altaReadOnly = false }) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const sintomaService = new SintomaService();

    const [sintomaParaDeletar, setSintomaParaDeletar] = useState("");

    const allColumns = Object.values(ColunaSintoma);
    const columnsToDisplay = colunas || allColumns;

    const onConfirmarDeletarSintoma = () => {
        sintomaService.deletarSintoma(sintomaParaDeletar.id)
            .then((res) => {
                console.log('deletarSintoma', res);
                enqueueSnackbar(`Sintoma deletado com sucesso!`, {
                    variant: "success",
                });
                setSintomaParaDeletar('');
                atualizarSintomas(res);
            })
            .catch((err) => {
                enqueueSnackbar(`Ocorreu um erro inesperado ao remover o sintoma`, {
                    variant: "error",
                });
                setSintomaParaDeletar('');
            });
    };

    const handleAltaChange = (sintoma) => (event) => {
        const novaAlta = event.target.checked;
        const novoSintoma = {
            ...sintoma,
            alta: novaAlta,
        };
        sintomaService.salvarSintoma(novoSintoma)
            .then((res) => {
                console.log('salvarSintoma', res);
                enqueueSnackbar(`Alta atualizada com sucesso!`, {
                    variant: "success",
                });
                atualizarSintomas(res);
            }).catch((err) => {
                enqueueSnackbar(`Ocorreu um erro inesperado ao atualizar a alta`, {
                    variant: "error",
                });
            });
    };

    const onDeletarSintomaClick = (sintoma) => {
        setSintomaParaDeletar(sintoma);
    };

    const fecharModalDeletar = () => {
        setSintomaParaDeletar("");
    };

    if (!sintomas?.length) {
        return <Typography variant='subtitle2' >Nenhum sintoma encontrado. Adicione novos sintomas para vê-los aqui</Typography>;
    }

    return (
        <div className={classes.root}>
            <CpDeletarDialog
                toDelete={sintomaParaDeletar}
                onConfirm={onConfirmarDeletarSintoma}
                onClose={fecharModalDeletar}
                title={"Remover Sintoma"}
            />
            <Typography variant='subtitle2' paragraph>Histórico de Sintomas</Typography>
            <Paper elevation={3}>
                <Table className={classes.table} size="small">
                    <TableHead>
                        <TableRow>
                            {columnsToDisplay.includes(ColunaSintoma.DATA_CADASTRO) && <TableCell>Cadastro</TableCell>}
                            {columnsToDisplay.includes(ColunaSintoma.DATA_CONSULTA) && <TableCell>Consulta</TableCell>}
                            {columnsToDisplay.includes(ColunaSintoma.ALTA) && <TableCell>Alta</TableCell>}
                            {columnsToDisplay.includes(ColunaSintoma.HOSPITALIZADO) && <TableCell>Hosp.</TableCell>}
                            {columnsToDisplay.includes(ColunaSintoma.LOCAL) && <TableCell>Local&nbsp;&nbsp;&nbsp;</TableCell>}
                            {columnsToDisplay.includes(ColunaSintoma.DESC) && <TableCell>Descrição</TableCell>}
                            {columnsToDisplay.includes(ColunaSintoma.ACOES) && <TableCell className={classes.acoesCell}>Ações</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sintomas.map((sintoma) => (
                            <TableRow key={sintoma.id} className={!sintoma.alta ? classes.semAlta : classes.alta}>
                                {columnsToDisplay.includes(ColunaSintoma.DATA_CADASTRO) && (
                                    <TableCell className={classes.textTableCell}>
                                        {isoToStrDate(sintoma.dataCadastro)}
                                    </TableCell>
                                )}
                                {columnsToDisplay.includes(ColunaSintoma.DATA_CONSULTA) && (
                                    <TableCell className={classes.textTableCell}>
                                        {isoToStrDate(sintoma.dataConsulta)}
                                    </TableCell>
                                )}
                                {columnsToDisplay.includes(ColunaSintoma.ALTA) && (
                                    altaReadOnly ? (
                                        <TableCell className={classes.textTableCell}>
                                            {sintoma.alta ? "Sim" : "Não"}
                                        </TableCell>
                                    ) : (
                                        <TableCell className={classes.switchCell}>
                                            <Switch
                                                checked={sintoma.alta}
                                                onChange={handleAltaChange(sintoma)}
                                                color="primary"
                                            />
                                        </TableCell>
                                    )
                                )}
                                {columnsToDisplay.includes(ColunaSintoma.HOSPITALIZADO) && (
                                    <TableCell className={classes.textTableCell}>
                                        {sintoma.hospitalizado ? "Sim" : "Não"}
                                    </TableCell>
                                )}
                                {columnsToDisplay.includes(ColunaSintoma.LOCAL) && (
                                    <TableCell className={classes.textTableCell}>
                                        {Local.porDbVal(sintoma.localTratamento).shortDesc}
                                    </TableCell>
                                )}
                                {columnsToDisplay.includes(ColunaSintoma.DESC) && (
                                    <TableCell>
                                        <CpDescricaoSintoma sintoma={sintoma} />
                                    </TableCell>
                                )}
                                {columnsToDisplay.includes(ColunaSintoma.ACOES) && (
                                    <TableCell className={classes.acoesCell}>
                                        <IconButton size="medium" href={"/editarSintoma/" + sintoma.id}>
                                            <EditIcon fontSize="inherit" color="primary" />
                                        </IconButton>
                                        <IconButton
                                            aria-label="remover o sintoma"
                                            size="medium"
                                            onClick={() => onDeletarSintomaClick(sintoma)}
                                        >
                                            <DeleteIcon fontSize="inherit" color="error" />
                                        </IconButton>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </div>
    );
}
