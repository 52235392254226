import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import React from "react";
import { Posicao } from '../assistidos/enums';
import { Local } from '../assistidos/enums';
import { isoDateToStrDayAndStrDate } from '../../services/date-utils';

const useStyles = makeStyles({
    lightRedBackground: {
        backgroundColor: '#ffebee',
    },
    conflito: {
        fontWeight: 'bold',
        color: 'darkred'
    },
});

export default function CpRowCirurgia(props) {
    const classes = useStyles();

    return (
        <TableRow className={props.cirurgia.conflitante ? classes.lightRedBackground : ''} hover role="checkbox">
            <TableCell className={classes.checkboxCell}>
                <Checkbox
                    checked={props.selecionado}
                    onChange={() => props.toggleSelecionado(props.cirurgia)}
                />
            </TableCell>
            <TableCell component="th" scope="row" className={props.cirurgia.conflitante ? classes.conflito : ''}>
                {isoDateToStrDayAndStrDate(props.cirurgia.dataAgendada)}
            </TableCell>
            <TableCell className={props.conflitante ? classes.conflito : ''}>
                {props.cirurgia.horarioAgendado.split(':').slice(0, 2).join(':')}
            </TableCell>
            <TableCell>
                {Local.porDbVal(props.cirurgia.localAtendimento).shortDesc}
            </TableCell>
            <TableCell>
                {props.cirurgia.descricao}
            </TableCell>
            <TableCell>
                {Posicao.getListaStr(props.cirurgia)}
            </TableCell>
            <TableCell width='150'>
                <Tooltip title="Editar a cirurgia" disableFocusListener>
                    <IconButton
                        size="medium"
                        href={'/editarCirurgia/' + props.cirurgia.id}
                    >
                        <EditIcon fontSize="inherit" color="primary" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Deletar a cirurgia" disableFocusListener>
                    <IconButton
                        size="medium"
                        onClick={() => props.onDeletarCirurgiaClick(props.cirurgia)}
                    >
                        <DeleteIcon fontSize="inherit" color="error" />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
}
