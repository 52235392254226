import * as Icons from '@material-ui/icons';

const HelpSection = {
    salvar: 'Atalhos para salvar a cirurgia',
    tratamentos: 'Atalhos dos tratamentos',
    cromos: 'Atalhos das cromos',
}

const Atalhos = {
    AJUDA: {
        sequence: 'f1',
    },
    SALVAR_IR_CIRURGIAS: {
        sequence: 'pageup',
        help: {
            section: HelpSection.salvar,
            icon: Icons.Save,
            title: 'pageup',
            desc: 'Salvar a cirurgia e ir para a página de cirurgias'
        },
    },
    SALVAR_IR_CROMO: {
        sequence: 'pagedown',
        help: {
            section: HelpSection.salvar,
            icon: Icons.Save,
            title: 'pagedown',
            desc: 'Salvar a cirurgia e ir para a página de cromoterapias'
        }
    },
    FOCO_QUANTIDADE_CROMOS: {
        sequence: 'alt+q',
        help: {
            section: HelpSection.tratamentos,
            icon: Icons.CenterFocusStrong,
            title: 'alt+q',
            desc: 'Seleciona o campo Quantidade de Cromos'
        }
    },
    FOCO_OUTROS_TRATAMENTOS: {
        sequence: 'alt+o',
        help: {
            section: HelpSection.tratamentos,
            icon: Icons.CenterFocusStrong,
            title: 'alt+o',
            desc: 'Seleciona o campo Outros Tratamentos'
        }
    },
    TOGGLE_CROMO: {
        sequence: 'alt+t',
        help: {
            section: HelpSection.tratamentos,
            icon: Icons.CheckBoxOutlined,
            title: 'alt+t',
            desc: 'Marcar/Desmarcar o tratamento Cromoterapia'
        }
    },
    TOGGLE_CROMO_DIARIA: {
        sequence: 'alt+c',
        help: {
            section: HelpSection.tratamentos,
            icon: Icons.CheckBoxOutlined,
            title: 'alt+c',
            desc: 'Marcar/Desmarcar o tratamento Cromo Diária'
        }
    },
    TOGGLE_DESOBSESSAO: {
        sequence: 'alt+d',
        help: {
            section: HelpSection.tratamentos,
            icon: Icons.CheckBoxOutlined,
            title: 'alt+d',
            desc: 'Marcar/Desmarcar o tratamento Desobsessão'
        }
    },
    TOGGLE_EVANGELHO: {
        sequence: 'alt+e',
        help: {
            section: HelpSection.tratamentos,
            icon: Icons.CheckBoxOutlined,
            title: 'alt+e',
            desc: 'Marcar/Desmarcar o tratamento Evangelho'
        }
    },
    TOGGLE_UMBANDA: {
        sequence: 'alt+u',
        help: {
            section: HelpSection.tratamentos,
            icon: Icons.CheckBoxOutlined,
            title: 'alt+u',
            desc: 'Marcar/Desmarcar o tratamento Umbanda'
        }
    },
    TOGGLE_VIBRACIONAL: {
        sequence: 'alt+v',
        help: {
            section: HelpSection.tratamentos,
            icon: Icons.CheckBoxOutlined,
            title: 'alt+v',
            desc: 'Marcar/Desmarcar o tratamento Vibracional'
        }
    },
    TOGGLE_PSICANALISE: {
        sequence: 'alt+p',
        help: {
            section: HelpSection.tratamentos,
            icon: Icons.CheckBoxOutlined,
            title: 'alt+p',
            desc: 'Marcar/Desmarcar o tratamento Psicanálise'
        }
    },
    TOGGLE_CTD: {
        sequence: 'alt+s',
        help: {
            section: HelpSection.tratamentos,
            icon: Icons.CheckBoxOutlined,
            title: 'alt+s',
            desc: 'Marcar/Desmarcar o tratamento CTD'
        }
    },
    TOGGLE_TERRA_DE_ELFOS: {
        sequence: 'alt+f',
        help: {
            section: HelpSection.tratamentos,
            icon: Icons.CheckBoxOutlined,
            title: 'alt+f',
            desc: 'Marcar/Desmarcar o tratamento Terra de Elfos'
        }
    },
    TOGGLE_TABAGISMO: {
        sequence: 'alt+t',
        help: {
            section: HelpSection.tratamentos,
            icon: Icons.CheckBoxOutlined,
            title: 'alt+t',
            desc: 'Marcar/Desmarcar o tratamento Tabagismo'
        }
    },
    TOGGLE_REIKI: {
        sequence: 'alt+r',
        help: {
            section: HelpSection.tratamentos,
            icon: Icons.CheckBoxOutlined,
            title: 'alt+r',
            desc: 'Marcar/Desmarcar o tratamento Reiki'
        }
    },
    TOGGLE_KARUNA: {
        sequence: 'alt+k',
        help: {
            section: HelpSection.tratamentos,
            icon: Icons.CheckBoxOutlined,
            title: 'alt+k',
            desc: 'Marcar/Desmarcar o tratamento Karuna'
        }
    },
    TOGGLE_XAMANISMO: {
        sequence: 'alt+x',
        help: {
            section: HelpSection.tratamentos,
            icon: Icons.CheckBoxOutlined,
            title: 'alt+x',
            desc: 'Marcar/Desmarcar o tratamento Xamanismo'
        }
    },
    TOGGLE_PREPARACAO_LADO: {
        sequence: 'alt+l',
        help: {
            section: HelpSection.tratamentos,
            icon: Icons.CheckBoxOutlined,
            title: 'alt+l',
            desc: 'Marcar/Desmarcar o campo de Preparação: Lado'
        }
    },
    TOGGLE_PREPARACAO_BRUCOS: {
        sequence: 'alt+b',
        help: {
            section: HelpSection.tratamentos,
            icon: Icons.CheckBoxOutlined,
            title: 'alt+b',
            desc: 'Marcar/Desmarcar o campo de Preparação: Bruços'
        }
    },
    TOGGLE_PREPARACAO_COSTAS: {
        sequence: 'alt+a',
        help: {
            section: HelpSection.tratamentos,
            icon: Icons.CheckBoxOutlined,
            title: 'alt+a',
            desc: 'Marcar/Desmarcar o campo de Preparação: Costas'
        }
    },
    TOGGLE_PREPARACAO_MAIS_CONFORTAVEL: {
        sequence: 'alt+m',
        help: {
            section: HelpSection.tratamentos,
            icon: Icons.CheckBoxOutlined,
            title: 'alt+m',
            desc: 'Marcar/Desmarcar o campo de Preparação: Mais confortável'
        }
    },
    TOGGLE_PREPARACAO_NAO_SE_APLICA: {
        sequence: 'alt+n',
        help: {
            section: HelpSection.tratamentos,
            icon: Icons.CheckBoxOutlined,
            title: 'alt+n',
            desc: 'Marcar/Desmarcar o campo de Preparação: Não se aplica'
        }
    },
    ADICIONAR_LINHA: {
        sequence: ['ctrl+plus,ctrl+m'],
        help: {
            section: HelpSection.cromos,
            icon: Icons.VerticalAlignBottom,
            title: 'Adicionar linha',
            desc: 'Adicionar uma nova linha de cromoterapia'
        }
    },
    EXCLUIR_LINHA: {
        sequence: 'ctrl+-',
        help: {
            section: HelpSection.cromos,
            icon: Icons.DeleteForeverOutlined,
            title: 'Excluir linha',
            desc: 'Excluir a linha de cromoterapia'
        }
    },
    PRESS_ENTER: {
        sequence: 'enter',
        help: {
            section: HelpSection.cromos,
            icon: Icons.NavigateNextOutlined,
            title: 'Próximo campo',
            desc: 'Mover para o próximo campo ao pressionar Enter'
        }
    },
    PRESS_F2: {
        sequence: 'f2',
        value:'AZUL-CORONARIO',
        help: {
            section: HelpSection.cromos,
            icon: Icons.ColorLensOutlined,
            title: 'f2',
            desc: 'Definir Cor AZUL e Local CORONARIO'
        }
    },
    PRESS_F3: {
        sequence: 'f3',
        value:'AZUL/PRATEADO-',
        help: {
            section: HelpSection.cromos,
            icon: Icons.ColorLensOutlined,
            title: 'f3',
            desc: 'Definir Cor AZUL/PRATEADO'
        }
    },
    PRESS_F4: {
        sequence: 'f4',
        value:'ROSA/AZUL-',
        help: {
            section: HelpSection.cromos,
            icon: Icons.ColorLensOutlined,
            title: 'f4',
            desc: 'Definir Cor ROSA/AZUL'
        }
    },
    PRESS_F5: {
        sequence: 'f5',
        value:'AZUL/ROSA-',
        help: {
            section: HelpSection.cromos,
            icon: Icons.ColorLensOutlined,
            title: 'f5',
            desc: 'Definir Cor AZUL/ROSA'
        }
    },
    PRESS_F6: {
        sequence: 'f6',
        value:'LILAS/PRATEADO-',
        help: {
            section: HelpSection.cromos,
            icon: Icons.ColorLensOutlined,
            title: 'f6',
            desc: 'Definir Cor LILAS/PRATEADO'
        }
    },
    PRESS_F7: {
        sequence: 'f7',
        value:'VERDE/AMARELO-',
        help: {
            section: HelpSection.cromos,
            icon: Icons.ColorLensOutlined,
            title: 'f7',
            desc: 'Definir Cor VERDE/AMARELO'
        }
    },
    PRESS_F8: {
        sequence: 'f8',
        value:'AMARELO/AZUL-',
        help: {
            section: HelpSection.cromos,
            icon: Icons.ColorLensOutlined,
            title: 'f8',
            desc: 'Definir Cor AMARELO/AZUL'
        }
    },
    PRESS_F9: {
        sequence: 'f9',
        value:'VERMELHO/AZUL-',
        help: {
            section: HelpSection.cromos,
            icon: Icons.ColorLensOutlined,
            title: 'f9',
            desc: 'Definir Cor VERMELHO/AZUL'
        }
    },
    PRESS_F10: {
        sequence: 'f10',
        value:'VERMELHO/AMARELO-',
        help: {
            section: HelpSection.cromos,
            icon: Icons.ColorLensOutlined,
            title: 'f10',
            desc: 'Definir Cor VERMELHO/AMARELO'
        }
    },
    PRESS_F11: {
        sequence: 'f11',
        value:'ROSA-',
        help: {
            section: HelpSection.cromos,
            icon: Icons.ColorLensOutlined,
            title: 'f11',
            desc: 'Definir Cor ROSA'
        }
    },
    PRESS_F12: {
        sequence: 'f12',
        value:'AZUL/DOURADO-',
        help: {
            section: HelpSection.cromos,
            icon: Icons.ColorLensOutlined,
            title: 'f12',
            desc: 'Definir Cor AZUL/DOURADO'
        }
    },
    PRESS_SHIFT_F1: {
        sequence: 'shift+f1',
        value:'AZUL-GERAL',
        help: {
            section: HelpSection.cromos,
            icon: Icons.ColorLensOutlined,
            title: 'shift+f1',
            desc: 'Definir Cor AZUL e Local GERAL'
        }
    },
    PRESS_SHIFT_F2: {
        sequence: 'shift+f2',
        value:'AZUL-CORONARIO',
        help: {
            section: HelpSection.cromos,
            icon: Icons.ColorLensOutlined,
            title: 'shift+f2',
            desc: 'Definir Cor AZUL e Local CORONARIO'
        }
    },
    PRESS_SHIFT_F3: {
        sequence: 'shift+f3',
        value:'AZUL/PRATEADO-FRONTAL',
        help: {
            section: HelpSection.cromos,
            icon: Icons.ColorLensOutlined,
            title: 'shift+f3',
            desc: 'Definir Cor AZUL/PRATEADO e Local FRONTAL'
        }
    },
    PRESS_SHIFT_F4: {
        sequence: 'shift+f4',
        value:'ROSA/AZUL-CARDIACO',
        help: {
            section: HelpSection.cromos,
            icon: Icons.ColorLensOutlined,
            title: 'shift+f4',
            desc: 'Definir Cor ROSA/AZUL e Local CARDIACO'
        }
    },
    PRESS_SHIFT_F5: {
        sequence: 'shift+f5',
        value:'AZUL/ROSA-CARDIACO',
        help: {
            section: HelpSection.cromos,
            icon: Icons.ColorLensOutlined,
            title: 'shift+f5',
            desc: 'Definir Cor AZUL/ROSA e Local CARDIACO'
        }
    },
    PRESS_SHIFT_F11: {
        sequence: 'shift+f11',
        value:'ROSA-TIMICO',
        help: {
            section: HelpSection.cromos,
            icon: Icons.ColorLensOutlined,
            title: 'shift+f11',
            desc: 'Definir Cor ROSA e Local TIMICO'
        }
    },
    PRESS_SHIFT_F12: {
        sequence: 'shift+f12',
        value:'AZUL/DOURADO-FRONTAL',
        help: {
            section: HelpSection.cromos,
            icon: Icons.ColorLensOutlined,
            title: 'shift+f12',
            desc: 'Definir Cor AZUL/DOURADO e Local FRONTAL'
        }
    }

}

export {HelpSection};
export default Atalhos;
