import axios from "axios";

class CepService {

    url = 'https://viacep.com.br/ws/{CEP}/json/';

    procurarCep = async (cep) => {
        cep = cep.replace('-', '');
        return new Promise((resolve, reject) => {
            const urlCep = this.url.replace('{CEP}', cep);
            axios.get(urlCep).then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject("Error: " + err);
            })
        })
    };
}

export default CepService;
 