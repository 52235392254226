import Paper from '@material-ui/core/Paper';
import React from "react";
import CpAcoesAssistido from './cp-acoes-assistido';

const CpBarraAcoes = ({ children, assistido, destacarAcao }) => {
    return (
        <Paper className="acoes" style={{ padding:'0px 0px',margin:'5px 0', backgroundColor:'', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ padding:'10px 20px'}}>
                {children}
            </div>
            {assistido?.id && <CpAcoesAssistido assistido={assistido} destacarAcao={destacarAcao}/>}
        </Paper>
    );
};

export default CpBarraAcoes;
