import api, { URL_LOGIN } from "../api_env_config";

class UsuarioService {

    login = async (usuario, senha) => {
        return new Promise((resolve, reject) => {
            const payload = {
                "usuario": usuario,
                "senha": senha
            }
            api.post(URL_LOGIN, payload).then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject("Error: " + err);
            })
        })
    };

    usuarioAutenticado = () => {
        return !!localStorage.getItem("access_token");
    }
}

export default UsuarioService;
 