// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.centralizado {
    text-align: center;
    display: 'flex';
}

.rodape-cromo {
    font-size: 6px;
}

.fonte-report-cromo {
    font-size: 12px;
}

.bottom-offset {
    position: relative;
    bottom: -12px;
}

.app-form {
    width: 100%;
    justify-content: center;
    margin: 0 0 0 0;
    padding: 2% 2% 2% 2%;
    display: 'flex';
}
`, "",{"version":3,"sources":["webpack://./src/pages/estilo.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,uBAAuB;IACvB,eAAe;IACf,oBAAoB;IACpB,eAAe;AACnB","sourcesContent":[".centralizado {\n    text-align: center;\n    display: 'flex';\n}\n\n.rodape-cromo {\n    font-size: 6px;\n}\n\n.fonte-report-cromo {\n    font-size: 12px;\n}\n\n.bottom-offset {\n    position: relative;\n    bottom: -12px;\n}\n\n.app-form {\n    width: 100%;\n    justify-content: center;\n    margin: 0 0 0 0;\n    padding: 2% 2% 2% 2%;\n    display: 'flex';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
