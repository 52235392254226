import { Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import CpResumoAssistido from './cp-resumo-assistido';

const useStyles = makeStyles(theme => ({
    popover: {
        pointerEvents: 'none',
        padding: theme.spacing(0),
    },
}));

const CpResumoAssistidoPopover = ({ children, assistido }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div
                aria-owns={anchorEl ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                style={{ cursor: 'pointer' }}
            >
                {children}
            </div>
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
                PaperProps={{
                    style: {
                        minWidth: '600px',
                        maxWidth: '800px',
                    }
                }}
            >
                <CpResumoAssistido assistido={assistido} />
            </Popover>
        </>
    );
};

export default CpResumoAssistidoPopover;
