import * as Icons from '@material-ui/icons';

const HelpSection = {
    salvar: 'Atalhos para salvar a cirurgia',
    campos: 'Atalhos dos campos',
}

const Atalhos = {
    AJUDA: {
        sequence: 'f1',
    },
    SALVAR_IR_TRATAMENTOS: {
        sequence: 'pagedown',
        help: {
            section: HelpSection.salvar,
            icon: Icons.Save,
            title: 'pagedown',
            desc: 'Salvar a cirurgia e ir para a página de tratamentos'
        },
    },
    SALVAR_IR_SINTOMAS: {
        sequence: 'pageup',
        help: {
            section: HelpSection.salvar,
            icon: Icons.Save,
            title: 'pageup',
            desc: 'Salvar a cirurgia e ir para a página de sintomas'
        }
    },
    FOCAR_CIRURGIA: {
        sequence: 'alt+c',
        help: {
            section: HelpSection.campos,
            icon: Icons.CenterFocusStrong,
            title: 'alt+c',
            desc: 'Focar no campo Cirurgia'
        }

    },
    TOGGLE_LOCAL: {
        sequence: 'alt+q',
        help: {
            section: HelpSection.campos,
            icon: Icons.CheckBoxOutlined,
            title: 'alt+q',
            desc: 'Marcar/Desmarcar Local',

        }

    },
    TOGGLE_COSTAS: {
        sequence: 'alt+a',
        help: {
            section: HelpSection.campos,
            icon: Icons.CheckBoxOutlined,
            title: 'alt+a',
            desc: 'Marcar/Desmarcar o valor Costas do campo Posição',

        }

    },
    TOGGLE_LADO: {
        sequence: 'alt+l',
        help: {
            section: HelpSection.campos,
            icon: Icons.CheckBoxOutlined,
            title: 'alt+l',
            desc: 'Marcar/Desmarcar o valor Lado do campo Posição'
        }

    },
    TOGGLE_BRUCOS: {
        sequence: 'alt+b',
        help: {
            section: HelpSection.campos,
            icon: Icons.CheckBoxOutlined,
            title: 'alt+b',
            desc: 'Marcar/Desmarcar o valor Bruços do campo Posição'
        }
    },
    TOGGLE_MAIS_CONFORTAVEL: {
        sequence: 'alt+m',
        help: {
            section: HelpSection.campos,
            icon: Icons.CheckBoxOutlined,
            title: 'alt+m',
            desc: 'Marcar/Desmarcar o valor Mais confortável do campo Posição'
        }
    },
}

export default Atalhos;
