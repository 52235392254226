import { Button, ButtonGroup, Divider, FormControl, InputLabel, MenuItem, Paper, Select, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PrintIcon from '@material-ui/icons/Print';
import React, { useEffect, useState } from 'react';
import { listTratamentos } from '../cp-tratamentos/cp-lista-tratamentos';
import CpRelatorioCromo from './cp-relatorio-cromo';
import CpRelatorioTratamento from './cp-relatorio-tratamento';
import CpRelatorioVibracional from './cp-relatorio-vibracional';
import PrintableReport from './printable-report';
import './relatorio-presencial.css';

const PrintableVibracional = ({ assistido, tratamento, CpRelatorioVibracional }) => (
    <div className="printable-report printable-vibracional">
        <div className="page" style={{ padding: '20mm' }}>
            <Paper elevation={0}>
                <CpRelatorioVibracional assistido={assistido} tratamento={tratamento} />
            </Paper>
        </div>
    </div>
);

export default function CpRelatorioPresencial({ assistido }) {
    const [tratamentoSelecionado, setTratamentoSelecionado] = useState(null);

    useEffect(() => {
        if (!!assistido.tratamentos?.length) {
            setTratamentoSelecionado(assistido.tratamentos[0] || null);
        }
    }, [assistido]);

    const handleTratamentoChange = (event) => {
        const tratamento = assistido.tratamentos.find(t => t.id === event.target.value);
        setTratamentoSelecionado(tratamento);
    }

    const handlePrint = () => {
        window.print();
    }

    const handlePrintVibracional = () => {
        const printContent = document.getElementById('printable-vibracional').innerHTML;

        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        document.body.appendChild(iframe);

        iframe.contentDocument.write(`
            <html>
                <head>
                    <title>Relatório Vibracional</title>
                    <style>
                        @media print {
                            body { margin: 0; }
                            @page { size: portrait; margin: 0; }
                        }
                    </style>
                </head>
                <body>${printContent}</body>
            </html>
        `);

        iframe.contentDocument.close();
        iframe.contentWindow.print();

        setTimeout(() => {
            document.body.removeChild(iframe);
        }, 100);
    }

    if (!assistido.tratamentos?.length) {
        return (
            <Paper style={{ margin: '5px 0' }}>
                <Grid container style={{ padding: '20px' }} >
                    <Typography variant='subtitle2'>Não existem tratamentos cadastrados. Cadastre os tratamentos para visualizar o relatório</Typography>
                </Grid>
            </Paper>
        )
    }

    return (
        <>
            <Paper style={{ margin: '5px 0' }}>
                <Grid container style={{ padding: '20px' }} spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="tratamento-label">Tratamento</InputLabel>
                            <Select
                                labelId="tratamento-label"
                                value={tratamentoSelecionado?.id || ''}
                                onChange={handleTratamentoChange}
                            >
                                {assistido.tratamentos.map((tratamento) => (
                                    <MenuItem key={tratamento.id} value={tratamento.id}>
                                        {tratamento.dataPrescricao} - {listTratamentos(tratamento).slice(0, 80)}{listTratamentos(tratamento).length > 80 && '...'}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonGroup variant="contained" color="primary">
                            <Button onClick={handlePrint} startIcon={<PrintIcon />}>
                                Tratamento
                            </Button>
                            {tratamentoSelecionado?.vibracional && (
                                <>
                                    <Button onClick={handlePrintVibracional} startIcon={<PrintIcon />}>
                                        Vibracional
                                    </Button>
                                </>
                            )}
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </Paper>

            <Divider style={{ margin: '16px 0' }} />

            {/* Conteúdo visível na tela */}
            <div className="screen-only">
                <Paper style={{ margin: '5px 0' }} elevation={3}>
                    <Grid container style={{ padding: '10px' }} >
                        <CpRelatorioTratamento assistido={assistido} tratamento={tratamentoSelecionado} />
                    </Grid>
                </Paper>
                {!!tratamentoSelecionado?.cromoterapias?.length && (
                    <Paper style={{ margin: '5px 0' }} elevation={3}>
                        <Grid container style={{ padding: '10px' }} >
                            <CpRelatorioCromo assistido={assistido} tratamento={tratamentoSelecionado} />
                        </Grid>
                    </Paper>
                )}
                {tratamentoSelecionado?.vibracional && (
                    <Paper style={{ margin: '5px 0' }} elevation={3}>
                        <Grid container style={{ padding: '10px' }} >
                            <CpRelatorioVibracional assistido={assistido} tratamento={tratamentoSelecionado} />
                        </Grid>
                    </Paper>
                )}
            </div>

            {/* Componente para impressão dos dois primeiros relatórios */}
            <PrintableReport
                assistido={assistido}
                tratamento={tratamentoSelecionado}
                CpRelatorioTratamento={CpRelatorioTratamento}
                CpRelatorioCromo={CpRelatorioCromo}
            />

            {/* Componente para impressão do relatório vibracional */}
            <div id="printable-vibracional" style={{ display: 'none' }}>
                <PrintableVibracional
                    assistido={assistido}
                    tratamento={tratamentoSelecionado}
                    CpRelatorioVibracional={CpRelatorioVibracional}
                />
            </div>
        </>
    );
}