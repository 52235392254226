import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import logo from '../../assets/logo_consolador.png';

import UsuarioService from '../../services/usuarios';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 'auto', // Centralizar na parte inferior da tela
        minHeight: '100vh', // Ocupar toda a altura da tela
    },
    card: {
        width: '100%',
        maxWidth: 400,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function PgLogin() {
    const [usuario, setUsuario] = useState('');
    const [senha, setSenha] = useState('');
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [msgAlert, setMsgAlert] = useState('');
    const usuarioService = new UsuarioService();

    async function realizarLogin() {
        setLoading(true);
        usuarioService
            .login(usuario, senha)
            .then((res) => {
                setLoading(false);
                localStorage.setItem('access_token', res.data.access_token);
                window.location.href = '/';
            })
            .catch((err) => {
                setLoading(false);
                setAlert(true);
                setMsgAlert('Login não efetuado, confira seus dados e tente novamente!');
                localStorage.removeItem('access_token');
            });
    }

    const classes = useStyles();

    return (
        <Container component="main" className={classes.container}>
            <Card className={classes.card}>
                <CardContent>
                    {alert ? <Alert severity="error">{msgAlert}</Alert> : null}
                    <div className={classes.paper}>
                        <img src={logo} alt="Casa do Consolador" style={{ width: '100%', maxWidth: 200 }} />
                        <form className={classes.form} noValidate>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="usuario"
                                label="Usuário"
                                name="usuario"
                                autoComplete="usuario"
                                autoFocus
                                onChange={(e) => setUsuario(e.target.value)}
                                value={usuario}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="senha"
                                label="Senha"
                                type="password"
                                id="senha"
                                autoComplete="current-password"
                                onChange={(e) => setSenha(e.target.value)}
                                value={senha}
                            />
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={() => realizarLogin()}
                                disabled={loading}
                            >
                                {loading ? <CircularProgress size={20} /> : 'Entrar'}
                            </Button>
                        </form>
                    </div>
                </CardContent>
            </Card>
        </Container>
    );
}
