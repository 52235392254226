import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import RelatorioCirurgiasIcon from '@material-ui/icons/FeaturedPlayListOutlined';
import FeriadosIcon from '@material-ui/icons/SettingsApplicationsOutlined';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ConfigCirurgiasIcon from '@material-ui/icons/SettingsApplicationsOutlined';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExitToApp from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';
import PeopleIcon from '@material-ui/icons/People';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import React from 'react';
import { Link } from "react-router-dom";
import '../../pages/estilo.css';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        height: '100vh',
    },
    toolbar: {
        paddingRight: 24,
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

function Header() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleLogout = () => {
        localStorage.removeItem("access_token");
        window.location.href = '/login';
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        Dr. Espanhol Cromoterapia
                    </Typography>
                    <Tooltip title="Logout" >
                        <IconButton
                            edge="end"
                            onClick={handleLogout}
                            size='medium'
                        ><ExitToApp style={{ color: 'white' }} /></IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <div>
                        <ListItem button component={Link} to="/">
                            <ListItemIcon>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Home" secondary="Atalho - Ctrl-0" />
                        </ListItem>
                        <ListItem button component={Link} to="/pesquisar-assistido">
                            <ListItemIcon>
                                <SearchIcon />
                            </ListItemIcon>
                            <ListItemText primary="Pesquisar Assistido" secondary="Atalho - Ctrl+1" />
                        </ListItem>
                        <ListItem button component={Link} to="/assistidos">
                            <ListItemIcon>
                                <PeopleIcon />
                            </ListItemIcon>
                            <ListItemText primary="Adicionar Assistido" secondary="Atalho - Ctrl+2" />
                        </ListItem>
                        <ListItem button component={Link} to="/relatorio/cirurgias">
                            <ListItemIcon>
                                <RelatorioCirurgiasIcon />
                            </ListItemIcon>
                            <ListItemText primary="Rel. Cirurgias" />
                        </ListItem>
                        <ListItem button component={Link} to="/relatorio/vibracionais">
                            <ListItemIcon>
                                <RelatorioCirurgiasIcon />
                            </ListItemIcon>
                            <ListItemText primary="Rel. Vibracionais" />
                        </ListItem>
                        <ListItem button component={Link} to="/configcirurgias">
                            <ListItemIcon>
                                <ConfigCirurgiasIcon />
                            </ListItemIcon>
                            <ListItemText primary="Config. Cirurgias" />
                        </ListItem>
                        <ListItem button component={Link} to="/feriados">
                            <ListItemIcon>
                                <FeriadosIcon />
                            </ListItemIcon>
                            <ListItemText primary="Config. Feriados" />
                        </ListItem>
                        {/* <ListItem button component={Link} to="/sintomas">
                            <ListItemIcon>
                                <RecordVoiceOverIcon />
                            </ListItemIcon>
                            <ListItemText primary="Adicionar Sintoma" secondary="Atalho - Ctrl+3" />
                        </ListItem>
                        <ListItem button component={Link} to="/cirurgias">
                            <ListItemIcon>
                                <LocalHospitalIcon />
                            </ListItemIcon>
                            <ListItemText primary="Adicionar Cirurgia" secondary="Atalho - Ctrl+4" />
                        </ListItem>
                        <ListItem button component={Link} to="/tratamentos">
                            <ListItemIcon>
                                <ColorizeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Adicionar Tratamento" secondary="Atalho - Ctrl+5" />
                        </ListItem>
                        <ListItem button component={Link} to="/cadastros-internet">
                            <ListItemIcon>
                                <AlternateEmailIcon />
                            </ListItemIcon>
                            <ListItemText primary="Cadastros Internet" secondary="Atalho - Ctrl+8" />
                        </ListItem>
                        <ListItem button>
                            <ListItemIcon>
                                <AccessibilityIcon />
                            </ListItemIcon>
                            <ListItemText primary="Cadastro Locais" secondary="Atalho - Ctrl+5"/>
                        </ListItem> */}
                    </div>
                </List>
            </Drawer>
        </div>
    );
}

export default Header;
