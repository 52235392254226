import { makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { indigo } from '@material-ui/core/colors';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import React, { useState } from 'react';


const useStyles = makeStyles(theme => ({
    avatar: {
        color: '#fff',
        backgroundColor: indigo[500],
    },
}));

const CpAjuda = ({ titulo, subTitulo, atalhos, onClose }) => {
    const classes = useStyles();
    const [aberto, setAberto] = useState(true);

    const getIconComponent = (iconReference) => {
        return iconReference ? React.createElement(iconReference) : null;
    };

    const fechar = () => {
        setAberto(false);
        onClose();
    };

    const groupedItems = Object.values(atalhos).reduce((acc, curr) => {
        const section = curr.help ? curr.help.section : '';
        if (!acc[section]) {
            acc[section] = [];
        }
        if (curr.help) {
            acc[section].push(curr);
        }
        return acc;
    }, {});

    return (
        <div>
            <Dialog
                open={aberto}
                onClose={fechar}
                aria-labelledby="responsive-dialog-title"
                maxWidth='md'
            >
                <DialogTitle>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar className={`${classes.avatar} avatar-small`} style={{ marginRight: '8px' }}>
                            <LiveHelpIcon />
                        </Avatar>
                        <Typography variant="h5" noWrap>
                            <strong>{titulo}</strong>
                        </Typography>
                    </div>


                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                    {subTitulo}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            {Object.entries(groupedItems).map(([section, items]) => (
                                <React.Fragment key={section}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" align="center">
                                            {section}
                                        </Typography>
                                    </Grid>
                                    {items.map((item, index) => (
                                        <Grid item xs={6} key={index}>
                                            <List>
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            {getIconComponent(item.help.icon)}
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary={item.help.title} secondary={item.help.desc} />
                                                </ListItem>
                                            </List>
                                        </Grid>
                                    ))}
                                </React.Fragment>
                            ))}
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={fechar} color="primary" autoFocus>
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default CpAjuda;
