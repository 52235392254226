import DateFnsUtils from '@date-io/date-fns';
import { Button, Paper, Tooltip, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined';
import DeleteAllIcon from '@material-ui/icons/DeleteSweepOutlined';
import SaveIcon from '@material-ui/icons/Save';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import { addWeeks, differenceInDays, format, nextMonday, parse, previousSaturday, setDay, startOfDay } from 'date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import { useSnackbar, withSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom';
import Atalhos, { HelpSection } from '../../pages/pg-tratamentos/atalhos-teclado';
import TratamentosService from '../../services/tratamentos';
import { DiaDaSemana } from '../report/cp-relatorio-tratamento';
import ShrinkLabelTypography from '../shrink-label-typography';
import locais from './locais';
import { DatePattern, strDateToIsoDate } from '../../services/date-utils';

//TODO mover para arquivo de funcao de data
function calcularDatas(dataInicial, quantidade = 7, diaSemana = DiaDaSemana.SABADO, semanas = [2, 4]) {
    let datas = [];
    let dataAtual = startOfDay(setDay(startOfDay(dataInicial), diaSemana, { weekStartsOn: 0 })); // Setando o primeiro dia da semana e zerando o tempo

    // Garantindo que a primeira data seja após a data inicial
    if (dataAtual < dataInicial) {
        dataAtual = startOfDay(addWeeks(dataAtual, 1));
    }

    while (datas.length < quantidade) {
        // Verifica se a semana atual está dentro das semanas desejadas
        let semanaDoMes = Math.ceil((dataAtual.getDate() - 1) / 7) + 1;

        if (semanas.includes(semanaDoMes)) {
            datas.push(startOfDay(new Date(dataAtual)));
        }

        // Pula para a próxima semana
        dataAtual = startOfDay(addWeeks(dataAtual, 1));
    }

    return datas;
}

function calcularDataFim(dataPrescricao, numeroDeSemanas = 12) {
    const dataPrescricaoDate = new Date(dataPrescricao);
    const dataInicio = nextMonday(dataPrescricaoDate);
    const dataFim = addWeeks(dataInicio, numeroDeSemanas);
    return previousSaturday(dataFim);
}

function calcularNumeroDeSemanas(dataInicio, dataFim) {
    const diferencaEmDias = differenceInDays(new Date(dataFim), new Date(dataInicio));
    const diferencaEmSemanas = Math.floor(diferencaEmDias / 7);
    return diferencaEmSemanas;
}

function isValidDate(dateString) {
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date);
}


const valoresIniciais = {
    id: null,
    quantidadeCromos: 12,
    dataPrescricao: new Date(),
    dataFim: format(calcularDataFim(new Date()), DatePattern.DD_MM_YYYY),
    cromoDiaria: false,
    desobsessao: false,
    evangelho: false,
    umbanda: false,
    vibracional: false,
    psicanalise: false,
    ctd: false,
    terraDeElfos: false,
    tabagismo: false,
    reiki: false,
    cromoterapia: true,
    karuna: false,
    xamanismo: false,
    outrosTratamentos: '',
    preparacaoLado: false,
    preparacaoBrucos: false,
    preparacaoCostas: false,
    preparacaoMaisConfortavel: false,
    preparacaoNaoSeAplica: false,
    cromoterapias: [{ numero: 1, cor: '', local: '' }],
};

const CpEditarTratamentos = ({ tratamento: tratamentoEdicao, assistidoId, onSalvar }) => {

    const { enqueueSnackbar } = useSnackbar();
    const service = new TratamentosService();
    const history = useHistory();

    const [id, setId] = useState(valoresIniciais.id);
    const [quantidadeCromos, setQuantidadeCromos] = useState(valoresIniciais.quantidadeCromos);
    const [dataPrescricao, setDataPrescricao] = useState(valoresIniciais.dataPrescricao);
    const [dataFim, setDataFim] = useState(valoresIniciais.dataFim);
    const [cromoDiaria, setCromoDiaria] = useState(valoresIniciais.cromoDiaria);
    const [desobsessao, setDesobsessao] = useState(valoresIniciais.desobsessao);
    const [evangelho, setEvangelho] = useState(valoresIniciais.evangelho);
    const [umbanda, setUmbanda] = useState(valoresIniciais.umbanda);
    const [vibracional, setVibracional] = useState(valoresIniciais.vibracional);
    const [psicanalise, setPsicanalise] = useState(valoresIniciais.psicanalise);
    const [ctd, setCtd] = useState(valoresIniciais.ctd);
    const [terraDeElfos, setTerraDeElfos] = useState(valoresIniciais.terraDeElfos);
    const [tabagismo, setTabagismo] = useState(valoresIniciais.tabagismo);
    const [reiki, setReiki] = useState(valoresIniciais.reiki);
    const [cromoterapia, setCromoterapia] = useState(valoresIniciais.cromoterapia);
    const [karuna, setKaruna] = useState(valoresIniciais.karuna);
    const [xamanismo, setXamanismo] = useState(valoresIniciais.xamanismo);
    const [outrosTratamentos, setOutrosTratamentos] = useState(valoresIniciais.outrosTratamentos);
    const [preparacaoLado, setPreparacaoLado] = useState(valoresIniciais.preparacaoLado);
    const [preparacaoBrucos, setPreparacaoBrucos] = useState(valoresIniciais.preparacaoBrucos);
    const [preparacaoCostas, setPreparacaoCostas] = useState(valoresIniciais.preparacaoCostas);
    const [preparacaoMaisConfortavel, setPreparacaoMaisConfortavel] = useState(valoresIniciais.preparacaoMaisConfortavel);
    const [preparacaoNaoSeAplica, setPreparacaoNaoSeAplica] = useState(valoresIniciais.preparacaoNaoSeAplica);
    const [cromoterapias, setCromoterapias] = useState(valoresIniciais.cromoterapias);

    //Utilizado para dar foco através dos atalhos
    const [focus, setFocus] = useState('');

    const outrosTratamentosInput = useRef();
    const quantidadeCromosInput = useRef();

    useEffect(() => {
        if (isValidDate(dataPrescricao)) {
            const dataFimPorSemanas = calcularDataFim(dataPrescricao, quantidadeCromos);
            if (desobsessao) {
                const datasDesobsessao = calcularDatas(dataPrescricao);
                const dataFimPorDesobsessao = datasDesobsessao[datasDesobsessao.length - 1];
                if (dataFimPorDesobsessao > dataFimPorSemanas) {
                    setDataFim(format(dataFimPorDesobsessao, DatePattern.DD_MM_YYYY));
                    const semanasTratamentoObsessao = calcularNumeroDeSemanas(dataPrescricao, dataFimPorDesobsessao);
                    enqueueSnackbar(`As semanas de tratamento foram atualizadas de ${quantidadeCromos} para ${semanasTratamentoObsessao} para alinhar com as 7 sessões de desobsessão`, {
                        variant: 'info',
                    });
                    setQuantidadeCromos(semanasTratamentoObsessao);
                } else {
                    setDataFim(format(dataFimPorSemanas, DatePattern.DD_MM_YYYY));
                }
            } else {
                setDataFim(format(dataFimPorSemanas, DatePattern.DD_MM_YYYY));
            }
        }
    }, [dataPrescricao, desobsessao, quantidadeCromos]);

    useEffect(() => {
        if (tratamentoEdicao) {
            setTratamentoFields(tratamentoEdicao);
            setCromoFields(tratamentoEdicao.cromoterapias);
        }
    }, [tratamentoEdicao]);

    useEffect(() => {
        adicionarCromoVaziaSeNecessario();
    }, [cromoterapias]);

    const setTratamentoFields = (tratamentos) => {
        if (!tratamentos) return;
        setId(tratamentos.id);
        setQuantidadeCromos(tratamentos.quantidadeCromos);
        setCromoterapia(tratamentos.cromoterapia);
        setCromoDiaria(tratamentos.cromoDiaria);
        setDesobsessao(tratamentos.desobsessao);
        setEvangelho(tratamentos.evangelho);
        setUmbanda(tratamentos.umbanda);
        setVibracional(tratamentos.vibracional);
        setPsicanalise(tratamentos.psicanalise);
        setCtd(tratamentos.ctd);
        setTerraDeElfos(tratamentos.terraDeElfos);
        setTabagismo(tratamentos.tabagismo);
        setReiki(tratamentos.reiki);
        setKaruna(tratamentos.karuna);
        setXamanismo(tratamentos.xamanismo);
        setPreparacaoLado(tratamentos.preparacaoLado);
        setPreparacaoBrucos(tratamentos.preparacaoBrucos);
        setPreparacaoCostas(tratamentos.preparacaoCostas);
        setPreparacaoMaisConfortavel(tratamentos.preparacaoMaisConfortavel);
        setPreparacaoNaoSeAplica(tratamentos.preparacaoNaoSeAplica);
        setOutrosTratamentos(tratamentos.outrosTratamentos);
        setDataPrescricao(new Date(tratamentos.dataPrescricao));
        setDataFim(new Date(tratamentos.dataFim));
    };

    const setCromoFields = (cromoterapias) => {
        if (!cromoterapias) return;
        setCromoterapias(cromoterapias.length ? cromoterapias : [{ numero: 1, cor: '', local: '' }]);
    }

    const normalizarString = (str) => {
        // Remover espaços duplos e fazer trim
        return str && typeof str === 'string' ? str.replace(/\s{2,}/g, ' ').trim() : str;
    }

    const getTratamentosFromFields = () => {
        return {
            assistidoId: assistidoId,
            id,
            quantidadeCromos,
            cromoterapia,
            cromoDiaria,
            desobsessao,
            evangelho,
            umbanda,
            vibracional,
            psicanalise,
            ctd,
            terraDeElfos,
            tabagismo,
            reiki,
            karuna,
            xamanismo,
            outrosTratamentos: normalizarString(outrosTratamentos),
            preparacaoCostas,
            preparacaoBrucos,
            preparacaoLado,
            preparacaoMaisConfortavel,
            preparacaoNaoSeAplica,
            dataPrescricao: format(dataPrescricao, DatePattern.YYYY_MM_DD),
            dataFim: strDateToIsoDate(dataFim),
        };
    };

    const getCromosFromFields = () => {
        if (cromoterapia || cromoDiaria) {
            return cromoterapias
                .filter(c => normalizarString(c.cor) && normalizarString(c.local))
                .map(c => ({
                    numero: c.numero,
                    cor: normalizarString(c.cor),
                    local: normalizarString(c.local),
                }));
        }
        return [];
    };

    const tratamentoSelecionado = () => {
        const outrosTratamentosPreenchido = normalizarString(outrosTratamentos).length > 0;
        return [outrosTratamentosPreenchido, cromoDiaria, desobsessao, evangelho, umbanda, vibracional, psicanalise, ctd, terraDeElfos, tabagismo, reiki, cromoterapia, karuna, xamanismo].some(Boolean);
    };

    const posicaoSelecionada = () => {
        return [preparacaoCostas, preparacaoBrucos, preparacaoLado, preparacaoMaisConfortavel, preparacaoNaoSeAplica].some(Boolean);
    };

    const dataPrescricaoValida = () => {
        if (!dataPrescricao) {
            return false;
        }
        const date = new Date(dataPrescricao);
        return !isNaN(date.getTime());
    };

    const salvarOnClick = (e) => {
        !!id ? salvar(`/assistidos/${assistidoId}/tratamentos`) : salvar('');
    };

    const salvar = (redirectUrl) => {
        let erroValidacao = false;
        if (!tratamentoSelecionado()) {
            enqueueSnackbar('Informe algum tratamento!', {
                variant: 'error',
            });
            erroValidacao = true;
        }
        if (!posicaoSelecionada()) {
            enqueueSnackbar('Selecione uma posição para a preparação!', {
                variant: 'error',
            });
            erroValidacao = true;
        }
        if (!dataPrescricaoValida()) {
            enqueueSnackbar('Selecione uma data prescrição válida!', {
                variant: 'error',
            });
            erroValidacao = true;
        }
        const cromoterapiasInvalidas = cromoterapias
            .map((cromo, index) => ((cromo.cor && !cromo.local) || (!cromo.cor && cromo.local)) ? index + 1 : null)
            .filter(index => index !== null);
        const numerosCromosInvalidas = cromoterapiasInvalidas.join(', ');
        if (!!cromoterapiasInvalidas.length) {
            enqueueSnackbar(`Insira uma cor e local para as cromoterapias de número ${numerosCromosInvalidas}`, {
                variant: 'error',
            });
            erroValidacao = true;
        }
        if (erroValidacao) {
            return false;
        }

        const tratamento = getTratamentosFromFields();
        const cromoterapiasParaSalvar = getCromosFromFields();
        tratamento['cromoterapias'] = cromoterapiasParaSalvar;
        service.salvar(tratamento)
            .then((res) => {
                enqueueSnackbar('Tratamento atualizado com sucesso!', {
                    variant: 'success',
                });
                if (redirectUrl) {
                    history.push(redirectUrl);
                } else {
                    if (onSalvar && typeof onSalvar === 'function') {
                        onSalvar(res);
                    }
                    setTratamentoFields(valoresIniciais);
                    setCromoFields([]);
                }
            })
            .catch(err => {
                enqueueSnackbar('Ocorreu um erro inesperado ao salvar o tratamento', {
                    variant: 'error',
                });
                console.error("Erro ao salvar o tratamento", err);
            });
    };

    const onPreparacaoNaoSeAplicaChange = (checked) => {
        setPreparacaoNaoSeAplica(checked);
        if (checked) {
            setPreparacaoBrucos(false);
            setPreparacaoCostas(false);
            setPreparacaoLado(false);
            setPreparacaoMaisConfortavel(false);
        }
    };

    const togglePreparacaoNaoSeAplica = () => {
        setPreparacaoNaoSeAplica(!preparacaoNaoSeAplica);
        if (!preparacaoNaoSeAplica) {
            setPreparacaoBrucos(false);
            setPreparacaoCostas(false);
            setPreparacaoLado(false);
            setPreparacaoMaisConfortavel(false);
        }
    };

    const salvarRedirecionarCirurgias = (e) => {
        salvarOnClick(`/assistidos/${assistidoId}/cirurgias`);
    };

    const salvarRedirecionarCromo = (e) => {
        salvarOnClick(`/assistidos/${assistidoId}/cromo`);
    };

    useHotkeys(Atalhos.SALVAR_IR_CIRURGIAS.sequence, salvarRedirecionarCirurgias, { keyup: true });
    useHotkeys(Atalhos.SALVAR_IR_CROMO.sequence, salvarRedirecionarCromo, { keyup: true });
    useHotkeys(Atalhos.FOCO_QUANTIDADE_CROMOS.sequence, () => quantidadeCromosInput.current.focus(), { keyup: true });
    useHotkeys(Atalhos.FOCO_OUTROS_TRATAMENTOS.sequence, () => outrosTratamentosInput.current.focus(), { keyup: true });
    useHotkeys(Atalhos.TOGGLE_CROMO.sequence, () => setCromoterapia(!cromoterapia), { keyup: true });
    useHotkeys(Atalhos.TOGGLE_CROMO_DIARIA.sequence, () => setCromoDiaria(!cromoDiaria), { keyup: true });
    useHotkeys(Atalhos.TOGGLE_DESOBSESSAO.sequence, () => setDesobsessao(!desobsessao), { keyup: true });
    useHotkeys(Atalhos.TOGGLE_EVANGELHO.sequence, () => setEvangelho(!evangelho), { keyup: true });
    useHotkeys(Atalhos.TOGGLE_UMBANDA.sequence, () => setUmbanda(!umbanda), { keyup: true });
    useHotkeys(Atalhos.TOGGLE_VIBRACIONAL.sequence, () => setVibracional(!vibracional), { keyup: true });
    useHotkeys(Atalhos.TOGGLE_PSICANALISE.sequence, () => setPsicanalise(!psicanalise), { keyup: true });
    useHotkeys(Atalhos.TOGGLE_CTD.sequence, () => setCtd(!ctd), { keyup: true });
    useHotkeys(Atalhos.TOGGLE_TERRA_DE_ELFOS.sequence, () => setTerraDeElfos(!terraDeElfos), { keyup: true });
    useHotkeys(Atalhos.TOGGLE_TABAGISMO.sequence, () => setTabagismo(!tabagismo), { keyup: true });
    useHotkeys(Atalhos.TOGGLE_REIKI.sequence, () => setReiki(!reiki), { keyup: true });
    useHotkeys(Atalhos.TOGGLE_KARUNA.sequence, () => setKaruna(!karuna), { keyup: true });
    useHotkeys(Atalhos.TOGGLE_XAMANISMO.sequence, () => setXamanismo(!xamanismo), { keyup: true });
    useHotkeys(Atalhos.FOCO_OUTROS_TRATAMENTOS.sequence, () => setOutrosTratamentos(!outrosTratamentos), { keyup: true });
    useHotkeys(Atalhos.TOGGLE_PREPARACAO_LADO.sequence, () => setPreparacaoLado(!preparacaoLado), { keyup: true });
    useHotkeys(Atalhos.TOGGLE_PREPARACAO_BRUCOS.sequence, () => setPreparacaoBrucos(!preparacaoBrucos), { keyup: true });
    useHotkeys(Atalhos.TOGGLE_PREPARACAO_COSTAS.sequence, () => setPreparacaoCostas(!preparacaoCostas), { keyup: true });
    useHotkeys(Atalhos.TOGGLE_PREPARACAO_MAIS_CONFORTAVEL.sequence, () => setPreparacaoMaisConfortavel(!preparacaoMaisConfortavel), { keyup: true });
    useHotkeys(Atalhos.TOGGLE_PREPARACAO_NAO_SE_APLICA.sequence, () => togglePreparacaoNaoSeAplica(), { keyup: true });

    const renderCheckbox = (label, checked, onChange, value, disabled = false) => (
        <FormControlLabel
            control={
                <Checkbox
                    checked={checked}
                    onChange={(e) => onChange(e.target.checked)}
                    value={value}
                    color="primary"
                    disabled={disabled}
                    style={{ paddingTop: '8px' }}
                />
            }
            label={label}
            labelPlacement="end"
        />
    );

    const renderGridWithCheckbox = (label, checked, onChange, value, disabled) => (
        <Grid item xs={12} sm={6} md={2} lg={2}>{renderCheckbox(label, checked, onChange, value, disabled)}</Grid>
    );

    const completarCor = (valor) => {
        let texto = valor;

        if (texto.includes("/")) {
            let separacoes = texto.split("/");
            texto = separacoes[separacoes.length - 1];
        }

        const corMapping = {
            'AM': 'AMARELO',
            'AZ': 'AZUL',
            'BR': 'BRANCO',
            'DR': 'DOURADO',
            'IN': 'INDIGO',
            'LL': 'LILÁS',
            'LR': 'LARANJA',
            'PR': 'PRATEADO',
            'RS': 'ROSA',
            'VD': 'VERDE',
            'VM': 'VERMELHO',
        };

        const cor = corMapping[texto] || "";

        if (cor === "") {
            return valor;
        }

        if (valor.includes("/")) {
            valor = valor.replace("/" + valor.substr(valor.length - 2), "/");
            valor += cor;
            return valor;
        }

        return cor;
    };

    const handleCromoterapiaChange = (index, field, value) => {
        value = value ? value.toUpperCase() : value;
        if (field === 'cor') {
            value = completarCor(value);
        }
        const newCromoterapias = [...cromoterapias];
        newCromoterapias[index][field] = value;
        setCromoterapias(newCromoterapias);
    };

    const handleRemoverCromoterapia = (index) => {
        const newCromoterapias = cromoterapias.filter((_, i) => i !== index);
        if (newCromoterapias.length === 0) {
            setCromoterapias([{ numero: cromoterapias.length + 1, cor: '', local: '' }]);
        } else {
            setCromoterapias([...newCromoterapias]);
        }
    };

    const handleLimparTodasCromoterapias = () => {
        setCromoterapias([{ numero: 1, cor: '', local: '' }]);
    };

    const adicionarCromoVaziaSeNecessario = () => {
        if (cromoterapias.length < 20 && (cromoterapias[cromoterapias.length - 1].cor || cromoterapias[cromoterapias.length - 1].local)) {
            setCromoterapias([...cromoterapias, { numero: cromoterapias.length + 1, cor: '', local: '' }]);
        }
    };

    const addCromoterapia = (index) => {
        const newCromoterapias = [
            ...cromoterapias.slice(0, index),
            { numero: cromoterapias.length + 1, cor: '', local: '' },
            ...cromoterapias.slice(index).map(cromo => ({
                ...cromo,
                numero: cromo.numero + 1
            })),
        ];
        setCromoterapias(newCromoterapias);
    };

    const removeCurrentCromoterapia = (index) => {
        handleRemoverCromoterapia(index);
    };

    //Foca no proximo campo ou na proxima linha quando atalho de insercao de cromo é utilizado
    useEffect(() => {
        if (focus) {
            const nextElement = document.getElementById(focus);
            if (nextElement) {
                nextElement.focus();
                setFocus('');
            }
        }
    }, [focus, cromoterapias]);


    const handleKeyDown = (e, index) => {
        if (e.ctrlKey && (e.key === '+' || e.key.toLowerCase() === 'm')) {
            e.preventDefault();
            addCromoterapia(index);
        } else if (e.ctrlKey && e.key === '-') {
            e.preventDefault();
            removeCurrentCromoterapia(index);
        } else if (e.key === 'Enter') {
            e.preventDefault();
            const [field, currentIndex] = e.target.id.split('-');
            let nextField, nextIndex;

            if (field === 'cor') {
                nextField = 'local';
                nextIndex = currentIndex;
            } else if (field === 'local') {
                nextField = 'cor';
                nextIndex = parseInt(currentIndex, 10) + 1;
            }
            setFocus(`${nextField}-${nextIndex}`);
        } else {
            const keySequence = e.shiftKey ? `shift+${e.key.toLowerCase()}` : e.key.toLowerCase();

            const matchedAtalho = Object.values(Atalhos).find(atalho => {
                if (Array.isArray(atalho.sequence)) {
                    return atalho.sequence.includes(keySequence);
                }
                return atalho.sequence === keySequence;
            });

            if (matchedAtalho && matchedAtalho.help && matchedAtalho.help.section === HelpSection.cromos) {
                e.preventDefault();
                const [cor, local] = matchedAtalho.value.split('-');
                if (cor) {
                    const focusField = !!local ? 'cor' : 'local';
                    const focusIndex = !!local ? index + 1 : index;
                    setFocus(`${focusField}-${focusIndex}`);
                    const newCromoterapias = [...cromoterapias];
                    newCromoterapias[index]['cor'] = cor;
                    newCromoterapias[index]['local'] = local;
                    setCromoterapias(newCromoterapias);
                }
            }
        }

    };

    const filterOptions = (options, { inputValue }) => {
        return options.filter(option =>
            option.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} lg={2}>
                    <TextField
                        id="quantidadeCromos"
                        name="quantidadeCromos"
                        label="Duração em Semanas"
                        type="number"
                        autoFocus
                        fullWidth
                        value={quantidadeCromos}
                        onChange={(e) => setQuantidadeCromos(e.target.value)}
                        inputRef={quantidadeCromosInput}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} lg={2}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                        <KeyboardDatePicker
                            id="dataPrescricao"
                            label="Data Prescrição"
                            name="dataPrescricao"
                            disableToolbar
                            value={dataPrescricao}
                            onChange={(e) => setDataPrescricao(e)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            format="dd/MM/yyyy"
                            invalidDateMessage="Formato de data inválido"
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={12} lg={2}>
                    <TextField
                        id="dataFim"
                        name="dataFim"
                        label="Data Fim"
                        fullWidth
                        value={dataFim}
                        disabled
                        InputProps={{
                            disableUnderline: true,
                            style: {
                                color: '#0000009e',
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                            style: {
                                color: '#626262',
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} lg={12}></Grid>
                <Grid item xs={12} sm={12} lg={12}>
                    <Typography variant='subtitle2'>Tratamentos disponíveis</Typography>
                </Grid>
                {renderGridWithCheckbox("Cromoterapia", cromoterapia, setCromoterapia, "cromoterapia")}
                {renderGridWithCheckbox("Cromo Diária", cromoDiaria, setCromoDiaria, "cromoDiaria")}
                {renderGridWithCheckbox("Reiki", reiki, setReiki, "reiki")}
                {renderGridWithCheckbox("Desobsessão", desobsessao, setDesobsessao, "desobsessao")}
                {renderGridWithCheckbox("Evangelho", evangelho, setEvangelho, "evangelho")}
                {renderGridWithCheckbox("Umbanda", umbanda, setUmbanda, "umbanda")}
                {renderGridWithCheckbox("Vibracional", vibracional, setVibracional, "vibracional")}
                {renderGridWithCheckbox("Psicanálise", psicanalise, setPsicanalise, "psicanalise")}
                {renderGridWithCheckbox("CTD", ctd, setCtd, "ctd")}
                {renderGridWithCheckbox("Terra de Elfos", terraDeElfos, setTerraDeElfos, "terraDeElfos")}
                {renderGridWithCheckbox("Tabagismo", tabagismo, setTabagismo, "tabagismo")}
                {renderGridWithCheckbox("Karuna", karuna, setKaruna, "karuna")}
                {renderGridWithCheckbox("Xamanismo", xamanismo, setXamanismo, "xamanismo")}
                <Grid item xs={12} sm={12} lg={12}>
                    <TextField
                        id="outrosTratamentos"
                        name="outrosTratamentos"
                        label="Outros Tratamentos"
                        fullWidth
                        value={outrosTratamentos}
                        onChange={(e) => setOutrosTratamentos(e.target.value)}
                        inputRef={outrosTratamentosInput}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            autoComplete: 'off',
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} lg={6}>
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography variant='subtitle2'>Preparação</Typography>
                </Grid>
                {renderGridWithCheckbox("Costas", preparacaoCostas, setPreparacaoCostas, "preparacaoCostas", preparacaoNaoSeAplica)}
                {renderGridWithCheckbox("Bruços", preparacaoBrucos, setPreparacaoBrucos, "preparacaoBrucos", preparacaoNaoSeAplica)}
                {renderGridWithCheckbox("Lado", preparacaoLado, setPreparacaoLado, "preparacaoLado", preparacaoNaoSeAplica)}
                {renderGridWithCheckbox("Mais confortável", preparacaoMaisConfortavel, setPreparacaoMaisConfortavel, "preparacaoMaisConfortavel", preparacaoNaoSeAplica)}
                {renderGridWithCheckbox("Não se aplica", preparacaoNaoSeAplica, onPreparacaoNaoSeAplicaChange, "preparacaoNaoSeAplica")}
                <Grid item xs={12} sm={12} lg={12}></Grid>
                <Grid item xs={12} sm={12} lg={12}>
                    {(!!cromoterapia || !!cromoDiaria) && (
                        <>
                            <Typography variant='subtitle2' paragraph>Cromoterapias</Typography>
                            <Paper elevation={3} style={{ padding: '10px 20px' }}>
                                <Grid container spacing={1}>
                                    {cromoterapias.slice(0, 20).map((cromoterapia, index) => (
                                        <React.Fragment key={index}>
                                            <Grid item xs={12} sm={12} lg={1}>
                                                <TextField
                                                    label="Número"
                                                    value={index + 1}
                                                    disabled
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        style: {
                                                            color: '#0000009e',
                                                        },
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                        style: {
                                                            color: '#626262',
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={5} sm={5} lg={4}>
                                                <TextField
                                                    id={`cor-${index}`}
                                                    name={`cor-${index}`}
                                                    fullWidth
                                                    label="Cor"
                                                    value={cromoterapia.cor}
                                                    onChange={(e) => handleCromoterapiaChange(index, 'cor', e.target.value)}
                                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={{
                                                        autoComplete: 'off',
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={5} sm={5} lg={5}>
                                                <Autocomplete
                                                    id={`local-${index}`}
                                                    options={locais}
                                                    value={cromoterapia.local}
                                                    onChange={(e, newValue) => handleCromoterapiaChange(index, 'local', newValue)}
                                                    filterOptions={filterOptions}
                                                    freeSolo
                                                    renderInput={(params) => (
                                                        <TextField
                                                            onChange={(e) => handleCromoterapiaChange(index, 'local', e.target.value)}
                                                            onKeyDown={(e) => handleKeyDown(e, index)}
                                                            {...params}
                                                            label="Local"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            fullWidth
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={2} sm={2} lg={2} style={{ display: 'flex', alignItems: 'center' }}>
                                                <Tooltip title="Deletar linha">
                                                    <IconButton onClick={() => handleRemoverCromoterapia(index)} style={{ marginTop: '10px' }} tabIndex={-1}>
                                                        <DeleteIcon fontSize="inherit" color="error" />
                                                    </IconButton>
                                                </Tooltip>
                                                {index === 0 && cromoterapias.length > 1 &&
                                                    <Tooltip title="Deletar todas as linhas">
                                                        <IconButton onClick={() => handleLimparTodasCromoterapias(index)} style={{ marginTop: '10px' }} tabIndex={-1}>
                                                            <DeleteAllIcon fontSize="inherit" color="error" />
                                                        </IconButton>
                                                    </Tooltip>
                                                }

                                            </Grid>
                                        </React.Fragment>
                                    ))}
                                </Grid>
                            </Paper>
                        </>
                    )}
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={salvarOnClick}
                        startIcon={!!id ? <SaveIcon /> : <AddBoxIcon />}
                    >
                        {!!id ? 'Salvar' : 'Adicionar'}
                    </Button>
                    {!!id && <>&nbsp; <Button component={Link} to={`/assistidos/${assistidoId}/tratamentos`} className="button" color="secondary">Cancelar</Button></>}
                </Grid>
            </Grid>
        </>
    );
};

export default withSnackbar(CpEditarTratamentos);
