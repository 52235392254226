import React from 'react';
import Atalhos from '../../pages/pg-sintomas/atalhos-teclado';
import CpAjuda from './cp-ajuda';

const CpAjudaCirurgias = ({ onClose }) => {
    return (
        <CpAjuda
            titulo={"Ajuda"}
            subTitulo={"Página para adicionar sintomas para o assistido selecionado"}
            atalhos={Atalhos}
            onClose={onClose}
        />
    );
};

export default CpAjudaCirurgias;