import { IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import TipoAssistidoHumanoIcon from '@material-ui/icons/EmojiPeople';
import TipoAssistidoPlantaIcon from '@material-ui/icons/LocalFlorist';
import TipoAssistidoAnimalIcon from '@material-ui/icons/Pets';
import { useSnackbar } from 'notistack';
import React, { useState } from "react";
import { Local, TipoAssistido } from '../../components/assistidos/enums';
import CpDeletarDialog from '../../components/cp-deletar-dialog';
import ConfiguracoesCirurgiaService from '../../services/configuracao_cirurgia';
import { isoTimeToStrTime } from "../../services/date-utils";

const useStyles = makeStyles((theme) => ({
	verticalAlignCell: {
		display: 'flex',
		alignItems: 'center',
	},
}));

const diasDaSemana = [
	{ value: 0, label: 'Domingo' },
	{ value: 1, label: 'Segunda-feira' },
	{ value: 2, label: 'Terça-feira' },
	{ value: 3, label: 'Quarta-feira' },
	{ value: 4, label: 'Quinta-feira' },
	{ value: 5, label: 'Sexta-feira' },
	{ value: 6, label: 'Sábado' },
];

function CpListaConfiguracoesCirurgia({ configuracoes, atualizarConfiguracoes }) {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	const service = new ConfiguracoesCirurgiaService();

	const [toDelete, setToDelete] = useState('');

	const handleDelete = (configuracao) => {
		setToDelete(configuracao);
	};

	const onConfirmDeletar = (configuracao) => {
		service.deletarConfiguracao(configuracao.id)
			.then((res) => {
				enqueueSnackbar('Configuração deletada com sucesso!', {
					variant: 'success',
				});
				atualizarConfiguracoes(res);
			})
			.catch((err) => {
				enqueueSnackbar('Erro ao deletar a configuração!', {
					variant: 'error',
				});
			});
	};

	const fecharModalDeletar = () => {
		setToDelete('');
	};

	const iconTipoAssistido = (tipo) => {
		const style = { fontSize: '20px', verticalAlign: 'middle', margin: '2px 8px 0 0' };
		if (tipo === "A") {
			return <TipoAssistidoAnimalIcon style={style} />;
		} else if (tipo === "P") {
			return <TipoAssistidoPlantaIcon style={style} />;
		}
		return <TipoAssistidoHumanoIcon style={style} />;
	};

	if (!configuracoes?.length) {
		return <Typography variant='subtitle2'>Nenhuma configuração encontrada. Adicione novas fconfigurações para vê-las aqui</Typography>;
	}

	return (
		<>
			<CpDeletarDialog
				toDelete={toDelete}
				onConfirm={onConfirmDeletar}
				onClose={fecharModalDeletar}
				title={"Remover Configuração"}
			/>
			<Typography variant='subtitle2' paragraph>Configurações Cadastradas</Typography>
			<Paper elevation={3}>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell>Tipo Assistido</TableCell>
							<TableCell>Local</TableCell>
							<TableCell>Dia da Semana</TableCell>
							<TableCell>Hora Início</TableCell>
							<TableCell>Hora Fim</TableCell>
							<TableCell>Cirurgias por Intervalo</TableCell>
							<TableCell>Ações</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{configuracoes.map((configuracao) => (
							<TableRow key={configuracao.id}>
								<TableCell>
									<div className={classes.verticalAlignCell}>
										{iconTipoAssistido(configuracao.tipoAssistido)} &nbsp;
										{TipoAssistido.porDbVal(configuracao.tipoAssistido).desc}
									</div>
								</TableCell>
								<TableCell>{Local.porDbVal(configuracao.local).desc}</TableCell>
								<TableCell>{diasDaSemana.find(dia => dia.value === configuracao.diaDaSemana)?.label}</TableCell>
								<TableCell>{isoTimeToStrTime(configuracao.horaInicio)}</TableCell>
								<TableCell>{isoTimeToStrTime(configuracao.horaFim)}</TableCell>
								<TableCell>{configuracao.quantidadeIntervalo}</TableCell>
								<TableCell>
									<Tooltip title="Editar configuração" disableFocusListener>
										<IconButton size="medium" href={`/configcirurgias/${configuracao.id}`}>
											<EditIcon fontSize="inherit" color="primary" />
										</IconButton>
									</Tooltip>
									<Tooltip title="Deletar configuração" disableFocusListener>
										<IconButton
											aria-label="remover configuração"
											size="medium"
											onClick={() => handleDelete(configuracao)}
										>
											<DeleteIcon fontSize="inherit" color="error" />
										</IconButton>
									</Tooltip>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Paper>
		</>
	);
}

export default CpListaConfiguracoesCirurgia;