import { IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import { useSnackbar } from 'notistack';
import React, { useState } from "react";
import CpDeletarDialog from '../../components/cp-deletar-dialog';
import { isoToStrDate } from "../../services/date-utils";
import FeriadosService from '../../services/feriados';

function CpListaFeriados({ lista, onAtualizarLista }) {
	const { enqueueSnackbar } = useSnackbar();

	const service = new FeriadosService();

	const [toDelete, setToDelete] = useState('');

	const handleDelete = (feriado) => {
		setToDelete(feriado);
	};

	const onConfirmDeletar = (feriado) => {
		service.deletar(feriado.id)
			.then((res) => {
				enqueueSnackbar('Feriado deletado com sucesso!', {
					variant: 'success',
				});
				onAtualizarLista(res);
			})
			.catch((err) => {
				enqueueSnackbar('Erro ao deletar o feriado!', {
					variant: 'error',
				});
			});
	};

	const fecharModalDeletar = () => {
		setToDelete('');
	};

	if (!lista?.length) {
		return <Typography variant='subtitle2' >Nenhum feriado encontrado. Adicione novos feriados para vê-los aqui</Typography>;
	}

	return (
		<>
			<CpDeletarDialog
				toDelete={toDelete}
				onConfirm={onConfirmDeletar}
				onClose={fecharModalDeletar}
				title={"Remover Feriado"}
			/>
			<Typography variant='subtitle2' paragraph>Feriados Cadastrados</Typography>
			<Paper elevation={3}>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell>Nome</TableCell>
							<TableCell>Data do Feriado</TableCell>
							<TableCell>Data de Substituição</TableCell>
							<TableCell>Ações</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{lista.map((feriado) => (
							<TableRow key={feriado.id}>
								<TableCell>{feriado.nome}</TableCell>
								<TableCell>{isoToStrDate(feriado.dataFeriado)}</TableCell>
								<TableCell>{isoToStrDate(feriado.dataSubstituicao)}</TableCell>
								<TableCell>
									<Tooltip title="Editar feriado" disableFocusListener>
										<IconButton size="medium" href={`/feriados/${feriado.id}`}>
											<EditIcon fontSize="inherit" color="primary" />
										</IconButton>
									</Tooltip>
									<Tooltip title="Deletar feriado" disableFocusListener>
										<IconButton
											aria-label="remover feriado"
											size="medium"
											onClick={() => handleDelete(feriado)}
										>
											<DeleteIcon fontSize="inherit" color="error" />
										</IconButton>
									</Tooltip>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Paper>
		</>
	);
}

export default CpListaFeriados;