import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from "react";
import CpListaCirurgias from './cp-lista-cirurgias';


import { Paper } from '@material-ui/core';
import CpEditarCirurgia from "./cp-editar-cirurgia";

const decorateConflitoCirurgia = (cirurgias) => {
    if (cirurgias) {
        //Logica para destacar as linhas de cirurgia marcadas para o mesmo dia/horario
        const quantidadeDeCirurgiasPorDataHora = {};
        cirurgias.forEach(cirurgia => {
            quantidadeDeCirurgiasPorDataHora[cirurgia.dataAgendada] = (quantidadeDeCirurgiasPorDataHora[cirurgia.dataAgendada] || 0) + 1;
        });
        cirurgias.forEach(cirurgia => {
            const cirurgiasAgendadasNaMesmaDataHora = quantidadeDeCirurgiasPorDataHora[cirurgia.dataAgendada];
            cirurgia['conflitante'] = cirurgiasAgendadasNaMesmaDataHora > 1;
        });
    }
}

function CpCirurgias(props) {
    const { enqueueSnackbar } = useSnackbar();

    if (props.assistido.cirurgias) {
        decorateConflitoCirurgia(props.assistido?.cirurgias);
    }

    const [cirurgias, setCirurgias] = useState(props.assistido.cirurgias);

    const atualizarCirurgias = (novaListaCirurgias) => {
        if (novaListaCirurgias) {
            decorateConflitoCirurgia(novaListaCirurgias);
            setCirurgias([
                ...novaListaCirurgias
            ]);
        } else {
            setCirurgias([]);
        }
    }

    useEffect(() => {
        const existeConflito = cirurgias && cirurgias.some(cirurgia => cirurgia.conflitante)
        if (existeConflito) {
            enqueueSnackbar('Existem uma ou mais cirurgias agendadas para a mesma data!', {
                variant: 'warning',
            });
        }
    }, [cirurgias]);

    return (
        <>
            <Paper className="app-form">
                <CpEditarCirurgia onAdicionarCirurgia={atualizarCirurgias} assistido={props.assistido} cirurgias={cirurgias}/>
            </Paper>
            <Paper className="app-form" style={{ margin: '5px 0' }}>
                <CpListaCirurgias
                    cirurgias={cirurgias}
                    onDeletarCirurgia={atualizarCirurgias}
                />
            </Paper>
        </>
    );
}

export default CpCirurgias;

