// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print {
    @page {
      size: landscape;
      margin: 0;
    }
  
    @page :first {
      size: landscape;
    }
  
    body {
      margin: 0;
      padding: 0;
    }
  
    body * {
      visibility: hidden;
    }
  
    .printable-report,
    .printable-report * {
      visibility: visible !important;
    }
  
    .printable-report {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      padding: 10mm;
      box-sizing: border-box;
    }
  
    .page {
      page-break-after: always;
    }
  
    /* Estilos específicos para o relatório vibracional */
    .printable-vibracional {
      size: portrait;
    }
  
    /* Esconde botões e elementos desnecessários na impressão */
    button, select, .MuiFormControl-root, .screen-only {
      display: none !important;
    }
  
    /* Remove sombras e bordas dos Papers */
    .MuiPaper-root {
      box-shadow: none !important;
      border: none !important;
    }
  }
  
  /* Estilos para visualização na tela */
  @media screen {
    .printable-report,
    .printable-vibracional {
      display: none;
    }
    
    .print-buttons {
      margin-top: 16px;
    }
  
    .print-button {
      margin-right: 8px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/report/relatorio-presencial.css"],"names":[],"mappings":"AAAA;IACI;MACE,eAAe;MACf,SAAS;IACX;;IAEA;MACE,eAAe;IACjB;;IAEA;MACE,SAAS;MACT,UAAU;IACZ;;IAEA;MACE,kBAAkB;IACpB;;IAEA;;MAEE,8BAA8B;IAChC;;IAEA;MACE,kBAAkB;MAClB,OAAO;MACP,MAAM;MACN,WAAW;MACX,aAAa;MACb,sBAAsB;IACxB;;IAEA;MACE,wBAAwB;IAC1B;;IAEA,qDAAqD;IACrD;MACE,cAAc;IAChB;;IAEA,2DAA2D;IAC3D;MACE,wBAAwB;IAC1B;;IAEA,uCAAuC;IACvC;MACE,2BAA2B;MAC3B,uBAAuB;IACzB;EACF;;EAEA,sCAAsC;EACtC;IACE;;MAEE,aAAa;IACf;;IAEA;MACE,gBAAgB;IAClB;;IAEA;MACE,iBAAiB;IACnB;EACF","sourcesContent":["@media print {\n    @page {\n      size: landscape;\n      margin: 0;\n    }\n  \n    @page :first {\n      size: landscape;\n    }\n  \n    body {\n      margin: 0;\n      padding: 0;\n    }\n  \n    body * {\n      visibility: hidden;\n    }\n  \n    .printable-report,\n    .printable-report * {\n      visibility: visible !important;\n    }\n  \n    .printable-report {\n      position: absolute;\n      left: 0;\n      top: 0;\n      width: 100%;\n      padding: 10mm;\n      box-sizing: border-box;\n    }\n  \n    .page {\n      page-break-after: always;\n    }\n  \n    /* Estilos específicos para o relatório vibracional */\n    .printable-vibracional {\n      size: portrait;\n    }\n  \n    /* Esconde botões e elementos desnecessários na impressão */\n    button, select, .MuiFormControl-root, .screen-only {\n      display: none !important;\n    }\n  \n    /* Remove sombras e bordas dos Papers */\n    .MuiPaper-root {\n      box-shadow: none !important;\n      border: none !important;\n    }\n  }\n  \n  /* Estilos para visualização na tela */\n  @media screen {\n    .printable-report,\n    .printable-vibracional {\n      display: none;\n    }\n    \n    .print-buttons {\n      margin-top: 16px;\n    }\n  \n    .print-button {\n      margin-right: 8px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
