
import { withSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import AssistidoObrigatorio from '../../components/assistido-obrigatorio';
import { Acao } from '../../components/assistidos/cp-acoes-assistido';
import CpBarraAcoes from '../../components/assistidos/cp-barra-acoes';
import CpResumoAssistido from '../../components/assistidos/cp-resumo-assistido';
import CpAjudaCirurgias from '../../components/cp-ajuda/cp-ajuda-cirurgias';
import CpRelatorioEmail from '../../components/report/cp-relatorio-email';
import '../../components/report/estilo-relatorio.css';
import AssistidoService from '../../services/assistidos';

const PgRelatorioPresencial = ({ match, enqueueSnackbar }) => {
    const [assistido, setAssistido] = useState(null);
    const [id, setId] = useState(match.params.id);
    const [exibindoAjuda, setExibindoAjuda] = useState(false);

    const service = new AssistidoService();

    const carregarAssistido = (id) => {
        service.procurarAssistidoPorId(id)
            .then(res => {
                console.log("Assistido encontrado", res);
                setAssistido(res);
            }).catch(err => {
                enqueueSnackbar(`Ocorreu um problema ao obter o assistido número de controle ${id}`, {
                    variant: 'error',
                });
                console.log("Erro ao carregar assistido", err);
            });
    }

    const mostrarAjuda = () => {
        setExibindoAjuda(!exibindoAjuda);
    }

    const ajudaOnClose = () => {
        setExibindoAjuda(!exibindoAjuda);
    }

    useEffect(() => {
        if (id) {
            carregarAssistido(id);
        } else {
            enqueueSnackbar('Escolha um Assistido!', {
                variant: 'info',
            });
        }
    }, [id]);

    if (!assistido) {
        return (<div></div>)
    }

    return (
        <AssistidoObrigatorio idAssistido={id} assistido={assistido}>
            <main>
                {exibindoAjuda && <CpAjudaCirurgias onClose={ajudaOnClose} />}

                <CpResumoAssistido assistido={assistido} />

                <CpBarraAcoes assistido={assistido} destacarAcao={Acao.RelatorioEmail} />

                <CpRelatorioEmail assistido={assistido} />
            </main>
        </AssistidoObrigatorio>
    );
}

export default withSnackbar(PgRelatorioPresencial);
