import Grid from '@material-ui/core/Grid';
import React from 'react';
import { renderInfo, renderText, renderTitle } from './report-utils';

export default function CpRelatorioCromo({ assistido, tratamento }) {

    const renderCromos = () => {
        return (
            <Grid item xs={12} lg={12}>
                <br />
                <table>
                    <thead>
                        <tr>
                            <th align="left">Nº</th>
                            <th align="left">Cor</th>
                            <th align="left">Local</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tratamento.cromoterapias.map(cromo => (
                            <tr key={cromo.id} >
                                <td>{cromo.numero}</td>
                                <td>{cromo.cor}&nbsp;&nbsp;</td>
                                <td>{cromo.local}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Grid>
        );
    };

    const renderCromoDiaria = () => {
        if (!tratamento.cromoDiaria) {
            return <></>;
        }
        const titulo = 'Como fazer a cromoterapia diária?';
        const texto = (
            <>
                Escolha um período de 15 minutos entre as 20:00h e 22:00h para realizar a cromoterapia diária.
                Todas as segundas, quartas, quintas e sextas-feiras, em sua casa.
                Para isso, coloque o papel de cromoterapia que você recebeu ao lado da pessoa no horário escolhido, ore 3 Pai Nossos e chame a equipe do Dr. Espanhol.
                Durante esses 15 minutos, eles estarão fazendo a cromoterapia na pessoa. O horário escolhido deve ser sempre o mesmo todos os dias. <strong>Nas terças-feiras, a cromoterapia deverá ser feita na Casa do Consolador</strong>.
            </>
        );
        return renderInfo(titulo, texto);
    }

    const renderExtra = () => {
        const texto = (
            <>
                Siga exatamente o número de sessões indicadas acima.<br />
                O silêncio é uma prece. A prece faz parte do seu tratamento.<br />
                O tratamento espiritual não exclui ou substitui os tratamentos médicos.
            </>
        );
        return renderText(texto);
    };

    if (!tratamento?.cromoterapias.length) {
        return (
            <></>
        );
    }

    return (
        <Grid container>
            {renderTitle("CASA DO CONSOLADOR - CONTROLE DE CROMOTERAPIA")}
            <Grid item xs={12} lg={12}>
                <strong>Controle:</strong> {assistido.id} <br />
                <strong>Nome:</strong> {assistido.nome} <br />
                <strong>Duração do tratamento:</strong> {tratamento.quantidadeCromos} semanas
            </Grid>
            {renderCromos()}
            {renderCromoDiaria()}
            {renderExtra()}
        </Grid>
    );
}
