import {
    Paper,
    Typography
} from '@material-ui/core';
import React from 'react';
import CpFeriados from './cp-feriados';

const PgFeriados = () => {

    return (
        <>
            <Paper className="acoes" style={{ padding: '10px 20px', margin: '10px 0', backgroundColor: '', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h4'>Feriados</Typography>
            </Paper>
            <CpFeriados />
        </>
    );
};

export default PgFeriados;