import MuiPhoneNumber from 'material-ui-phone-number';
import React from 'react';
import { translatedCountries } from './translatedCountries';

const PhoneNumber = (props) => {
    return (
        <MuiPhoneNumber
            id={props.id}
            label={props.label}
            fullWidth={props.fullWidth}
            autoComplete={props.autoComplete}
            defaultCountry={props.defaultCountry}
            value={props.value}
            inputProps={props.inputProps}
            onBlur={props.onBlur}
            onChange={props.onChange}
            InputProps={props.InputProps}
            localization={translatedCountries}
        />
    );
};

export default PhoneNumber;