import 'date-fns';
import ptLocale from 'date-fns/locale/pt-BR'
import { format, parseISO } from 'date-fns';
import { Redirect } from "react-router-dom";
import React from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import SaveIcon from '@material-ui/icons/Save';
import { HotKeys, configure } from 'react-hotkeys';
import MuiPhoneNumber from "material-ui-phone-number";
import { withSnackbar } from 'notistack';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import AjudaNovoAssistido from '../../components/cp-ajuda/cp-ajuda-novo-assistido';
import CepService from '../../services/cep';
import CadastrosInternetService from '../../services/cadastros_internet';
import { keyMap } from './atalhos-teclado-editar';

configure({
    ignoreTags: []
})

class PgEditarCadastroInternet extends React.Component {
    constructor(props) {
        super(props);

        this.service = new CadastrosInternetService();
        this.cepService = new CepService();

        this.state = {
            id: this.props.match.params.id,
            nome: '',
            sexo: '',
            endereco: '',
            cep: '',
            dataNascimento: new Date(),
            email: '',
            origem: 'P',
            cidade: 'SAO PAULO',
            estado: 'SP',
            animal: false,
            nomeProtepor: '',
            mostrarMensagemErro: false,
            mostrarAjuda: false,
            idade: 0,
            local: false,
            sintoma: "",
            voltarParaListaDeCadastros: false,
            irParaPerfil: false
        }
    }

    limparEstado = () => {
        return new Promise((resolve, reject) => {
            this.setState({
                nome: '',
                telefone: '',
                sexo: '',
                endereco: '',
                cep: '',
                dataNascimento: new Date(),
                email: '',
                origem: 'P',
                cidade: 'SAO PAULO',
                estado: 'SP',
                animal: false,
                nomeProtepor: '',
                mostrarMensagemErro: false,
                mostrarAjuda: false,
                idade: 0,
                local: false,
                sintoma: "",
                voltarParaListaDeCadastros: false
            });
            resolve(true);
        });
    }

    carregarCadastroInternet = (id) => {
        this.service.procurarCadastroPorId(this.state.id)
            .then(res => {
                console.log(res.dataNascimento);
                let telefone = res.telefone;

                if (telefone !== undefined && telefone !== null && telefone.length <= 9 && telefone.length > 4) {
                    telefone = "11" + telefone;
                }

                this.setState({
                    nome: res.nome,
                    sexo: res.sexo,
                    telefone: telefone,
                    endereco: res.endereco,
                    cep: res.cep,
                    cidade: res.cidade,
                    estado: res.estado,
                    email: res.email,
                    origem: "I",
                    animal: res.tipo === "A",
                    dataNascimento: res.dataNascimento,
                    sintoma: res.sintomas,
                });
            })
            .catch(err => {
                this.props.enqueueSnackbar(`Ocorreu um problema ao obter o cadastro de internet com id ${this.state.id}`, {
                    variant: 'error',
                });
                console.log(err);
            });
    }

    salvarEVoltarParaLista = (e) => {
        this.salvar(e)
            .then(res => {
                this.voltarParaListaDeCadastro();
            })
            .catch(err => {
                console.log(err);
            });
    }

    salvarEIrParaPerfil = (e) => {
        this.salvar(e)
            .then(res => {
                this.setState({
                    irParaPerfil: true
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    salvar = (e) => {
        return new Promise((resolve, reject) => {
            if (this.validarDadosDoAssistido() === true) {
                const assistido = this.montarAssistidoParaSalvar();

                console.log("Salvando assistido", assistido)
    
                this.service.editarEAprovarCadastro(this.state.id, assistido)
                .then(res => {
                    this.setState({
                        id: res.id
                    });
                    this.props.enqueueSnackbar('Cadastro de internet salvo com sucesso', {
                        variant: 'success',
                    });
                    resolve(true);
                })
                .catch(err => {
                    this.props.enqueueSnackbar('Não foi possível salvar o cadastro de internet.', {
                        variant: 'error',
                    });
                    reject(err);
                });
            }
        });
    }

    voltarParaListaDeCadastro = () => {
        this.setState({
            voltarParaListaDeCadastros: true
        });
    }

    renderVoltarParaListaDeCadastros = () => {
        if (this.state.voltarParaListaDeCadastros === true) {
            return <Redirect to={"/cadastros-internet"} />
        }
    }

    renderIrParaPerfil = () => {
        if (this.state.irParaPerfil === true) {
            return <Redirect to={`/assistidos/${this.state.id}`} />
        }
    }

    validarDadosDoAssistido = () => {
        if (this.state.nome === undefined || this.state.nome === '') {
            this.props.enqueueSnackbar('O nome do assistido é obrigatório', {
                variant: 'error',
            });
            return false;
        }

        if (this.state.sexo !== 'F' && this.state.sexo !== 'M') {
            this.props.enqueueSnackbar('O sexo do assistido é obrigatório', {
                variant: 'error',
            });
            return false;
        }

        if (this.state.endereco === undefined || this.state.endereco === '') {
            this.props.enqueueSnackbar('O endereço do assistido é obrigatório', {
                variant: 'error',
            });
            return false;
        }

        return true;
    }

    montarAssistidoParaSalvar = () => {
        let tipo = 'H';

        if (this.state.animal !== undefined && this.state.animal === true) {
            tipo = 'A'
        }

        let dataNascimentoIso = null;

        try {
            dataNascimentoIso = format(this.state.dataNascimento, "yyyy-MM-dd");
        }
        catch (er) {
            dataNascimentoIso = this.state.dataNascimento;
        }

        return {
            id: this.state.id,
            nome: this.state.nome,
            nomeProtetor: this.state.nomeProtepor,
            email: this.state.email,
            sexo: this.state.sexo,
            telefone: this.state.telefone,
            endereco: this.state.endereco,
            cidade: this.state.cidade,
            estado: this.state.estado,
            cep: this.state.cep,
            tipo: tipo,
            dataNascimento: dataNascimentoIso,
            sintomas: this.state.sintoma
        };
    }

    componentDidMount() {
        if (this.state.id !== undefined) {
            this.carregarCadastroInternet(this.state.id);
        } else {
           
        }
    }

    onAnimalChange = (e) => {
        this.setState({
            animal: !this.state.animal
        });
    }

    onLocalChange = (e) => {
        this.setState({
            local: !this.state.local
        });
    }

    onCepChange = (e) => {
        let cep = e.target.value;

        if (cep.length >= 8) {
            cep = cep.replace("-", "");
            cep = cep.replace(" ", "");

            this.cepService.procurarCep(cep)
                .then(res => {
                    console.log(res);
                    if (res.erro !== undefined && res.erro === true) {
                        this.props.enqueueSnackbar('Não foi possível obter o endereço do cep informado', {
                            variant: 'error',
                        });
                        this.setState({
                            cidade: '',
                            estado: '',
                            endereco: '',
                            cep: cep
                        });
                    } else {
                        const logradouro = res.logradouro;
                        const uf = res.uf;
                        const cidade = res.localidade;

                        this.setState({
                            cidade: cidade,
                            estado: uf,
                            endereco: logradouro,
                            cep: cep
                        });
                    }
                })
                .catch(err => {
                    this.props.enqueueSnackbar('Não foi possível obter o endereço do cep informado', {
                        variant: 'error',
                    });
                })
        }
        this.setState({
            cep: e.target.value
        });
    
    }

    onCidadeChange = (e) => {
        this.setState({
            cidade: e.target.value
        });
    }

    onEmailChange = (e) => {
        this.setState({
            email: e.target.value
        });
    }

    onEnderecoChange = (e) => {
        this.setState({
            endereco: e.target.value
        });
    }

    onEstadoChange = (e) => {
        this.setState({
            estado: e.target.value
        });
    }

    onNascimentoChange = (e) => {
        this.setState({
            nascimento: e
        });
    }

    onNomeChange = (e) => {
        this.setState({
            nome: e.target.value
        });
    }

    onNomeProtetorChange = (e) => {
        this.setState({
            nomeProtepor: e.target.value
        });
    }

    onSintomaChange = (e) => {
        this.setState({
            sintoma: e.target.value
        });
    }

    onSexoChange = (e) => {
        this.setState({
            sexo: e.target.value
        });
    }

    onTelefoneChange = (e) => {
        this.setState({
            telefone: e
        })
    }

    onAjudaFocus = (e) => {
        e.preventDefault();
        this.mostrarAjuda();
    }

    onMostrarMensagemErroClose = (e) => {
        this.setState({
            mostrarMensagemErro: false
        });
    }

    mostrarMensagemErro = () => {
        this.setState({
            mostrarMensagemErro: true
        });
    }

    mostrarAjuda = () => {
        this.setState({
            mostrarAjuda: !this.state.mostrarAjuda
        });
    }

    render() {
        const handlers = {
            AJUDA: this.onAjudaFocus,
        };

        return (
            <HotKeys
                keyMap={keyMap}
                handlers={handlers}
                isolate={true}
            >
                <main>
                    {this.renderVoltarParaListaDeCadastros()}
                    {this.renderIrParaPerfil()}
                    {this.state.mostrarAjuda && <AjudaNovoAssistido />}
                    <Paper className="acoes">
                        <Typography variant="h4" component="h5">
                            Editar Cadastro
                        </Typography>
                    </Paper>
                    <Paper className="acoes">
                        <Tooltip title="Aprova o cadastro de internet e volta para a tela de listar os cadastros" disableFocusListener >
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<SaveIcon />}
                                onClick={this.salvarEVoltarParaLista}
                            >
                                Aprovar & Voltar para lista
                            </Button>
                        </Tooltip>
                        &nbsp;
                        <Tooltip title="Aprova o cadastro de internet e vai para perfil de assistido" disableFocusListener >
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<PlaylistAddCheckIcon />}
                                onClick={this.salvarEIrParaPerfil}
                            >
                                Aprovar & Ir Para Perfil
                            </Button>
                        </Tooltip>
                        &nbsp;
                        <Tooltip title="Cancela a edição do cadastro e volta para a tela de listar" disableFocusListener >
                            <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                startIcon={<CancelIcon />}
                                onClick={this.voltarParaListaDeCadastro}
                            >
                                Cancelar
                            </Button>
                        </Tooltip>
                        &nbsp;
                        <Tooltip title="Mostra informações e atalhos da tela (f1)" disableFocusListener >
                            <Button
                                variant="contained"
                                color="default"
                                size="small"
                                startIcon={<HelpOutlineIcon />}
                                inputRef={this.ajudaInput}
                                onClick={this.mostrarAjuda}
                            >
                                Ajuda
                            </Button>
                        </Tooltip>
                        &nbsp;
                    </Paper>
                    <Paper className="assistidos">
                        <React.Fragment>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} lg={5}>
                                    <Tooltip title="Nome do assistido" disableFocusListener >
                                        <TextField
                                            required
                                            id="nome"
                                            name="nome"
                                            label="Nome do assistido"
                                            fullWidth
                                            value={this.state.nome}
                                            onChange={this.onNomeChange}
                                            autoFocus
                                        />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={3}>
                                    <Tooltip title="Nascimento" disableFocusListener >
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                                            <KeyboardDatePicker
                                                id="nascimento"
                                                label="Data de nascimento"
                                                name="nascimento"
                                                disableToolbar
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                value={this.state.dataNascimento}
                                                onChange={this.onNascimentoChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'alterar data de nascimento',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={4}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">
                                            Sexo
                                        </FormLabel>
                                        <RadioGroup
                                            aria-label="sexo"
                                            name="sexo"
                                            value={this.state.sexo}
                                            onChange={this.onSexoChange}
                                            row
                                        >
                                            <Tooltip title="Sexo do assistido - feminino" disableFocusListener >
                                                <FormControlLabel
                                                    value="F"
                                                    control={<Radio />}
                                                    label="Feminino"
                                                    labelPlacement="end" />
                                            </Tooltip>
                                            <Tooltip title="Sexo do assistido - masculino" disableFocusListener >
                                                <FormControlLabel
                                                    value="M"
                                                    control={<Radio color="primary" />}
                                                    label="Masculino" 
                                                    labelPlacement="end" />
                                            </Tooltip>
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={4}>
                                    <Tooltip title="Telefone do assistido" disableFocusListener >
                                        <MuiPhoneNumber
                                            id="telefone"
                                            label="Telefone"
                                            fullWidth
                                            autoComplete="telefone"
                                            defaultCountry={"br"}
                                            value={this.state.telefone}
                                            onChange={this.onTelefoneChange}
                                        />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Tooltip title="E-mail" disableFocusListener >
                                        <TextField
                                            id="email"
                                            name="email"
                                            label="E-mail"
                                            fullWidth
                                            value={this.state.email}
                                            onChange={this.onEmailChange} />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <Tooltip title="CEP do assistido" disableFocusListener >
                                        <TextField
                                            id="cep"
                                            name="cep"
                                            label="CEP"
                                            fullWidth
                                            autoComplete="cep"
                                            value={this.state.cep}
                                            onChange={this.onCepChange}
                                        />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={10}>
                                    <Tooltip title="Endereço do assistido" disableFocusListener >
                                        <TextField
                                            required
                                            id="endereco"
                                            name="endereco"
                                            label="Endereço"
                                            fullWidth
                                            value={this.state.endereco}
                                            onChange={this.onEnderecoChange}
                                        />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Tooltip title="Cidade" disableFocusListener >
                                        <TextField
                                            id="cidade"
                                            name="cidade"
                                            label="Cidade"
                                            fullWidth
                                            autoComplete="cidade"
                                            value={this.state.cidade}
                                            onChange={this.onCidadeChange}
                                        />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Tooltip title="Estado" disableFocusListener >
                                        <TextField
                                            id="estado"
                                            name="estado"
                                            label="Estado"
                                            fullWidth
                                            autoComplete="estado"
                                            value={this.state.estado}
                                            onChange={this.onEstadoChange}
                                        />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={4} lg={2}>
                                    <Tooltip title="Animal" disableFocusListener >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.animal}
                                                    onChange={this.onAnimalChange}
                                                    value="animal"
                                                    color="primary"
                                                />
                                            }
                                            label="Animal"
                                        />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Tooltip title="Nome do Protetor" disableFocusListener >
                                        <TextField
                                            id="nomeProtepor"
                                            name="nomeProtepor"
                                            label="Nome do Protetor"
                                            fullWidth
                                            value={this.state.nomeProtepor}
                                            onChange={this.onNomeProtetorChange} />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Tooltip title="Sintomas do assistido" disableFocusListener >
                                        <TextField
                                            id="nomeProtepor"
                                            name="nomeProtepor"
                                            label="Sintomas"
                                            fullWidth
                                            value={this.state.sintoma}
                                            onChange={this.onSintomaChange}
                                            multiline
                                            rows={3} />
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    </Paper>
                </main>
            </HotKeys>
        )
    }
}


export default withSnackbar(PgEditarCadastroInternet);
