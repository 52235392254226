import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import { format, parseISO } from 'date-fns';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Redirect } from "react-router-dom";
import CadastrosInternetService from '../../services/cadastros_internet';


function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
    { id: 'id', numeric: false, disablePadding: true, label: 'Id' },
    { id: 'nome', numeric: false, disablePadding: false, label: 'Nome' },
    { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'tipo', numeric: false, disablePadding: false, label: 'Tipo' },
    { id: 'dataCadastro', numeric: false, disablePadding: false, label: 'Cadastrado em' },
    { id: 'acoes', numeric: false, disablePadding: false, label: 'Ações' },
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(5)
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export default function PgListarCadastrosInternet() {
    const classes = useStyles();
    const [assistidos, setAssistidos] = React.useState([]);
    const [cadastroParaAprovacao, setCadastroParaAprovacao] = React.useState(null);
    const [cadastroParaEdicao, setCadastroParaEdicao] = React.useState(null);
    const [cadastroParaExclusao, setCadastroParaExclusao] = React.useState(null);
    const [carregarAssistido, setCarregarAssistido] = React.useState(true);
    const [editarCadastro, setEditarCadastro] = React.useState(false);
    const [mostrarModalAprovar, setMostrarModalAprovar] = React.useState(false);
    const [mostrarModalDeletar, setMostrarModalDeletar] = React.useState(false);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('nome');
    const [pageIndex, setPageIndex] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [totalCadastros, setTotalCadastros] = React.useState(0);
    const { enqueueSnackbar } = useSnackbar();

    const service = new CadastrosInternetService();

    useEffect(() => {
        if (carregarAssistido) {
            service.listarCadastrosInternet(pageIndex + 1, 10)
                .then(res => {
                    console.log("Cadastros da internet carregados com sucesso", res);
                    setAssistidos(res.cadastros);
                    setTotalCadastros(res.total);
                })
                .catch(err => {
                    const msg = "Ocorreu um erro ao carregar os cadastros de internet. Tente recarregar página para tentar novamente.";
                    enqueueSnackbar(msg, {
                        variant: 'error',
                    });
                });
            setCarregarAssistido(false);
        }
    }, [carregarAssistido, enqueueSnackbar, pageIndex, service, setCarregarAssistido]);

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPageIndex(newPage);
        setCarregarAssistido(true);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPageIndex(0);
    };

    const formatarData = (data) => {
        if (data === undefined || data === null || data === "") {
            return ""
        }

        try {
            const nascimento = parseISO(data);
            const dataFormatada = format(nascimento, "dd/MM/yyyy");

            return dataFormatada;
        } catch (err) {
            return "";
        }
    }

    const handleClose = () => {
        setMostrarModalDeletar(false);
    };

    const handleCloseModalAprovar = () => {
        setMostrarModalAprovar(false);
    };

    const onAprovar = (cadastroInternet) => {
        setCadastroParaAprovacao(cadastroInternet);
        setMostrarModalAprovar(true);
    };

    const onConfirmarAprovar = () => {
        service.aprovarCadastro(cadastroParaAprovacao.id)
            .then(res => {
                console.log("Cadastro aprovado", res);
                const msg = `Cadastro ${cadastroParaAprovacao.nome} aprovado com sucesso. Número de controle do assistido: ${res.id}`;
                enqueueSnackbar(msg, {
                    variant: 'success',
                });

                const assistidosParaAprovacao = assistidos.filter(a => a.id !== cadastroParaAprovacao.id);

                setAssistidos(assistidosParaAprovacao);
                setMostrarModalAprovar(false);
                setCadastroParaAprovacao(null);
            })
            .catch(err => {
                console.log("Erro ao aprovar o cadastro", err);
                const msg = `Ocorreu um erro ao aprovar o cadastro ${cadastroParaAprovacao.nome}`;
                enqueueSnackbar(msg, {
                    variant: 'error',
                });
                setMostrarModalAprovar(false);
                setCadastroParaAprovacao(null);
            });
    };

    const onEditar = (cadastroInternet) => {
        setCadastroParaEdicao(cadastroInternet);
        setEditarCadastro(true);
    };

    const onDeletar = (cadastroInternet) => {
        setCadastroParaExclusao(cadastroInternet);
        setMostrarModalDeletar(true);
    };

    const onConfirmarDeletar = () => {
        setCadastroParaExclusao(null);
    };

    const renderEditarCadastroInternet = () => {
        if (editarCadastro && cadastroParaEdicao !== undefined && cadastroParaEdicao !== null) {
            const url = `/cadastros-internet/${cadastroParaEdicao.id}`;
            return <Redirect to={url} />
        }
    }

    return (
        <div className={classes.root}>
            {renderEditarCadastroInternet()}

            <Dialog
                open={mostrarModalAprovar}
                onClose={() => setMostrarModalAprovar(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <WarningIcon color="warning" fontSize="large" />
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    Aprovar Cadastro
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Tem certeza que deseja <strong>aprovar</strong> o cadastro do assistido {cadastroParaEdicao && cadastroParaEdicao.nome}?
                        <br />
                        <br />
                        * Esta ação não poderá ser desfeita!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModalAprovar} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={onConfirmarAprovar} color="primary" autoFocus>
                        Aprovar
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={mostrarModalDeletar}
                onClose={() => setMostrarModalDeletar(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <WarningIcon color="error" fontSize="large" />
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    Deletar Cadastro
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Tem certeza que deseja deletar o cadastro do assistido {cadastroParaExclusao && cadastroParaExclusao.nome}?
                        <br />
                        <br />
                        * Esta ação não poderá ser desfeita!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={onConfirmarDeletar} color="primary" autoFocus>
                        Deletar
                    </Button>
                </DialogActions>
            </Dialog>
            <Paper className={classes.paper}>
                <Typography variant="h4" component="h5">
                    Cadastros de Internet
                </Typography>
                <br />
                <br />
                <div className={classes.tableWrapper}>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="medium"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={totalCadastros}
                        />
                        <TableBody>
                            {stableSort(assistidos, getSorting(order, orderBy))
                                .map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.id}
                                        >
                                            <TableCell component="th" id={labelId} scope="row" padding="none">
                                                {row.id}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.nome}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.email}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.tipo === "A" ? "Animal" : "Humano"}
                                            </TableCell>
                                            <TableCell align="left">
                                                {formatarData(row.dataCadastro)}
                                            </TableCell>
                                            <TableCell align="left">
                                                <Tooltip title="Aprovar o cadastro de internet" disableFocusListener >
                                                    <IconButton
                                                        aria-label="Aprovar o cadastro de internet"
                                                        size="medium"
                                                        onClick={() => onAprovar(row)}
                                                    >
                                                        <CheckCircleIcon fontSize="inherit" color="secondary" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Editar o cadastro de internet" disableFocusListener >
                                                    <IconButton
                                                        aria-label="Editar o cadastro de internet"
                                                        size="medium"
                                                        onClick={() => onEditar(row)}
                                                    >
                                                        <EditIcon fontSize="inherit" color="primary" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Deletar o cadastro de internet" disableFocusListener >
                                                    <IconButton
                                                        aria-label="deletar o cadastro de internet"
                                                        size="medium"
                                                        onClick={() => onDeletar(row)}
                                                    >
                                                        <DeleteIcon fontSize="inherit" color="error" />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={totalCadastros}
                    rowsPerPage={rowsPerPage}
                    page={pageIndex}
                    backIconButtonProps={{
                        'aria-label': 'previous page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'next page',
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}
