import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Redirect } from "react-router-dom";

const AssistidoObrigatorio = ({ children, idAssistido, assistido }) => {
    if (assistido) {
        return (
            <>
                {children}
            </>
        )
    }
    if (idAssistido) {
        return (
            <>
                <Typography variant='h5' style={{ display: 'flex', alignContent: 'center' }}>
                    <CircularProgress size={30} /> &nbsp; Carregando dados do assistido...
                </Typography>
            </>
        )
    }
    return (
        <Redirect to={'/pesquisar-assistido'} />
    )
}

export default AssistidoObrigatorio;