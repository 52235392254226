import { IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import { useSnackbar } from 'notistack';
import React, { useState } from "react";
import CpDeletarDialog from '../../components/cp-deletar-dialog';
import { isoDateToStrDate, isoToStrDate } from '../../services/date-utils';
import TratamentosService from '../../services/tratamentos';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
	},
	table: {
		tableLayout: 'auto',
	},
}));

const labels = {
	cromoterapia: "Cromoterapia",
	cromoDiaria: "Cromo Diária",
	reiki: "Reiki",
	desobsessao: "Desobsessão",
	evangelho: "Evangelho",
	umbanda: "Umbanda",
	vibracional: "Vibracional",
	psicanalise: "Psicanálise",
	ctd: "CTD",
	terraDeElfos: "Terra de Elfos",
	tabagismo: "Tabagismo",
	karuna: "Karuna",
	xamanismo: "Xamanismo"
};

export function listTratamentos(tratamentos) {
	const keys = Object.keys(tratamentos);

	const trueKeys = keys.filter(key => tratamentos[key] && labels[key]);
	let listaTratamentos = trueKeys.map(key => labels[key]);
	if (!!tratamentos.cromoterapias?.length) {
		listaTratamentos = listaTratamentos.filter(t => t !== labels['cromoterapia']);
		listaTratamentos = ['Cromo Detalhada', ...listaTratamentos];
	}
	if (!!tratamentos.outrosTratamentos) {
		listaTratamentos.push(tratamentos.outrosTratamentos);
	}
	return listaTratamentos.join(', ');
}


function CpListaTratamentos({ lista, onAtualizarLista }) {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	const service = new TratamentosService();

	const [toDelete, setToDelete] = useState('');

	const handleDelete = (tratamento) => {
		setToDelete(tratamento);
	};

	const onConfirmDeletar = (tratamento) => {
		service.deletar(tratamento.id)
			.then((res) => {
				enqueueSnackbar('Tratamento deletado com sucesso!', {
					variant: 'success',
				});
				onAtualizarLista(res);
			})
			.catch((err) => {
				enqueueSnackbar('Erro ao deletar o tratamento!', {
					variant: 'error',
				});
			});
	};

	const fecharModalDeletar = () => {
		setToDelete('');
	};

	if (!lista?.length) {
		return <Typography variant='subtitle2' >Nenhum tratamento encontrado. Adicione novos tratamentos para vê-los aqui</Typography>;
	}

	return (
		<div className={classes.root}>
			<CpDeletarDialog
				toDelete={toDelete}
				onConfirm={onConfirmDeletar}
				onClose={fecharModalDeletar}
				title={"Remover Tratamento"}
			/>
			<Typography variant='subtitle2' paragraph>Histórico de Tratamentos</Typography>
			<Paper elevation={3}>
				<Table className={classes.table} size="small">
					<TableHead>
						<TableRow>
							<TableCell>Prescrição</TableCell>
							<TableCell>Data Fim</TableCell>
							<TableCell>Semanas</TableCell>
							<TableCell>Tratamentos</TableCell>
							<TableCell>Ações</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{lista.map((tratamento) => (
							<TableRow key={tratamento.id}>
								<TableCell>{isoToStrDate(tratamento.dataPrescricao)}</TableCell>
								<TableCell>{isoDateToStrDate(tratamento.dataFim)}</TableCell>
								<TableCell>{tratamento.quantidadeCromos}</TableCell>
								<TableCell>{listTratamentos(tratamento)}</TableCell>
								<TableCell>
									<Tooltip title="Editar tratamento" disableFocusListener>
										<IconButton size="medium" href={`/tratamento/${tratamento.id}`}>
											<EditIcon fontSize="inherit" color="primary" />
										</IconButton>
									</Tooltip>
									<Tooltip title="Deletar tratamento" disableFocusListener>
										<IconButton
											aria-label="remover tratamento"
											size="medium"
											onClick={() => handleDelete(tratamento)}
										>
											<DeleteIcon fontSize="inherit" color="error" />
										</IconButton>
									</Tooltip>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Paper>
		</div>
	);
}

export default CpListaTratamentos;