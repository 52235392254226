import Grid from '@material-ui/core/Grid';
import { format } from 'date-fns';
import React from 'react';
import { decorateAssistido } from '../assistidos/utils';
import { listTratamentos } from '../cp-tratamentos/cp-lista-tratamentos';
import { renderTitle } from './report-utils';

export default function CpRelatorioVibracional({ assistido, tratamento }) {

    const renderHistoricoSintomas = () => {
        const sintomas = assistido.sintomas;
        return (
            <>
                <Grid item xs={12} lg={12}>
                    <h3>Histórico de Sintomas</h3>
                    {sintomas.map((sintoma, index) => (
                        <div key={index}>
                            <p><strong>{format(new Date(sintoma.dataConsulta), 'dd/MM/yyyy')}:</strong> {sintoma.descricao ? sintoma.descricao : (sintoma.descricaoAssistido || '')}</p>
                        </div>
                    ))}
                </Grid>
            </>
        );
    };

    const renderHistoricoTratamentos = () => {
        const tratamentos = assistido.tratamentos;
        return (
            <>
                <Grid item xs={12} lg={12}>
                    <h3>Histórico de Tratamentos</h3>
                    {tratamentos.map((tratamento, index) => (
                        <div key={index}>
                            <p><strong>{format(new Date(tratamento.dataPrescricao), 'dd/MM/yyyy')}:</strong> {listTratamentos(tratamento)}</p>
                        </div>
                    ))}
                </Grid>
            </>
        );
    };

    if (!tratamento?.vibracional) {
        return (
            <></>
        );
    }

    return (
        <Grid container>
            {renderTitle("CASA DO CONSOLADOR - VIBRACIONAL")}
            <Grid item xs={12} lg={12}>
                <strong>Controle:</strong> {assistido.id} <br />
                <strong>Data do Encaminhamento:</strong> {format(new Date(tratamento.dataPrescricao), 'dd/MM/yyyy')} <br />
                <strong>Nome:</strong> {assistido.nome || 'Não Informado'} <br />
                <strong>Data Nascimento:</strong> {assistido.dataNascimento ? `${assistido.strDataNascimento} (${assistido.idade})` : 'Não Informado'} <br />
                <strong>Telefone:</strong> {assistido.telefone || 'Não Informado'} <br />
                <strong>Endereço:</strong> {assistido.endereco || 'Não Informado'} <br />
                <strong>Email:</strong> {assistido.email || 'Não Informado'} <br />
                <strong>Início:</strong> __/__/__
            </Grid>
            {renderHistoricoSintomas()}
            {renderHistoricoTratamentos()}
        </Grid >
    );
}
