import { Paper } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from "react";
import CpEditarSintoma from "./cp-editar-sintoma";
import CpListaSintomas from './cp-lista-sintomas';
import './cp-sintomas.css';

function CpSintomas({ assistido }) {
    const { enqueueSnackbar } = useSnackbar();

    const [sintomas, setSintomas] = useState(assistido.sintomas);

    const atualizarSintomas = (novaListaSintomas) => {
        if (novaListaSintomas) {
            setSintomas(novaListaSintomas);
        } else {
            setSintomas([]);
        }
    }

    useEffect(() => {
        const existeConflito = sintomas && sintomas.some(sintoma => sintoma.conflitante)
        if (existeConflito) {
            enqueueSnackbar('Existem uma ou mais sintomas agendadas para a mesma data!', {
                variant: 'warning',
            });
        }
    }, [sintomas]);

    return (
        <>
            <Paper className="app-form">
                <CpEditarSintoma onAdicionarSintoma={atualizarSintomas} assistidoId={assistido.id} />
            </Paper>
            <Paper className="app-form" style={{ margin: '5px 0' }}>
                <CpListaSintomas
                    sintomas={sintomas}
                    atualizarSintomas={atualizarSintomas}
                />
            </Paper>
        </>
    );
}

export default CpSintomas;

