import { format, parse, parseISO } from "date-fns";

const DatePattern = {
    dd_MM_yyyy_HH_mm: 'dd/MM/yyyy HH:mm',
    DD_MM_YYYY: 'dd/MM/yyyy',
    dd_MM_yy: 'dd/MM/yy',
    YYYY_MM_DD: 'yyyy-MM-dd',
    HH_mm_ss: 'HH:mm:ss'
};

// Constante para padronizar números com dois dígitos
const pad = (num) => String(num).padStart(2, '0');

// Uso: isoDateAndJsDatetimeToDatetime('2023-05-15', new Date('2023-05-15T14:30:00'))
// Retorna: Objeto Date representando '2023-05-15T14:30:00'
const isoDateAndJsDatetimeToDatetime = (strDate, dateTime) => {
    return parseISO(strDate + 'T' + format(dateTime, DatePattern.HH_mm_ss));
}

// Uso: strDateAndStrTimeToJsDate('2023-05-15', '14:30')
// Retorna: Objeto Date representando '2023-05-15T14:30:00'
const strDateAndStrTimeToJsDate = (strDate, strTime) => {
    return new Date(strDateAndStrTimeToIso(strDate, strTime));
}

// Uso: strDateAndStrTimeToIso('2023-05-15', '14:30')
// Retorna: '2023-05-15T14:30:00'
const strDateAndStrTimeToIso = (strDate, strTime) => {
    const fullTime = strTimeToIsoTime(strTime);
    const date = jsDateToIsoDate(new Date(strDate));
    return `${date}T${fullTime}`;
}

// Uso: strTimeToIsoTime('14:30')
// Retorna: '14:30:00'
const strTimeToIsoTime = (horario) => {
    let [horas, minutos, segundos = 0] = horario.split(':').map(Number);
    return `${pad(horas)}:${pad(minutos)}:${pad(segundos)}`;
}

// Uso: isoTimeToStrTime('14:30:00')
// Retorna: '14:30'
const isoTimeToStrTime = (horario) => {
    let [horas, minutos] = horario.split(':');
    return `${pad(horas)}:${pad(minutos)}`;
}

// Uso: isoToStrDate('2023-05-15T14:30:00')
// Retorna: '15/05/2023'
const isoToStrDate = (dateString, pattern = DatePattern.DD_MM_YYYY) => {
    if (!dateString) {
        return '';
    }
    return format(parseISO(dateString), pattern);
};

// Uso: capitalizeFirstLetter('segunda')
// Retorna: 'Segunda'
const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

// Uso: isoDateToStrDayAndStrDate('2023-05-15')
// Retorna: 'Seg 15/05/2023'
const isoDateToStrDayAndStrDate = (dateString, pattern = DatePattern.DD_MM_YYYY) => {
    if (!dateString) {
        return '';
    }
    const date = parseISO(dateString);
    const day = capitalizeFirstLetter(date.toLocaleDateString('pt-BR', { weekday: 'short' }));
    return `${day} ${format(date, pattern)}`;
};

// Uso: isoDateToStrDate('2023-05-15')
// Retorna: '15/05/2023'
const isoDateToStrDate = (dateString, pattern = DatePattern.DD_MM_YYYY) => {
    if (!dateString) {
        return '';
    }
    const date = parse(dateString, DatePattern.YYYY_MM_DD, new Date());
    return format(date, pattern);
};

// Uso: strDateToIsoDate('15/05/2023')
// Retorna: '2023-05-15'
const strDateToIsoDate = (dateString, pattern = DatePattern.YYYY_MM_DD) => {
    if (!dateString) {
        return '';
    }
    const date = parse(dateString, DatePattern.DD_MM_YYYY, new Date());
    return format(date, pattern);
};

// Uso: toISO(new Date('2023-05-15T14:30:00'))
// Retorna: '2023-05-15T14:30:00.000Z'
const toISO = (date) => {
    return date ? date.toISOString() : null;
};

// Uso: jsDateToIsoDate(new Date('2023-05-15'))
// Retorna: '2023-05-15'
const jsDateToIsoDate = (date) => {
    return format(date, DatePattern.YYYY_MM_DD);
};

// Uso: jsDateToIsoTime(new Date('2023-05-15T14:30:00'))
// Retorna: '14:30:00'
const jsDateToIsoTime = (date) => {
    return format(date, DatePattern.HH_mm_ss);
};

// Uso: isoDateToJsDate('2023-05-15')
// Retorna: Objeto Date representando '2023-05-15T00:00:00'
const isoDateToJsDate = (dateString) => {
    return dateString ? new Date(dateString) : null;
};

export {
    DatePattern,
    strDateAndStrTimeToJsDate, 
    jsDateToIsoDate, 
    jsDateToIsoTime, 
    toISO, 
    isoDateToJsDate, 
    isoToStrDate,
    isoDateToStrDate, 
    strTimeToIsoTime, 
    isoDateToStrDayAndStrDate, 
    isoDateAndJsDatetimeToDatetime,
    strDateToIsoDate,
    isoTimeToStrTime
};