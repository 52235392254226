import { Button, FormControl, Grid, InputLabel, MenuItem, TextField } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import SaveIcon from '@material-ui/icons/Save';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import FeriadosService from '../../services/feriados';

const estadoInicial = {
    id: null,
    nome: '',
    dataFeriado: '',
    dataSubstituicao: '',
};

function CpEditarFeriado({ lista, id, onSalvar }) {
    const [feriado, setFeriado] = useState(estadoInicial);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const service = new FeriadosService();

    useEffect(() => {
        if (!!lista?.length && id) {
            const feriadoInicial = lista.find(f => f.id == id);
            setFeriado(feriadoInicial);
        }
    }, [id, lista]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFeriado((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const salvar = () => {
        let valido = true;
        if (!feriado.nome) {
            enqueueSnackbar('Informe o Nome do Feriado!', { variant: 'error' });
            valido = false;
        }
        if (!feriado.dataFeriado) {
            enqueueSnackbar('Informe a Data do Feriado!', { variant: 'error' });
            valido = false;
        }
        if (!feriado.dataSubstituicao) {
            enqueueSnackbar('Informe a Data de Substituição!', { variant: 'error' });
            valido = false;
        }

        // Verificar se já existe um feriado na dataFeriado
        const feriadoExistente = lista.find(f => f.dataFeriado === feriado.dataFeriado && f.id !== feriado.id);
        if (feriadoExistente) {
            enqueueSnackbar('Já existe um feriado cadastrado para esta data!', { variant: 'error' });
            valido = false;
        }

        if (valido) {
            service.salvar(feriado)
                .then(res => {
                    if (feriado.id) {
                        history.push('/feriados');
                    } else {
                        setFeriado(estadoInicial);
                        onSalvar(res);
                    }
                    enqueueSnackbar('Feriado salvo com sucesso!', { variant: 'success' });
                })
                .catch(err => {
                    enqueueSnackbar('Erro ao salvar o feriado!', { variant: 'error' });
                });
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
                <TextField
                    label="Nome do Feriado"
                    name="nome"
                    value={feriado.nome}
                    onChange={handleChange}
                    fullWidth
                    required
                    inputProps={{ maxLength: 50 }}
                    autoComplete="off"
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <TextField
                    label="Data do Feriado"
                    type="date"
                    name="dataFeriado"
                    value={feriado.dataFeriado}
                    onChange={handleChange}
                    fullWidth
                    required
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <TextField
                    label="Data de Substituição"
                    type="date"
                    name="dataSubstituicao"
                    value={feriado.dataSubstituicao}
                    onChange={handleChange}
                    fullWidth
                    required
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={salvar}
                    startIcon={feriado.id ? <SaveIcon /> : <AddBoxIcon />}
                >
                    {feriado.id ? 'Salvar' : 'Adicionar'}
                </Button>
                {feriado.id && <>&nbsp; <Button component={Link} to={`/feriados`} className="button" color="secondary">Cancelar</Button></>}
            </Grid>
        </Grid>
    );
}

export default CpEditarFeriado;