import 'typeface-roboto';
import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { GlobalHotKeys, HotKeys } from 'react-hotkeys';
import PgLogin from './pages/pg-login/pg-login';
import { Header } from './components/header';
import Routes from './routes/Routes';
import { SnackbarProvider } from 'notistack';
import UsuarioService from './services/usuarios';

import './App.css';

const globalKeyMap = {
    HOME: { name: 'Página Inicial', sequence: 'ctrl+0' },
    PESQUISAR_ASSISTIDO: { name: 'Pesquisar Assistidos', sequence: 'ctrl+1' },
    ADICIONAR_ASSISTIDO: { name: 'Adicionar Novos Assistidos', sequence: 'ctrl+2' },
    ADICIONAR_SINTOMA: { name: 'Cadastro de Sintomas', sequence: 'ctrl+3' },
    CADASTRAR_CIRURGIAS: { name: 'Cadastro de Cirurgias', sequence: 'ctrl+4' },
    CADASTRAR_TRATAMENTOS: { name: 'Cadastro de Tratamentos', sequence: 'ctrl+5' },
    CADASTRAR_CROMO: { name: 'Cadastro de Cromoterapia', sequence: 'ctrl+6' },
    EDITAR_CONFIGURACOES: { name: 'Editar Configurações', sequence: 'ctrl+7' },
    CADASTROS_INTERNET: { name: 'Listar Cadastros da Internet', sequence: 'ctrl+8' }
};

function App() {
    const [redirect, setRedirect] = useState(false);
    const [redirectTo, setRedirectTo] = useState(null);

    const usuarioService = new UsuarioService();

    function onHome(e) {
        e.preventDefault();
        setRedirect(true);
        setRedirectTo('/');
    };

    function onCadastroConsultas(e) {
        e.preventDefault();
        setRedirect(true);
        setRedirectTo('/home');
    };
  
    function onAdicionarAssistidos(e) {
        e.preventDefault();
        setRedirect(true);
        setRedirectTo('/assistidos');
    }
  
    function onPesquisarAssistidos(e) {
        e.preventDefault();
        setRedirect(true);
        setRedirectTo('/pesquisar-assistido');
    }

    function onCadastrarCirurgias(e) {
        e.preventDefault();
        setRedirect(true);
        setRedirectTo('/cirurgias');
    }

    function onCadastrarTratamentos(e) {
        e.preventDefault();
        setRedirect(true);
        setRedirectTo('/tratamentos');
    }

    function onCadastrarCromo(e) {
        e.preventDefault();
        setRedirect(true);
        setRedirectTo('/cromo');
    }

    function onCadastrarSintomas(e) {
        e.preventDefault();
        setRedirect(true);
        setRedirectTo('/sintomas');
    }

    function onEditarConfiguracoes(e) {
        e.preventDefault();
        setRedirect(true);
        setRedirectTo('/configcirurgias');
    }

    function onListarCadastrosInternet(e) {
        e.preventDefault();
        setRedirect(true);
        setRedirectTo('/cadastros-internet');
    }

    function renderRedirect() {
        if (redirect) {
           return <Redirect to={redirectTo} />
        }
    }

    return (
        <React.Fragment>
            <GlobalHotKeys keyMap={globalKeyMap} handlers={{
                        HOME: onHome,
                        CADASTRO_CONSULTAS: onCadastroConsultas,
                        ADICIONAR_ASSISTIDO: onAdicionarAssistidos,
                        PESQUISAR_ASSISTIDO: onPesquisarAssistidos,
                        CADASTRAR_CIRURGIAS: onCadastrarCirurgias,
                        CADASTRAR_TRATAMENTOS: onCadastrarTratamentos,
                        ADICIONAR_SINTOMA: onCadastrarSintomas,
                        CADASTRAR_CROMO: onCadastrarCromo,
                        EDITAR_CONFIGURACOES: onEditarConfiguracoes,
                        CADASTROS_INTERNET: onListarCadastrosInternet
                        }} />
                <Router>
                    <React.Fragment>
                        <div className="estrutura-principal">
                            {usuarioService.usuarioAutenticado() ? <Header /> : null}
                            {renderRedirect()}
                            <Container component="main" className="conteudo">
                                <HotKeys keyMap={globalKeyMap} handlers={{}}>
                                    <SnackbarProvider
                                        maxSnack={3}
                                        anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}>
                                        <Route path="/login" component={PgLogin} exact />
                                        <Routes />
                                    </SnackbarProvider>
                                </HotKeys>
                            </Container>
                        </div>
                    </React.Fragment>
                </Router>
            </React.Fragment>
    );
}

export default App;
