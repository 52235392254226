import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
}));

export default function CpDeletarDialog({
    toDelete,
    onClose,
    onConfirm,
    title
}) {
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
        if (onClose && typeof onClose === 'function') {
            onClose();
        }
    };

    const onConfirmDeletar = () => {
        if (onConfirm && typeof onConfirm === 'function') {
            onConfirm(toDelete);
            handleClose();
        } else {
            console.warn('Nenhum callback informado');
        }
    };

    useEffect(() => {
        setOpen(!!toDelete);
    }, [toDelete]);

    return (
        <div>
            <Dialog
                name="deletarModal"
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <WarningIcon color="error" fontSize="large" />
                        <span style={{ marginLeft: '0.5em' }}>{title}</span>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Tem certeza que deseja deletar o registro?
                        <br />
                        * Esta ação não poderá ser desfeita!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        startIcon={<DeleteIcon />}
                        onClick={onConfirmDeletar}
                    >
                        Deletar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

CpDeletarDialog.propTypes = {
    toDelete: PropTypes.any.isRequired,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    title: PropTypes.string.isRequired,
};
