import * as Icons from '@material-ui/icons';

const HelpSection = {
    salvar: 'Atalhos para salvar a cirurgia',
    campos: 'Atalhos dos campos',
}

const Atalhos = {
    AJUDA: {
        sequence: 'f1',
    },
    SALVAR_IR_CIRURGIAS: {
        sequence: 'pagedown',
        help: {
            section: HelpSection.salvar,
            icon: Icons.Save,
            title: 'pagedown',
            desc: 'Salvar a cirurgia e ir para a página de cirurgias'
        },
    },
    SALVAR_IR_ASSISTIDO: {
        sequence: 'pageup',
        help: {
            section: HelpSection.salvar,
            icon: Icons.Save,
            title: 'pageup',
            desc: 'Salvar a cirurgia e ir para a página de cadastro'
        }
    },
    FOCAR_DESCRICAO: {
        sequence: 'alt+s',
        help: {
            section: HelpSection.campos,
            icon: Icons.CenterFocusStrong,
            title: 'alt+s',
            desc: 'Focar no campo Descrição dos sintomas'
        }

    },
}

export default Atalhos;
