

import api, { URL_FERIADOS } from "../api_env_config";

class FeriadosService {

    obterLista = async () => {
        return new Promise((resolve, reject) => {
            const url = `${URL_FERIADOS}`;
            api.get(url).then(response => {
                resolve(response.data.data);
            }).catch(err => {
                reject("Error: " + err);
            });
        });
    };

    salvar = async (config) => {
        return new Promise((resolve, reject) => {
            const url = `${URL_FERIADOS}`;
            api.post(url, config).then(response => {
                resolve(response.data.data);
            })
            .catch(err => {
                reject("Error: " + err);
            });
        });
    };

    deletar = async (id) => {
        return new Promise((resolve, reject) => {
            const url = `${URL_FERIADOS}/${id}`;
            api.delete(url).then(response => {
                resolve(response.data.data);
            })
            .catch(err => {
                reject("Error: " + err);
            });
        });
    };

}

export default FeriadosService;
