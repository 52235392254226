import { Badge } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { indigo } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import HumanoIcon from '@material-ui/icons/EmojiPeople';
import TipoVazioIcon from '@material-ui/icons/HelpOutline';
import PlantaIcon from '@material-ui/icons/LocalFlorist';
import AnimalIcon from '@material-ui/icons/Pets';
import React from 'react';
import { DatePattern, isoDateToStrDate } from '../../services/date-utils';
import { Sexo, TipoAssistido } from './enums';
import { calcularIdade } from './utils';

const useStyles = makeStyles(theme => ({
    avatar: {
        color: '#fff',
        backgroundColor: indigo[500],
    },
    dot: {
        backgroundColor: 'white',
    },
}));

export default function CpResumoAssistido({ assistido }) {
    const classes = useStyles();

    const idade = calcularIdade(assistido.dataNascimento);
    const dataNascimento = idade ? assistido.strDataNascimento : "Não informado";

    const renderSexo = () => {
        const tipoAssistido = TipoAssistido.porDbVal(assistido.tipo);
        if (tipoAssistido === TipoAssistido.PLANTA) {
            return (<></>);
        }
        let sexoDesc = "Não informado";
        if (assistido.sexo) {
            const sexoAssistido = Sexo.porDbVal(assistido.sexo);
            if (tipoAssistido === TipoAssistido.ANIMAL) {
                sexoDesc = sexoAssistido === Sexo.MASCULINO ? "Macho" : "Fêmea";
            } else {
                sexoDesc = sexoAssistido.desc;
            }
        }
        return (
            <Typography variant="body2">
                <strong>Sexo:</strong> {sexoDesc}
            </Typography>
        );
    };

    const renderIconTipoAssistido = () => {
        const tipo = TipoAssistido.porDbVal(assistido.tipo);
        let IconComponent;
        switch (tipo) {
            case TipoAssistido.ANIMAL:
                IconComponent = AnimalIcon;
                break;
            case TipoAssistido.PLANTA:
                IconComponent = PlantaIcon;
                break;
            case TipoAssistido.HUMANO:
                IconComponent = HumanoIcon;
                break;
            default:
                IconComponent = TipoVazioIcon;
                break;
        }
        return (
            <Badge color="primary" badgeContent={assistido.semanasRestantesTratamento} invisible={!assistido.tratamentosEmAndamento} overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Avatar className={`${classes.avatar} avatar-small`} style={{ marginRight: '8px' }}>
                    <IconComponent />
                </Avatar>
            </Badge>
        );
    };

    const renderNomeProtetor = () => {
        const tipo = TipoAssistido.porDbVal(assistido.tipo);
        if (tipo === TipoAssistido.ANIMAL || tipo === TipoAssistido.PLANTA) {
            return (
                <Typography variant="body2">
                    <strong>Nome do Protetor:</strong> {assistido.nomeProtetor || "Não informado"}
                </Typography>
            );
        }
        return <></>;
    };

    const renderDataNascimento = () => {
        return (
            <Typography variant="body2">
                <strong>Data de Nascimento:</strong> {dataNascimento} {idade ? `(${idade})` : ''}
            </Typography>
        );
    };

    const renderEmail = () => {
        return (
            <Typography variant="body2">
                <strong>Email:</strong> {assistido.email ? assistido.email.toLowerCase() : "Não informado"}
            </Typography>
        );
    };

    const renderEndereco = () => {
        let endereco = "Não informado";
        if (assistido.endereco) {
            endereco = assistido.endereco;
            if (assistido.cidade) {
                endereco += `, ${assistido.cidade}`;
            }
            if (assistido.estado) {
                endereco += ` - ${assistido.estado}`;
            }
        }
        return (
            <Typography variant="body2">
                <strong>Endereço:</strong> {endereco}
            </Typography>
        );
    };

    const renderSemanasRestantes = () => {
        if (assistido.semanasRestantesTratamento) {
            return (
                <Typography variant="body2">
                    <strong>Semanas Restantes:</strong> {assistido.semanasRestantesTratamento}
                </Typography>
            );
        }
        return <></>;
    };

    const renderTratamento = () => {
        if (!assistido.tratamentos?.length) {
            return <Typography variant="body2">
                <strong>Tratamentos:</strong> Nenhum tratamento encontrado
            </Typography>;
        };
        if (!assistido.tratamentosEmAndamento) {
            return (
                <Typography variant="body2">
                    <strong>Tratamentos:</strong> Todos os tratamentos finalizados
                </Typography>
            );
        }
        if (assistido.tratamentosEmAndamento.length === 1) {
            const tratamento = assistido.tratamentosEmAndamento[0];
            return (
                <Typography variant="body2">
                    <strong>Tratamento:</strong> Em andamento ({isoDateToStrDate(tratamento.dataPrescricao, DatePattern.dd_MM_yy)} - {isoDateToStrDate(tratamento.dataFim, DatePattern.dd_MM_yy)})
                </Typography>
            );
        }
        return (
            <>
                <Typography variant="body2">
                    <strong>Tratamentos:</strong>
                </Typography>
                <ul style={{ margin: 0 }}>
                    {assistido.tratamentosEmAndamento.map((tratamento, index) => (
                        <li key={index}>
                            <Typography variant="body2">
                                Em andamento ({isoDateToStrDate(tratamento.dataPrescricao, DatePattern.dd_MM_yy)} - {isoDateToStrDate(tratamento.dataFim, DatePattern.dd_MM_yy)})
                            </Typography>
                        </li>
                    ))}
                </ul>
            </>
        );
    }

    return (
        <>
            <Paper className="titulo">
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={12} style={{ paddingBottom: 0 }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {renderIconTipoAssistido()}
                            <Typography variant="h5" noWrap>
                                <strong>{assistido.id}</strong> - {assistido.nome ? assistido.nome.toUpperCase() : ''}
                            </Typography>
                        </div>
                        <hr />
                    </Grid>
                    <Grid item xs={12} lg={6} style={{ paddingTop: 0 }}>
                        {renderSexo()}
                        {renderDataNascimento()}
                        {renderNomeProtetor()}
                        {renderEmail()}
                        {renderEndereco()}
                    </Grid>
                    <Grid item xs={12} lg={6} style={{ paddingTop: 0 }}>
                        {renderTratamento()}
                        {renderSemanasRestantes()}
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}
