// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assistidos {
    width: 100%;
    justify-content: center;
    margin: 2% 0 0 0;
    padding: 0% 5% 5% 5%;
    display: 'flex';
}

.titulo {
    width: 100%;
    padding: 2% 2% 2% 2%;
    display: 'flex';
}

.acoes {
    width: 100%;
    padding: 2% 2% 2% 2%;
    display: 'flex';
}

.titulo-sintomas {
    width: 100%;
    justify-content: center;
    margin: 1% 1% 0 0;
    padding: 2% 2% 2% 2%;
    display: 'flex';
}

.numero-controle input {
    font-weight: 700;
    color: #0000009e;
}

.idade input {
    color: #0000009e;
}`, "",{"version":3,"sources":["webpack://./src/pages/pg-assistidos/pg-assistidos.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,uBAAuB;IACvB,gBAAgB;IAChB,oBAAoB;IACpB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,oBAAoB;IACpB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,oBAAoB;IACpB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,uBAAuB;IACvB,iBAAiB;IACjB,oBAAoB;IACpB,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".assistidos {\n    width: 100%;\n    justify-content: center;\n    margin: 2% 0 0 0;\n    padding: 0% 5% 5% 5%;\n    display: 'flex';\n}\n\n.titulo {\n    width: 100%;\n    padding: 2% 2% 2% 2%;\n    display: 'flex';\n}\n\n.acoes {\n    width: 100%;\n    padding: 2% 2% 2% 2%;\n    display: 'flex';\n}\n\n.titulo-sintomas {\n    width: 100%;\n    justify-content: center;\n    margin: 1% 1% 0 0;\n    padding: 2% 2% 2% 2%;\n    display: 'flex';\n}\n\n.numero-controle input {\n    font-weight: 700;\n    color: #0000009e;\n}\n\n.idade input {\n    color: #0000009e;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
