import { CircularProgress, TextField, Typography } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import EmojiPeople from '@material-ui/icons/EmojiPeople';
import TipoIndefinido from '@material-ui/icons/Help';
import LocalFloristIcon from '@material-ui/icons/LocalFlorist';
import PetsIcon from '@material-ui/icons/Pets';
import SearchIcon from '@material-ui/icons/Search';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AssistidoService from '../../services/assistidos';
import CpAcoesAssistido from './cp-acoes-assistido';
import CpResumoAssistidoPopover from './cp-resumo-assistido-popover';
import { isoDateToStrDate, isoToStrDate } from '../../services/date-utils';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(3)
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

const SmallBadge = withStyles(() => ({
    badge: {
        transform: 'scale(0.8)',
        transformOrigin: '300% 300%'
    },
}))(Badge);

const colunaDataOrdenarPorMap = {
    'id': 'dataCadastro',
    'ultima_atualizacao_assistido': 'ultimaAtualizacao',
    'ultima_atualizacao_sintomas': 'ultimaAtualizacaoSintomas',
}

const CpPesquisarAssistidos = ({ pesquisarAoCarregar }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const service = new AssistidoService();

    const [pesquisar, setPesquisar] = React.useState(!!pesquisarAoCarregar);
    const [totalCadastros, setTotalCadastros] = React.useState(0);
    const [termoBusca, setTermoBusca] = React.useState('');
    const [tipoAssistido, setTipoAssistido] = React.useState('');
    const [tipoBusca, setTipoBusca] = React.useState('');
    const [assistidos, setAssistidos] = React.useState([]);
    const [ordenarPor, setOrdenarPor] = React.useState('ultima_atualizacao_sintomas');
    const [colunaDataOrdenarPor, setColunaDataOrdenarPor] = React.useState(colunaDataOrdenarPorMap['ultima_atualizacao_sintomas']);

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const pageParam = parseInt(params.get('page'), 10);
    const rowsParam = parseInt(params.get('rows'), 10);
    const pageIndexInicial = !isNaN(pageParam) ? pageParam : 0;
    const pageSizeInicial = !isNaN(rowsParam) ? rowsParam : 25;

    const [pageIndex, setPageIndex] = React.useState(pageIndexInicial);
    const [pageSize, setPageSize] = React.useState(pageSizeInicial);

    const history = useHistory();

    useEffect(() => {
        if (pesquisar) {
            const params = {}
            if (tipoAssistido) {
                params['tipo_assistido'] = tipoAssistido
            }
            if (tipoBusca) {
                const [campo, tipoBuscaVal] = tipoBusca.split('-');
                params['campo'] = campo;
                params['match'] = tipoBuscaVal;
            }
            params['ordenar'] = ordenarPor;
            service.buscaDinamica(termoBusca, params, pageIndex + 1, pageSize)
                .then((res) => {
                    if (!res || !res.assistidos.length) {
                        enqueueSnackbar(`Não foi encontrado nenhum assistido com o termo pesquisado '${termoBusca}'`, {
                            variant: 'warning',
                        });
                        setAssistidos([]);
                        setTotalCadastros(0);
                        setPesquisar(false);
                    } else {
                        setAssistidos(res.assistidos);
                        setTotalCadastros(res.total);
                        setColunaDataOrdenarPor(colunaDataOrdenarPorMap[ordenarPor]);
                        setPesquisar(false);
                    }
                }).catch((err) => {
                    setPesquisar(false);
                    console.error("Erro ao procurar assistidos", err);
                });
        }
    }, [pesquisar, pageIndex, termoBusca]);

    const setNewPageIndex = (pageIndex) => {
        setPageIndex(pageIndex);
        const params = new URLSearchParams(location.search);
        params.set('page', pageIndex);
        history.push({
            pathname: location.pathname,
            search: params.toString()
        });
    };

    const setNewPageSize = (pageSize) => {
        setPageSize(pageSize);
        const params = new URLSearchParams(location.search);
        params.set('rows', pageSize);
        history.push({
            pathname: location.pathname,
            search: params.toString(),
        });
    };

    const handleChangePage = (event, newPage) => {
        setNewPageIndex(newPage);
        setPesquisar(true);
    };

    const handleChangePageSize = (event) => {
        const pageSize = parseInt(event.target.value, 10);
        setNewPageIndex(0);
        setNewPageSize(pageSize);
        setPesquisar(true);
    };

    const onTermoBuscaChange = (e) => {
        setTermoBusca(e.target.value);
    };

    const normalizarString = (str) => {
        // Remover espaços duplos e fazer trim
        return str && typeof str === 'string' ? str.replace(/\s{2,}/g, ' ').trim() : str;
    }

    const efetuarBusca = () => {
        const termoBuscaNormalizado = normalizarString(termoBusca);
        if (termoBusca !== termoBuscaNormalizado) {
            setTermoBusca(termoBuscaNormalizado);
        }
        setNewPageIndex(0);
        setPesquisar(true);
    };

    const onEnterPress = (event) => {
        if (event.key === 'Enter') {
            efetuarBusca();
        }
    };

    const iconTipoAssistido = (assistido) => {
        if (assistido.tipo === "A") {
            return <PetsIcon />;
        } else if (assistido.tipo === "P") {
            return <LocalFloristIcon />;
        } else if (assistido.tipo === "H") {
            return <EmojiPeople />;
        } else {
            return <TipoIndefinido />;
        }
    };

    const formatarNome = (assistido) => {
        return assistido.nome ? assistido.nome.toUpperCase() : 'Não informado';
    };

    const renderTable = () => {
        return (
            <Paper className={classes.paper}>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left">Data</TableCell>
                                <TableCell align="left">Nº Controle</TableCell>
                                <TableCell align="left">Nome</TableCell>
                                <TableCell align="left">Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {assistidos.map((assistido, index) => {
                                return (
                                    <TableRow key={assistido.id} hover >
                                        <TableCell align="center">
                                            <CpResumoAssistidoPopover assistido={assistido} >
                                                <SmallBadge color="primary" overlap='rectangular' badgeContent={assistido.semanasRestantesTratamento} invisible={!assistido.tratamentosEmAndamento} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                                                    {iconTipoAssistido(assistido)}
                                                </SmallBadge>
                                            </CpResumoAssistidoPopover>
                                        </TableCell>
                                        <TableCell align="left">
                                            {isoToStrDate(assistido[colunaDataOrdenarPor], 'dd/MM/yy')}
                                        </TableCell>
                                        <TableCell>
                                            {assistido.id}
                                        </TableCell>
                                        <TableCell align="left">
                                            {formatarNome(assistido)}
                                        </TableCell>
                                        <TableCell align="left">
                                            <div style={{ width: '100%' }}>
                                                {assistido.id && <CpAcoesAssistido assistido={assistido} iconSize={'small'} />}
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"
                    count={totalCadastros}
                    rowsPerPage={pageSize}
                    page={pageIndex}
                    backIconButtonProps={{ 'aria-label': 'página anterior' }}
                    nextIconButtonProps={{ 'aria-label': 'próxima página' }}
                    labelRowsPerPage="Assistidos por página"
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangePageSize}
                    hidden={!totalCadastros}
                />
            </Paper>
        )
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={10}>
                        <TextField
                            id="nome"
                            name="nome"
                            label="Digite o número de controle, nome, email ou cpf do assistido"
                            fullWidth
                            value={termoBusca}
                            onChange={onTermoBuscaChange}
                            onKeyDown={onEnterPress}
                            autoFocus
                            InputProps={{
                                autoComplete: 'off',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={2}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={() => efetuarBusca()}
                            startIcon={<SearchIcon />}
                        >
                            Buscar
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4}>
                        <FormControl fullWidth>
                            <InputLabel shrink id="filtroLabel">
                                Pesquisar por
                            </InputLabel>
                            <Select
                                labelId="filtroLabel"
                                id="filtro"
                                value={tipoBusca}
                                onChange={(e) => setTipoBusca(e.target.value)}
                                displayEmpty>
                                <MenuItem value="">
                                    Todos
                                </MenuItem>
                                <MenuItem value={"nome-inteiro"}>
                                    Nome
                                </MenuItem>
                                <MenuItem value={"email-inteiro"}>
                                    Email
                                </MenuItem>
                                <MenuItem value={"nome_protetor-inteiro"}>
                                    Nome do Protetor
                                </MenuItem>
                                <MenuItem value={"nome-parte"}>
                                    %Nome%
                                </MenuItem>
                                <MenuItem value={"email-parte"}>
                                    %Email%
                                </MenuItem>
                                <MenuItem value={"nome_protetor-parte"}>
                                    %Nome do Protetor%
                                </MenuItem>
                                <MenuItem value={"nome-vazio"}>
                                    Sem Nome
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4}>
                        <FormControl fullWidth>
                            <InputLabel shrink id="tipoAssistidoLabel">
                                Tipo assistido
                            </InputLabel>
                            <Select
                                labelId="tipoAssistidoLabel"
                                id="tipoAssistido"
                                value={tipoAssistido}
                                onChange={(e) => setTipoAssistido(e.target.value)}
                                displayEmpty>
                                <MenuItem value="">
                                    Todos
                                </MenuItem>
                                <MenuItem value={"H"}>
                                    <EmojiPeople style={{ fontSize: '18px', verticalAlign: 'middle', margin: '2px 8px 0 0' }} />
                                    Humano
                                </MenuItem>
                                <MenuItem value={"A"}>
                                    <PetsIcon style={{ fontSize: '18px', verticalAlign: 'middle', margin: '2px 8px 0 0' }} />
                                    Animal
                                </MenuItem>
                                <MenuItem value={"P"}>
                                    <LocalFloristIcon style={{ fontSize: '18px', verticalAlign: 'middle', margin: '2px 8px 0 0' }} />
                                    Planta
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4}>
                        <FormControl fullWidth>
                            <InputLabel shrink id="ordenarPor">
                                Ordenar por
                            </InputLabel>
                            <Select
                                labelId="ordenarPorLabel"
                                id="ordenarPor"
                                value={ordenarPor}
                                onChange={(e) => setOrdenarPor(e.target.value)}
                                displayEmpty>
                                <MenuItem value={"id"}>
                                    Assistido Cadastrado
                                </MenuItem>
                                <MenuItem value={"ultima_atualizacao_assistido"}>
                                    Assistido Atualizado
                                </MenuItem>
                                <MenuItem value={"ultima_atualizacao_sintomas"}>
                                    Sintomas Atualizados
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Paper>
            {pesquisar &&
                <Paper className='app-form'>
                    <Typography variant='h5' style={{ display: 'flex', alignContent: 'center' }}>
                        <CircularProgress size={30} /> &nbsp; Carregando assistidos...
                    </Typography>
                </Paper>}
            {!pesquisar && !!assistidos.length && renderTable()}
        </div>
    );
}

export default CpPesquisarAssistidos;
