import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

class CpReportTratamentosEmailOld extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            assistido: props.assistido,
            mostrarTabelaPosicoesTratamento: false,
            mostrarCromoDetalhada: false,
            mostrarCromoSemDetalhes: false,
            mostrarCromoDiariaComDetalhes: false,
            mostrarCromoDiariaSemDetalhes: false,
            mostrarComprimentosHumano: false,
            mostrarComprimentosAnimal: false,
            mostrarComprimentosPlanta: false,
            mostrarDesobsessao: false,
            mostrarEvangelho: false,
            mostrarUmbanda: false,
            mostrarVibracional: false,
            mostrarPsicanalise: false,
            mostrarCTD: false,
            mostrarTerraDeElfos: false,
            mostrarTabagismo: false,
            mostrarReiki: false,
            mostrarKaruna: false,
            mostrarXamanismo: false,
            mostrarTED: false,
            mostrarOutrosTratamentos: false
        }
    }

    componentDidMount() {
        this.definirTipoAssistido();
        this.definirSeTEDSeraExibido();

        let temCromoDetalhada = false;

        if (this.state.assistido.cromoterapias !== null && this.state.assistido.cromoterapias !== undefined && this.state.assistido.cromoterapias.length > 0) {
            for (const cromoterapia of this.state.assistido.cromoterapias) {
                if (cromoterapia.cromoDetalhada === true && cromoterapia.cor !== "sem_detalhes") {
                    temCromoDetalhada = true;
                    this.setState({
                        mostrarCromoDetalhada: true
                    });
                    break;
                } else if (cromoterapia.cromoDetalhada === false || cromoterapia.cor === "sem_detalhes") {
                    this.setState({
                        mostrarCromoSemDetalhes: true
                    });
                }
            }
        }

        if (this.state.assistido !== null && this.state.assistido.tratamentos !== null) {
            if (this.state.assistido.tratamentos.cromoDiaria) {
                this.setState({
                    mostrarCromoDetalhada: false,
                    mostrarCromoSemDetalhes: false
                });
            }

            this.setState({
                mostrarCromoDiariaComDetalhes: this.state.assistido.tratamentos.cromoDiaria && temCromoDetalhada,
                mostrarCromoDiariaSemDetalhes: this.state.assistido.tratamentos.cromoDiaria && !temCromoDetalhada,
                mostrarDesobsessao: this.state.assistido.tratamentos.desobsessao,
                mostrarEvangelho: this.state.assistido.tratamentos.evangelho,
                mostrarUmbanda: this.state.assistido.tratamentos.umbanda,
                mostrarVibracional: this.state.assistido.tratamentos.vibracional,
                mostrarPsicanalise: this.state.assistido.tratamentos.psicanalise,
                mostrarCTD: this.state.assistido.tratamentos.ctd,
                mostrarTerraDeElfos: this.state.assistido.tratamentos.terraDeElfos,
                mostrarTabagismo: this.state.assistido.tratamentos.tabagismo,
                mostrarReiki: this.state.assistido.tratamentos.reiki,
                mostrarKaruna: this.state.assistido.tratamentos.karuna,
                mostrarXamanismo: this.state.assistido.tratamentos.xamanismo,
                mostrarTabelaPosicoesTratamento: !this.state.assistido.tratamentos.preparacaoNaoSeAplica,
                mostrarOutrosTratamentos: this.state.assistido.tratamentos.outrosTratamentos !== "" && this.state.assistido.tratamentos.outrosTratamentos !== null && this.state.assistido.tratamentos.outrosTratamentos !== undefined
            });
        }
    }

    definirTipoAssistido() {
        this.setState({
            mostrarComprimentosHumano: this.state.assistido.tipo === "H",
            mostrarComprimentosAnimal: this.state.assistido.tipo === "A",
            mostrarComprimentosPlanta: this.state.assistido.tipo === "P"
        });
    }

    definirSeTEDSeraExibido() {
        if (this.props.tratamento) {
            this.setState({
                mostrarTED: true
            });
        }
    }

    renderComprimentosHumano() {
        return (
            <React.Fragment>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        Olá, {this.state.assistido.nome}
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        Sua solicitação passou por consulta com a corrente médica da Casa do Consolador que enviou o tratamento a ser realizado, porém antes de detalharmos o que deve ser feito seguem alguns esclarecimentos:
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        <strong>• O tratamento Espiritual não exclui ou substitui o tratamento médico!</strong>
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        <strong>Horário do tratamento:</strong> Considerar o horário de onde você ou a pessoa que irá realizar o tratamento vive/está no momento.
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        <strong>Posição:</strong>
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        <ul>
                            <li>Costas = barriga para cima</li>
                            <li>Bruços = barriga para baixo</li>
                        </ul>
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        <strong>Duração do tratamento:</strong> 12 semanas
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        <strong>Quando iniciar:</strong> Assim que receber o tratamento
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        <strong>Os dias que devo fazer os tratamentos:</strong>
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        <strong>1) Cromoterapia:</strong> Todas as terças, caso tenha sido indicado <strong>cromoterapia</strong> diária deverá ser realizada de segunda à sexta conforme indicada abaixo
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        <strong>2) Preparação: </strong> De segunda à sábado
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="h6" gutterBottom>
                        <strong>Seguem as orientações:</strong>
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        <strong>Controle: {this.state.assistido.id}</strong>
                    </Typography>
                </Grid>

                {this.state.mostrarCromoDetalhada && this.renderCromoComDetalhamento()}
                {this.state.mostrarCromoSemDetalhes && this.renderCromoSemDetalhamento()}
                {this.state.mostrarCromoDiariaSemDetalhes && this.renderCromoDiariaSemDetalhamento()}
                {this.state.mostrarCromoDiariaComDetalhes && this.renderCromoDiariaComDetalhamento()}
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        <strong>PREPARAÇÃO ANTES DE DORMIR:</strong> Coloque um copo com água potável perto de sua cabeceira. Reze 3 “Pai Nosso” e procure conectar-se com a corrente do Dr. Espanhol. Fique na posição indicada, seguindo cada dia, como no quadro abaixo. Na manhã seguinte, em jejum, beba metade do copo de água e à noite, beba o restante. Repita isso, todos os dias.
                    </Typography>
                </Grid>
                {this.state.mostrarTabelaPosicoesTratamento && this.renderTabelaPosicoesTratamento()}
                {this.state.mostrarTED && this.renderTED()}
                {this.renderAguaFluida()}
                {this.state.mostrarDesobsessao && this.renderDesobsessao()}
                {this.state.mostrarEvangelho && this.renderEvangelho()}
                {this.state.mostrarUmbanda && this.renderUmbanda()}
                {this.state.mostrarVibracional && this.renderVibracional()}
                {this.state.mostrarPsicanalise && this.renderPsicanalise()}
                {this.state.mostrarTerraDeElfos && this.renderTerraDeElfos()}
                {this.state.mostrarTabagismo && this.renderTabagismo()}
                {this.state.mostrarReiki && this.renderReiki()}
                {this.state.mostrarKaruna && this.renderKaruna()}
                {this.state.mostrarXamanismo && this.renderXamanismo()}
                {this.state.mostrarOutrosTratamentos && this.renderOutrosTratamentos()}
                {this.renderRodape()}
            </React.Fragment>
        )
    }

    renderRodape() {
        return (
            <React.Fragment>
                <Grid item xs={12} lg={12}>
                <hr></hr>
                    <Typography component="span" variant="body1" gutterBottom>
                        Após 12 semanas do tratamento <strong>(a contar da data da primeira Cromoterapia)</strong> preencha novamente o formulário no site da Casa e nos mande notícias de como estão as coisas.
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        <strong>Bom tratamento e muita luz!</strong>
                    </Typography>
                </Grid>
            </React.Fragment>
        )
    }

    renderOutrosTratamentos() {
        return (
            <React.Fragment>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                    <strong>OUTROS TRATAMENTOS:</strong> {this.state.assistido.tratamentos.outrosTratamentos}
                    </Typography>
                </Grid>
            </React.Fragment>
        )
    }

    renderAguaFluida() {
        return (
            <React.Fragment>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        <strong>ÁGUA FLUIDA:</strong> Coloque uma garrafa/jarra de água filtrada em um local silencioso (sem equipamentos eletrônicos ligados). Reze 3 “Pai Nosso” e chame pela corrente do Dr. Espanhol. Deixe por pelo menos 15 minutos. Esta água não pode ser fervida ou congelada. Não acrescente água à garrafa. Beba em 3 dias, caso contrário, descarte e faça uma nova. A água fluida é especial para cada pessoa.
                    </Typography>
                </Grid>
            </React.Fragment>
        )
    }

    renderTED() {
        return (
            <React.Fragment>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        <strong>TRATAMENTO ESPIRITUAL DIRIGIDO (TED) à CIRURGIA:</strong>
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        <strong>Quando:</strong> Ver tabela abaixo com todas as informações
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        <strong>1 HORA DE DURAÇÃO</strong>
                    </Typography>
                </Grid>
                {this.renderTabelaTED()}
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        <strong>Preparação no dia:</strong> Evite ingerir carnes e bebidas alcoólicas. Use lençóis e roupas de cor clara.
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        <strong>Início da cirurgia:</strong> Próximo da cabeceira, coloque uma garrafa ou um jarro com no mínimo 1,5 litros de água potável, ela estará fluidificada ao final da cirurgia e deverá ser bebida em até 24 horas, (depois desse prazo se ainda sobrar água descarte nas plantas e faça o procedimento que indicaremos mais abaixo para preparar a água fluída). Ao lado da cama, coloque sobre um pano claro, uma bacia com água de torneira. Se possível tenha 2 pessoas vibrando durante o tempo da Cirurgia (podem estar com você ou em lugares diferentes). Caso contrário, mantenha-se tranquilo e com fé. Permaneça em oração durante 1 hora. Reze 3 "Pai Nosso", pedindo a presença da Corrente do Espanhol. Após 1 hora de oração/vibração jogue a água da bacia na pia ou no vaso sanitário, (pode fazer no dia seguinte ao acordar, se dormir durante a cirurgia). Se possível tome apenas água fluída durante todo o tratamento. Evite fazer muito esforço no dia seguinte.
                    </Typography>
                </Grid>
            </React.Fragment>
        )
    }

    renderTabelaTED() {
        return (
            <React.Fragment>
                <Grid item xs={12} lg={12}>
                    <table border={1}>
                        <thead>
                            <tr>
                                <th>DATA DO TED</th>
                                <th align="left">&nbsp;&nbsp;&nbsp;&nbsp;ÓRGÃO&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                <th align="left">&nbsp;&nbsp;&nbsp;&nbsp;POSIÇÃO&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                <th align="left">&nbsp;&nbsp;&nbsp;&nbsp;HORÁRIO&nbsp;&nbsp;&nbsp;&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.assistido.proximosTEDs.map(ted => (
                                <tr key={ted.data} className="fonte-report-cromo">
                                    <td component="th">
                                        {ted.data} &nbsp;&nbsp;&nbsp;&nbsp;
                                    </td>
                                    <td align="left">
                                        {ted.descricao} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </td>
                                    <td align="left">
                                        {ted.preparacao}
                                    </td>
                                    <td align="left">
                                        {ted.horario}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Grid>
            </React.Fragment>
        )
    }

    renderTabelaPosicoesTratamento() {
        let costas = this.state.assistido.tratamentos.preparacaoCostas;
        let brucos = this.state.assistido.tratamentos.preparacaoBrucos;
        let lado = this.state.assistido.tratamentos.preparacaoLado;
        let maisConfortavel = this.state.assistido.tratamentos.preparacaoMaisConfortavel;

        return (
            <React.Fragment>
                <table border={1}>
                    <thead>
                        <tr>
                            <th>&nbsp;&nbsp;&nbsp;2º&nbsp;&nbsp;&nbsp;</th>
                            <th>&nbsp;&nbsp;&nbsp;3º&nbsp;&nbsp;&nbsp;</th>
                            <th>&nbsp;&nbsp;&nbsp;4º&nbsp;&nbsp;&nbsp;</th>
                            <th>&nbsp;&nbsp;&nbsp;5º&nbsp;&nbsp;&nbsp;</th>
                            <th>&nbsp;&nbsp;&nbsp;6º&nbsp;&nbsp;&nbsp;</th>
                            <th>&nbsp;&nbsp;&nbsp;Sábado&nbsp;&nbsp;&nbsp;</th>
                            <th align="left">&nbsp;&nbsp;&nbsp;&nbsp;Posição&nbsp;&nbsp;&nbsp;&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                            {brucos &&
                                <React.Fragment>
                                    <tr key={1} className="fonte-report-cromo">
                                        <td align="center">
                                            X &nbsp;&nbsp;&nbsp;&nbsp;
                                        </td>
                                        <td align="center">
                                            
                                        </td>
                                        <td align="center">
                                            X
                                        </td>
                                        <td align="center">
                                            
                                        </td>
                                        <td align="center">
                                            X
                                        </td>
                                        <td align="center">
                                            
                                        </td>
                                        <td align="center">
                                            Costas
                                        </td>
                                    </tr>
                                    <tr key={2} className="fonte-report-cromo">
                                        <td align="center">
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                        </td>
                                        <td align="center">
                                            X
                                        </td>
                                        <td align="center">
                                            
                                        </td>
                                        <td align="center">
                                            X
                                        </td>
                                        <td align="center">
                                            
                                        </td>
                                        <td align="center">
                                            X
                                        </td>
                                        <td align="center">
                                            Bruços
                                        </td>

                                    </tr>
                                </React.Fragment>
                            }
                            {(costas && !brucos) &&
                                <React.Fragment>
                                    <tr key={1} className="fonte-report-cromo">
                                        <td align="center">
                                            X &nbsp;&nbsp;&nbsp;&nbsp;
                                        </td>
                                        <td align="center">
                                            X
                                        </td>
                                        <td align="center">
                                            X
                                        </td>
                                        <td align="center">
                                            X
                                        </td>
                                        <td align="center">
                                            X
                                        </td>
                                        <td align="center">
                                            X
                                        </td>
                                        <td align="center">
                                            Costas
                                        </td>
                                    </tr>
                                </React.Fragment>
                            }
                            {(lado) &&
                                <React.Fragment>
                                    <tr key={1} className="fonte-report-cromo">
                                        <td align="center">
                                            X &nbsp;&nbsp;&nbsp;&nbsp;
                                        </td>
                                        <td align="center">
                                            X
                                        </td>
                                        <td align="center">
                                            X
                                        </td>
                                        <td align="center">
                                            X
                                        </td>
                                        <td align="center">
                                            X
                                        </td>
                                        <td align="center">
                                            X
                                        </td>
                                        <td align="center">
                                            Lado
                                        </td>
                                    </tr>
                                </React.Fragment>
                            }
                            {(maisConfortavel) &&
                                <React.Fragment>
                                    <tr key={1} className="fonte-report-cromo">
                                        <td align="center">
                                            X &nbsp;&nbsp;&nbsp;&nbsp;
                                        </td>
                                        <td align="center">
                                            X
                                        </td>
                                        <td align="center">
                                            X
                                        </td>
                                        <td align="center">
                                            X
                                        </td>
                                        <td align="center">
                                            X
                                        </td>
                                        <td align="center">
                                            X
                                        </td>
                                        <td align="center">
                                            Mais confortável
                                        </td>
                                    </tr>
                                </React.Fragment>
                            }
                    </tbody>
                </table>
            </React.Fragment>
        )
    }

    renderCromoSemDetalhamento() {
        return (
            <React.Fragment>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        <strong>CROMOTERAPIA : Toda terça-feira, escolha um horário fixo, entre 20hs e 22hs - durante 15 minutos.</strong> Reze 3 "Pai Nosso" e se mantenha em vibração/oração, procure se conectar com a Corrente do Dr. Espanhol. Esteja receptivo ao tratamento espiritual, mantenha-se tranquilo, com Fé. <strong>Período</strong> = fazer <strong>durante 12 semanas</strong>
                    </Typography>
                </Grid>
            </React.Fragment>
        )
    }

    renderCromoDiariaSemDetalhamento() {
        return (
            <React.Fragment>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        <strong>CROMOTERAPIA DIÁRIA: De segunda-feira a sexta-feira entre 20hs e 22hs - durante 15 minutos.</strong> Reze 3 “Pai Nosso” e procure se conectar com a Corrente do Dr. Espanhol. Esteja receptivo ao tratamento espiritual, mantenha-se tranquilo, com fé. <strong>Período</strong> = fazer <strong>durante 12 semanas.</strong>
                    </Typography>
                </Grid>
            </React.Fragment>
        )
    }

    renderCromoDiariaComDetalhamento() {
        return (
            <React.Fragment>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        <strong>CROMOTERAPIA DIÁRIA: De segunda-feira a sexta-feira entre 20hs e 22hs - durante 15 minutos.</strong> Reze 3 “Pai Nosso” e procure se conectar com a Corrente do Dr. Espanhol. Se possível deixe o controle da Cromoterapia (a lista com as cores e locais) próximo a você, para facilitar a aplicação pelos trabalhadores da equipe do Dr. Espanhol. Essas cores e locais são a Jornada de Cromoterapia, ou seja, é o que a equipe irá utilizar para a aplicação, você não precisa mentalizar as cores e locais, procure se conectar com a Corrente do Dr. Espanhol. Esteja receptivo ao tratamento espiritual, mantenha-se tranquilo, com fé. <strong>Período</strong> = fazer <strong>durante 12 semanas</strong>
                    </Typography>
                </Grid>
                {this.renderTabelaCromo()}
            </React.Fragment>
        )
    }

    renderTabelaCromo() {
        return (
            <React.Fragment>
                <Grid item xs={12} lg={12}>
                    <table>
                        <thead>
                            <tr>
                                <th>Nº</th>
                                <th align="left">Cor</th>
                                <th align="left">Local</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.assistido.cromoterapias.map(cromo => (
                                <tr key={cromo.id} className="fonte-report-cromo">
                                    <td component="th">
                                        {cromo.numero} &nbsp;&nbsp;&nbsp;&nbsp;
                                    </td>
                                    <td align="left">
                                        {cromo.cor} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </td>
                                    <td align="left">
                                        {cromo.local}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Grid>
            </React.Fragment>
        )
    }

    renderCromoComDetalhamento() {
        return(
            <React.Fragment>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        <strong>CROMOTERAPIA : Toda terça-feira, escolha um horário fixo entre 20hs e 22hs</strong> e faça uma vibração e oração durante <strong>15 minutos</strong>, seu tratamento será feito à distância. Se possível deixe o controle da Cromoterapia (a lista com as cores e locais) próximo a você, para facilitar a aplicação pelos trabalhadores da equipe do Dr. Espanhol. Essas cores e locais são a Jornada de Cromoterapia, ou seja, é o que a equipe irá utilizar para a aplicação, você não precisa mentalizar as cores e locais, usar roupas nessas cores ou usar lâmpadas, basta se manter em vibração para receber o tratamento. <strong>Período</strong> = fazer <strong>durante 12 semanas</strong>
                    </Typography>
                </Grid>
                {this.renderTabelaCromo()}
            </React.Fragment>
        )
    }

    renderCromoDiaria() {
        return (
            <React.Fragment>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="overline" gutterBottom>
                        <strong>CROMOTERAPIA DIÁRIA</strong>
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="overline" gutterBottom>
                        <strong>De segunda-feira a sexta-feira entre 20hs e 22hs - durante 15 minutos. </strong>
                        Reze 3 “Pai Nosso” e procure se conectar com a Corrente do Dr. Espanhol. 
                        Esteja receptivo ao tratamento espiritual, mantenha-se tranquilo, com fé. 
                        <strong>Período</strong> = fazer <strong>durante {this.state.assistido.tratamentos.quantidadeCromos} semanas</strong>.
                    </Typography>
                </Grid>
            </React.Fragment>
        )
    }

    renderDesobsessao() {
        return (
            <React.Fragment>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        <strong>DESOBSESSÃO:</strong> Sempre no <strong>1º</strong> e <strong>3º</strong> sábado de cada mês. Realizar uma oração às <strong>14hs pontualmente</strong> para entrar em sintonia com os trabalhos. Caso tenha dúvida sobre as datas, confira no calendário do site da Casa do Consolador. Esse tratamento é feito sempre em 7 sessões. 
                    </Typography>
                </Grid>
            </React.Fragment>
        )
    }

    renderEvangelho() {
        return (
            <React.Fragment>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        <strong>EVANGELHO NO LAR: Quando: </strong> Uma vez por semana, sempre no mesmo dia e horário da semana. Coloque 1 toalha branca na mesa e 1 garrafa com água. Faça uma oração em sincera sintonia com Nosso Senhor Jesus Cristo e leia uma passagem da Bíblia, do Evangelho, ou de um livro espírita. Ao final, comente fazendo uma reflexão sobre o que leu. Faça uma vibração seguida de oração de agradecimento e encerramento. Beba a água ao terminar.
                    </Typography>
                </Grid>
            </React.Fragment>
        )
    }

    renderUmbanda() {
        return (
            <React.Fragment>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        <strong>UMBANDA:</strong> Passaremos seu caso com os Guias da Umbanda da Casa e entraremos em contato novamente caso haja novas orientações.
                    </Typography>
                </Grid>
            </React.Fragment>
        )
    }

    renderVibracional() {
        return (
            <React.Fragment>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        <strong>VIBRAÇÃO:</strong> Colocamos seu nome em vibração para ajudar em seu fortalecimento. Ele ficará com os mentores por 3 meses.
                    </Typography>
                </Grid>
            </React.Fragment>
        )
    }

    renderPsicanalise() {
        return (
            <React.Fragment>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        <strong>PSICANÁLISE: </strong> Dr. Espanhol indica tratamento de Psicanálise, esse atendimento não faz parte do tratamento da Casa do Consolador, é uma orientação. Indicamos a Psicanalista Margarete Àquela, caso tenha interesse em passar em consulta com ela entre em contato através do e-mail margareteaquila@hotmail.com.
                    </Typography>
                </Grid>
            </React.Fragment>
        )
    }

    renderCTD() {
        return (
            <React.Fragment>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="overline" gutterBottom>
                        <strong>CTD</strong>
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="overline" gutterBottom>
                    </Typography>
                </Grid>
            </React.Fragment>
        )
    }

    renderTerraDeElfos() {
        return (
            <React.Fragment>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        <strong>TERRA DE ELFOS: </strong> Foi indicado que participe de um tratamento chamado <strong>"Terra dos Elfos"</strong> Por gentileza, fazer inscrição no site da Casa www.casadoconsolador.com.br . No rodapé, na aba de inscrições, ir em inscrições Projeto Terra de Elfos.
                    </Typography>
                </Grid>
            </React.Fragment>
        )
    }

    renderTabagismo() {
        return (
            <React.Fragment>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        <strong>TABAGISMO: </strong> <strong>Toda terça-feira</strong> defina um horário fixo <strong>entre 20h e 22h – durante 15 minutos</strong>. Reze 3 "Pai Nosso" e fique em oração/vibração para receber o tratamento espiritual.
                    </Typography>
                </Grid>
            </React.Fragment>
        )
    }

    renderReiki() {
        return (
            <React.Fragment>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        <strong>REIKI: </strong> Um tratamento que busca equilibrar as energias e emoções. <strong>Toda segunda-feira</strong> defina um horário fixo entre <strong>20hs e 22hs - durante 15 minutos</strong>. Reze 3 "Pai Nosso" e procure se conectar com os Mestres da Casa do Consolador. Esteja receptivo ao tratamento espiritual, mantenha-se tranquilo, com fé.
                    </Typography>
                </Grid>
            </React.Fragment>
        )
    }

    renderKaruna() {
        return (
            <React.Fragment>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        <strong>KARUNA:</strong> Um tratamento que busca equilibrar as energias e emoções. <strong>Toda segunda-feira</strong> defina um horário fixo entre <strong>20hs e 22hs - durante 15 minutos</strong>. Reze 3 "Pai Nosso" e procure se conectar com os Mestres da Casa do Consolador. Esteja receptivo ao tratamento espiritual, mantenha-se tranquilo, com fé.
                    </Typography>
                </Grid>
            </React.Fragment>
        )
    }

    renderXamanismo() {
        return (
            <React.Fragment>
                <Grid item xs={12} lg={12}>
                    <Typography component="span" variant="body1" gutterBottom>
                        <strong>XAMANISMO: </strong> Um tratamento para auxiliar no equilíbrio emocional / mental / espiritual. Todas as quartas feiras, entre 20 e 22 horas, conecte-se com seu Eu Superior e Sua Presença Divina, fazendo orações que lhe toquem o coração, procurando estar em comunhão com os Xamãs da Casa do Consolador. Esteja receptivo (a) ao tratamento, mantendo-se tranquilo (a) e com Fé.
                    </Typography>
                </Grid>
            </React.Fragment>
        )
    }

    render() {
        return (
            <React.Fragment>
                <Grid container spacing={1}>
                    {this.state.mostrarComprimentosHumano && this.renderComprimentosHumano()}
                </Grid>
            </React.Fragment>
        )
    }
}


export default CpReportTratamentosEmailOld;
