import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CpEditarTratamentos from '../../components/cp-tratamentos/cp-editar-tratamentos';
import TratamentoService from '../../services/tratamentos';

const PgEditarTratamento = () => {
    const { id } = useParams();
    const [tratamento, setTratamento] = useState(null);
    const [assistidoId, setAssistidoId] = useState(null);
    const service = new TratamentoService();

    useEffect(() => {
        if (id) {
            service.procurarPorId(id)
                .then(res => {
                    console.log(res);
                    setTratamento(res);
                    setAssistidoId(res.assistidoId);
                }).catch(err => {
                    console.log("Erro ao carregar tratamento", err);
                });
        }
    }, [id]);

    if (!assistidoId) {
        return <h2>Carregando...</h2>;
    }

    return (
        <>
            <Paper className="acoes" style={{ padding: '10px 20px', margin: '10px 0', backgroundColor: '', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h4'>Editar Tratamento</Typography>
            </Paper>
            <Paper className="app-form">
                <Grid container spacing={1}>
                    <CpEditarTratamentos tratamento={tratamento} assistidoId={assistidoId} />
                </Grid>
            </Paper>
        </>
    );
}

export default withSnackbar(PgEditarTratamento);