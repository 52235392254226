import api, { URL_CIRURGIAS } from "../api_env_config";

class CirurgiaService {

    deletarCirurgias = async (ids) => {
        return new Promise((resolve, reject) => {
            const url = `${URL_CIRURGIAS}`;
            // Convertendo o array de IDs em uma string separada por vírgulas
            const params = { ids: ids.join(',') };

            api.delete(url, { params: params })
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(err => {
                    reject("Error: " + err);
                });
        });
    };

    editarCirurgia = async (id, cirurgia) => {
        return new Promise((resolve, reject) => {
            const url = `${URL_CIRURGIAS}/${id}`;
            api.put(url, cirurgia).then(response => {
                resolve(response.data.data);
            })
                .catch(err => {
                    reject("Error: " + err);
                })
        })
    };

    recuperarCirurgiaId = async (idCirurgia) => {
        return new Promise((resolve, reject) => {
            const url = `${URL_CIRURGIAS}/${idCirurgia}`;
            api.get(url).then(response => {
                resolve(response.data.data);
            })
                .catch(err => {
                    reject("Error: " + err);
                })
        })
    };

    obterProximaData = async (tipoAssistido, local) => {
        return new Promise((resolve, reject) => {
            const url = `${URL_CIRURGIAS}/agendamentos/proximos?t=${encodeURIComponent(tipoAssistido)}&l=${encodeURIComponent(local)}`;
            api.get(url).then(response => {
                resolve(response.data.data);
            }).catch(err => {
                reject("Error: " + err);
            })
        })
    };

    recuperarQuantidadeAgendamentos = async (datetime) => {
        return new Promise((resolve, reject) => {
            const url = `${URL_CIRURGIAS}/agendamentos/${datetime}`;
            api.get(url).then(response => {
                console.log('recuperarQuantidadeAgendamentos', { datetime, response: response.data });
                resolve(response.data.data.agendamentos);
            })
                .catch(err => {
                    reject("Error: " + err);
                })
        })
    };


    adicionarCirurgias = async (cirurgias, auto) => {
        return new Promise((resolve, reject) => {
            api.post(`${URL_CIRURGIAS}`, {
                cirurgias,
                auto
            }).then(response => {
                resolve(response.data.data);
            }).catch(err => {
                reject("Error: " + err)
            });
        });
    }

    obterRelatorioCirurgias = async (data) => {
        return new Promise((resolve, reject) => {
            const url = `${URL_CIRURGIAS}/relatorio`;
            api.get(url, { params: { data } })
                .then(response => {
                    resolve(response.data);
                })
                .catch(err => {
                    reject("Error: " + err);
                });
        });
    };

}

export default CirurgiaService;
