import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/DeleteSweepOutlined';
import { useSnackbar } from 'notistack';
import React, { useState } from "react";

import CirurgiaService from '../../services/cirurgias';
import CpDeletarCirurgiaAlert from './cp-deletar-cirurgia';
import CpRowCirurgia from './cp-row-cirurgia';
import { Typography } from '@material-ui/core';
import { isoDateToStrDate } from '../../services/date-utils';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    checkboxCell: {
        width: '50px',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(2),
    },
    button: {
        marginLeft: theme.spacing(1),
    },
}));

export default function CpListaCirurgias(props) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [selecionados, setSelecionados] = useState([]);
    const [abrirModalDeletar, setAbrirModalDeletar] = useState(false);
    const [cirurgiasParaDeletar, setCirurgiasParaDeletar] = useState([]);

    const cirurgiaService = new CirurgiaService();

    const toggleSelecionado = (cirurgia) => {
        if (selecionados.some((selectedCirurgia) => selectedCirurgia.id === cirurgia.id)) {
            setSelecionados(selecionados.filter((selectedCirurgia) => selectedCirurgia.id !== cirurgia.id));
        } else {
            setSelecionados([...selecionados, cirurgia]);
        }
    };

    const isSelecionado = (cirurgia) => selecionados.some((selectedCirurgia) => selectedCirurgia.id === cirurgia.id);

    const selecionarTodos = (event) => {
        if (event.target.checked) {
            setSelecionados([...props.cirurgias]);
        } else {
            setSelecionados([]);
        }
    };

    const onDeletarSelecionados = () => {
        setCirurgiasParaDeletar(selecionados);
        setAbrirModalDeletar(true);
    };

    const onDeletarCirurgiaClick = (cirurgia) => {
        setCirurgiasParaDeletar([cirurgia]);
        setAbrirModalDeletar(true);
    }

    const onConfirmarDeletarCirurgia = () => {
        cirurgiaService.deletarCirurgias(cirurgiasParaDeletar.map(c => c.id))
            .then(res => {
                let mensagemSucesso = `As ${cirurgiasParaDeletar.length} cirurgias selecionadas foram deletadas com sucesso!`;
                if (cirurgiasParaDeletar.length === 1) {
                    const cirurgiaDeletada = cirurgiasParaDeletar[0];
                    const horaMinuto = cirurgiaDeletada.horarioAgendado.split(":").slice(0, 2).join(":");
                    mensagemSucesso = `A cirurgia ${cirurgiaDeletada.descricao} agendada para ${isoDateToStrDate(cirurgiaDeletada.dataAgendada)} ${horaMinuto}h foi removida com sucesso`;
                }
                enqueueSnackbar(mensagemSucesso, {
                    variant: 'success',
                });
                setCirurgiasParaDeletar([]);
                setSelecionados([]);
                setAbrirModalDeletar(false);
                props.onDeletarCirurgia(res);
            }).catch(err => {
                console.log('onConfirmarDeletarCirurgia', err);
                const horaMinuto = cirurgiasParaDeletar.horarioAgendado.split(":").slice(0, 2).join(":");
                enqueueSnackbar(`Ocorreu um erro ao remover a cirurgia ${cirurgiasParaDeletar.descricao} agendada para ${cirurgiasParaDeletar.dataAgendada} ${horaMinuto}h`, {
                    variant: 'error',
                });
                setCirurgiasParaDeletar([]);
                setAbrirModalDeletar(false);
            });
    }

    const fecharModalDeletar = () => {
        setSelecionados([]);
        setCirurgiasParaDeletar([]);
        setAbrirModalDeletar(false);
    }

    if (!props.cirurgias?.length) {
        return <Typography variant='subtitle2' >Nenhuma cirurgia encontrada. Adicione novas cirurgias para vê-las aqui</Typography>;
    }

    return (
        <div className={classes.root}>
            <CpDeletarCirurgiaAlert
                abrirModalDeletar={abrirModalDeletar}
                onConfirmarDeletarCirurgia={onConfirmarDeletarCirurgia}
                fecharModalDeletar={fecharModalDeletar}
                cirurgiasParaDeletar={cirurgiasParaDeletar} />
            <Typography variant='subtitle2' paragraph>Histórico de Cirurgias</Typography>
            <Paper elevation={3}>
                <div className={classes.buttonContainer}>
                    <Button
                        id="deletar-selecionados"
                        variant="contained"
                        color="secondary"
                        startIcon={<DeleteIcon />}
                        onClick={onDeletarSelecionados}
                        className={classes.button}
                        disabled={selecionados.length === 0}
                    >
                        Deletar cirurgias selecionadas
                    </Button>
                </div>
                <Table className={classes.table} size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.checkboxCell}>
                                <Checkbox
                                    indeterminate={!!selecionados.length > 0 && selecionados.length < props.cirurgias.length}
                                    checked={!!selecionados.length && selecionados.length === props.cirurgias.length}
                                    onChange={selecionarTodos}
                                />
                            </TableCell>
                            <TableCell>Data</TableCell>
                            <TableCell>Horário</TableCell>
                            <TableCell>Local</TableCell>
                            <TableCell>Descrição</TableCell>
                            <TableCell>Posição</TableCell>
                            <TableCell>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.cirurgias.map(cirurgia => (
                            <CpRowCirurgia
                                key={cirurgia.id}
                                cirurgia={cirurgia}
                                selecionado={isSelecionado(cirurgia)}
                                toggleSelecionado={toggleSelecionado}
                                onDeletarCirurgiaClick={onDeletarCirurgiaClick}
                            />
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </div>
    );
}
