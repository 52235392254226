

import api, { URL_CONFIG_CIRURGIAS } from "../api_env_config";

class ConfiguracaoCirurgiasService {

    obterConfiguracoes = async () => {
        return new Promise((resolve, reject) => {
            const url = `${URL_CONFIG_CIRURGIAS}`;
            api.get(url).then(response => {
                resolve(response.data.data);
            }).catch(err => {
                reject("Error: " + err);
            });
        });
    };

    salvarConfiguracao = async (config) => {
        return new Promise((resolve, reject) => {
            const url = `${URL_CONFIG_CIRURGIAS}`;
            api.post(url, config).then(response => {
                resolve(response.data.data);
            })
            .catch(err => {
                reject("Error: " + err);
            });
        });
    };

    deletarConfiguracao = async (id) => {
        return new Promise((resolve, reject) => {
            const url = `${URL_CONFIG_CIRURGIAS}/${id}`;
            api.delete(url).then(response => {
                resolve(response.data.data);
            })
            .catch(err => {
                reject("Error: " + err);
            });
        });
    };

}

export default ConfiguracaoCirurgiasService;
