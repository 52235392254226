import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PeopleIcon from '@material-ui/icons/People';
import React, { useEffect, useState } from 'react';
import CpPesquisarAssistidos from '../../components/assistidos/cp-pesquisar-assistidos';
import AssistidoService from '../../services/assistidos';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    pageHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    tableHeader: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    bigicon: {
        marginRight: theme.spacing(1),
        fontSize: '2.5rem',
    },
}));

const PgHome = () => {
    const classes = useStyles();
    const [quantidadeAssistidos, setQuantidadeAssistidos] = useState(0);
    const service = new AssistidoService();

    useEffect(() => {
        // Fetch the number of registered assistidos on component mount
        service.contagemAssistidos()
            .then(res => {
                setQuantidadeAssistidos(res.total);
            });
    }, []);

    const formattedQuantidadeAssistidos = quantidadeAssistidos.toLocaleString('pt-BR');

    return (
        <main>
            <CssBaseline />
            <Paper className={classes.paper}>
                <div className={classes.pageHeader}>
                    <PeopleIcon color="primary" className={classes.bigicon} />
                    <Typography variant="h4">
                        {quantidadeAssistidos ? `${formattedQuantidadeAssistidos} Assistidos Cadastrados` : <CircularProgress/>}
                    </Typography>
                </div>
            </Paper>
            <CpPesquisarAssistidos pesquisarAoCarregar={true} />
        </main>
    );
}

export default PgHome;
