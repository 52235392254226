
export const TipoAssistido = {
    HUMANO: { desc: 'Humano', dbVal: 'H' },
    ANIMAL: { desc: 'Animal', dbVal: 'A' },
    PLANTA: { desc: 'Planta', dbVal: 'P' },
    porDbVal: function (dbVal) {
        return Object.values(this).find(e => e.dbVal === dbVal);
    }
};

export const Sexo = {
    MASCULINO: { desc: 'Masculino', dbVal: 'M' },
    FEMININO: { desc: 'Feminino', dbVal: 'F' },
    porDbVal: function (dbVal) {
        return Object.values(this).find(e => e.dbVal === dbVal);
    },
};

export const Origem = {
    PCD: { desc: 'PCD', dbVal: 'P' },
    INTERNET: { desc: 'Internet', dbVal: 'I' },
    porDbVal: function (dbVal) {
        return Object.values(this).find(e => e.dbVal === dbVal);
    }
};

export const Local = {
    CONSOLADOR: { shortDesc:'C.D.C.', desc: 'Casa do Consolador', boolVal: true, dbVal: 'CONSOLADOR' },
    ASSISTIDO: { shortDesc:'No Lar', desc: 'No Lar', boolVal: false, dbVal: 'ASSISTIDO' },
    porBoolVal: function (boolVal) {
        return Object.values(this).find(e => e.boolVal === boolVal);
    },
    porDbVal: function (dbVal) {
        return Object.values(this).find(e => e.dbVal === dbVal);
    }
};

export const Posicao = {
    COSTAS: "Costas",
    BRUCOS: "Bruços",
    LADO: "Lado",
    MAIS_CONFORTAVEL: "Mais confortável",
    ofCirurgia: function (cirurgia) {
        return this.of(cirurgia.preparacaoLado, cirurgia.preparacaoCostas, cirurgia.preparacaoBrucos, cirurgia.preparacaoMaisConfortavel);
    },
    getListaStr: function (cirurgia) {
        let selecionados = [];
        if (cirurgia.preparacaoLado) {
            selecionados.push(this.LADO);
        }
        if (cirurgia.preparacaoCostas) {
            selecionados.push(this.COSTAS);
        }
        if (cirurgia.preparacaoBrucos) {
            selecionados.push(this.BRUCOS);
        }
        if (cirurgia.preparacaoMaisConfortavel) {
            selecionados.push(this.MAIS_CONFORTAVEL);
        }

        return selecionados.join(', ');
    },
    of: function (posicaoLado, posicaoCostas, posicaoBrucos, posicaoConfortavel) {
        const quantidadeDeSelecionados = [posicaoLado, posicaoCostas, posicaoBrucos, posicaoConfortavel].filter(Boolean).length;

        if (quantidadeDeSelecionados === 1) {
            if (posicaoLado) return this.LADO;
            if (posicaoCostas) return this.COSTAS;
            if (posicaoBrucos) return this.BRUCOS;
        }

        // Retorna posicao mais confortavel se mais de uma opcao selecionada ou ela mesma
        return this.MAIS_CONFORTAVEL;
    }
};

