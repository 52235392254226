import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    Paper,
    Select,
    Typography,
    FormControl,
    InputLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import AssistidoService from '../../services/assistidos';
import { Local, Origem, Sexo, TipoAssistido } from './enums';

const useStyles = makeStyles((theme) => ({
    bold: {
        fontWeight: theme.typography.fontWeightBold,
    },
    paper: {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '200px',
    },
    selectLabel: {
        marginBottom: theme.spacing(1),
    },
}));

const CpHistoricoAssistido = ({ assistidoId, onClose }) => {
    const classes = useStyles();
    const [aberto, setAberto] = useState(true);
    const [historicos, setHistoricos] = useState(null);
    const [selectedData, setSelectedData] = useState('');
    const [selectedField, setSelectedField] = useState('');
    const [loading, setLoading] = useState(true);
    const [viewMode, setViewMode] = useState('data'); // 'data' ou 'campo'

    const labels = {
        nome: 'Nome',
        cep: 'CEP',
        cidade: 'Cidade',
        cpf: 'CPF',
        data_nascimento: 'Data de Nascimento',
        email: 'Email',
        endereco: 'Endereço',
        estado: 'Estado',
        nome_protetor: 'Nome do Protetor',
        origem: 'Origem',
        sexo: 'Sexo',
        telefone: 'Telefone',
        tipo: 'Tipo de Assistido',
    };

    const handleChangeData = (event) => {
        setSelectedData(event.target.value);
    };

    const handleChangeField = (event) => {
        setSelectedField(event.target.value);
    };

    const handleChangeViewMode = (event) => {
        setViewMode(event.target.value);
    };

    const fechar = () => {
        setAberto(false);
        if (onClose) {
            onClose();
        }
    };

    useEffect(() => {
        const service = new AssistidoService();

        service
            .listarHistoricoCadastroAssistido(assistidoId)
            .then((data) => {
                setHistoricos(data);
                if (data.length > 0) {
                    setSelectedData(data[0].data);
                }
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [assistidoId]);

    const formatEvento = (evento) => {
        return evento === 'criar' ? 'Assistido criado' : 'Assistido editado';
    };

    const formatField = (key, val) => {
        if (!val) {
            return '';
        }
        if (key === 'cpf') {
            const cpf = val.replace(/\D/g, '');
            return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        } else if (key === 'data_nascimento') {
            const [year, month, day] = val.split('-');
            return `${day}/${month}/${year}`;
        } else if (key === 'origem') {
            return Origem.porDbVal(val).desc;
        } else if (key === 'sexo') {
            return Sexo.porDbVal(val).desc;
        } else if (key === 'tipo') {
            return TipoAssistido.porDbVal(val).desc;
        } else {
            return val;
        }
    };

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    };

    const getFieldHistory = () => {
        return historicos
            .filter((item) => item[selectedField])
            .map((item, index) => ({
                timestamp: item.data,
                user: item.usuario,
                value: formatField(selectedField, item[selectedField]),
                event: formatEvento(item.evento),
            }));
    };

    const getFieldsWithChanges = () => {
        const fieldsWithChanges = new Set();
        historicos.forEach((item) => {
            Object.keys(item).forEach((key) => {
                if (labels[key] && item[key]) {
                    fieldsWithChanges.add(key);
                }
            });
        });
        return Array.from(fieldsWithChanges);
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={aberto}
            onClose={fechar}
            aria-labelledby="responsive-dialog-title"
            PaperProps={{
                style: { minHeight: '600px' },
            }}
        >
            <DialogTitle id="responsive-dialog-title">
                Histórico do Cadastro do Assistido
            </DialogTitle>
            <DialogContent>
                {loading ? (
                    <div className={classes.loading}>
                        <CircularProgress />
                    </div>
                ) : historicos && historicos.length > 0 ? (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth className={classes.selectLabel}>
                                <InputLabel id="view-mode-select-label">Visualizar por</InputLabel>
                                <Select
                                    labelId="view-mode-select-label"
                                    id="select-view-mode"
                                    value={viewMode}
                                    onChange={handleChangeViewMode}
                                >
                                    <MenuItem value="data">Evento</MenuItem>
                                    <MenuItem value="campo">Campo</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {viewMode === 'data' && (
                            <>
                                <Grid item xs={12}>
                                    <Select
                                        id="select-historico"
                                        value={selectedData}
                                        onChange={handleChangeData}
                                        labelId="historicoLabel"
                                        fullWidth
                                    >
                                        {historicos.map((item, index) => (
                                            <MenuItem key={index} value={item.data}>
                                                {`${formatTimestamp(item.data)} - ${formatEvento(item.evento)}`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                {historicos
                                    .filter((item) => item.data === selectedData)
                                    .map((item, index) => (
                                        <Grid item xs={12} key={index}>
                                            <Paper className={classes.paper}>
                                                <Typography variant="subtitle1" className={classes.bold}>
                                                    Usuário Responsável
                                                </Typography>
                                                <Typography variant="body1">
                                                    <strong>Email:&nbsp;</strong>
                                                    {item.usuario}
                                                </Typography>
                                            </Paper>
                                            <Paper className={classes.paper}>
                                                <Typography variant="subtitle1" className={classes.bold}>
                                                    {item.evento === 'criar'
                                                        ? 'Dados do Cadastro'
                                                        : 'Dados Alterados'}
                                                </Typography>
                                                {Object.entries(item).map(
                                                    ([key, value]) =>
                                                        labels[key] &&
                                                        value && (
                                                            <Typography variant="body1" key={key}>
                                                                <strong>
                                                                    {labels[key]}:&nbsp;
                                                                </strong>
                                                                {formatField(key, value)}
                                                            </Typography>
                                                        )
                                                )}
                                            </Paper>
                                        </Grid>
                                    ))}
                            </>
                        )}
                        {viewMode === 'campo' && (
                            <>
                                <Grid item xs={12}>
                                    <FormControl fullWidth className={classes.selectLabel}>
                                        <InputLabel id="field-select-label">Campo</InputLabel>
                                        <Select
                                            labelId="field-select-label"
                                            id="select-field"
                                            value={selectedField}
                                            onChange={handleChangeField}
                                        >
                                            {getFieldsWithChanges().map((key) => (
                                                <MenuItem key={key} value={key}>
                                                    {labels[key]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {selectedField && (
                                    <Grid item xs={12}>
                                        {getFieldHistory().map((item, index) => (
                                            <Paper key={index} className={classes.paper}>
                                                <Typography variant="body1">
                                                    <strong>{formatTimestamp(item.timestamp)}</strong> -{' '}
                                                    {item.event} por {item.user}
                                                </Typography>
                                                <Typography variant="body1">
                                                    {item.value}
                                                </Typography>
                                            </Paper>
                                        ))}
                                    </Grid>
                                )}
                            </>
                        )}
                    </Grid>
                ) : (
                    <Typography variant="body1">
                        Não há histórico disponível para este assistido.
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={fechar} color="primary" autoFocus>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CpHistoricoAssistido;
