import React from "react";
import { Redirect, Route } from "react-router-dom";
import PgAssistido from '../pages/pg-assistidos/pg-assistidos';
import PgEditarCadastroInternet from '../pages/pg-cadastros-internet/pg-editar-cadastros-internet';
import PgListarCadastrosInternet from '../pages/pg-cadastros-internet/pg-listar-cadastros-internet';
import PgCirurgias from '../pages/pg-cirurgias/pg-cirurgias';
import PgEditarCirurgia from '../pages/pg-cirurgias/pg-editar-cirurgia';
import pgRelatorioCirurgias from "../pages/pg-cirurgias/pg-relatorio-cirurgias";
import PgConfigCirurgia from '../pages/pg-configuracao-cirurgias/pg-configuracao-cirurgias';
import PgEditarConfiguracaoCirurgias from "../pages/pg-configuracao-cirurgias/pg-editar-configuracao-cirurgias";
import PgEditarFeriados from "../pages/pg-feriados/pg-editar-feriado";
import PgFeriados from "../pages/pg-feriados/pg-feriados";
import PgHome from '../pages/pg-home/pg-home';
import PesquisarAssistidos from '../pages/pg-pesquisar-assistidos/pg-pesquisar-assistidos';
import pgRelatorioEmail from "../pages/pg-relatorios/pg-relatorio-email";
import PgRelatorioPresencial from "../pages/pg-relatorios/pg-relatorio-presencial";
import PgEditarSintoma from "../pages/pg-sintomas/pg-editar-sintoma";
import PgSintomas from '../pages/pg-sintomas/pg-sintomas';
import PgEditarTratamentos from "../pages/pg-tratamentos/pg-editar-tratamentos";
import pgRelatorioVibracionalLote from "../pages/pg-tratamentos/pg-relatorio-vibracional-lote";
import PgTratamentos from '../pages/pg-tratamentos/pg-tratamentos';

const routes_privates = [
    {
        path: "/",
        component: PgHome,
    },
    {
        path: "/assistidos",
        component: PgAssistido,
    },
    {
        path: "/assistidos/:id",
        component: PgAssistido,
    },
    {
        path: "/assistidos/:id/cirurgias",
        component: PgCirurgias,
    },
    {
        path: "/editarCirurgia/:id",
        component: PgEditarCirurgia,
    },
    {
        path: "/assistidos/:id/tratamentos",
        component: PgTratamentos,
    },
    {
        path: "/tratamento/:id",
        component: PgEditarTratamentos,
    },
    {
        path: "/assistidos/:id/sintomas",
        component: PgSintomas,
    },
    {
        path: "/editarSintoma/:id",
        component: PgEditarSintoma,
    },
    {
        path: "/assistidos/:id/relatorio-presencial",
        component: PgRelatorioPresencial,
    },
    {
        path: "/assistidos/:id/relatorio-email",
        component: pgRelatorioEmail,
    },
    {
        path: "/cirurgias",
        component: PgCirurgias,
    },
    {
        path: "/relatorio/cirurgias",
        component: pgRelatorioCirurgias,
    },
    {
        path: "/relatorio/vibracionais",
        component: pgRelatorioVibracionalLote,
    },
    {
        path: "/configcirurgias",
        component: PgConfigCirurgia,
    },
    {
        path: "/configcirurgias/:id",
        component: PgEditarConfiguracaoCirurgias,
    },
    {
        path: "/feriados",
        component: PgFeriados,
    },
    {
        path: "/feriados/:id",
        component: PgEditarFeriados,
    },
    {
        path: "/pesquisar-assistido",
        component: PesquisarAssistidos,
    },
    {
        path: "/sintomas",
        component: PgSintomas,
    },
    {
        path: "/tratamentos",
        component: PgTratamentos,
    },
    {
        path: "/cadastros-internet",
        component: PgListarCadastrosInternet,
    },
    {
        path: "/cadastros-internet/:id",
        component: PgEditarCadastroInternet,
    },
];


const authenticated = localStorage.getItem("access_token");


const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            authenticated ? <Component {...props} /> : <Redirect to="/login" />
        }
    />
);


const Routes = () =>
    routes_privates.map((item) => (
        <PrivateRoute
            exact
            key={item.path}
            path={item.path}
            component={item.component}
        />
    )
    );

export default Routes;
