import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import EmailIcon from '@material-ui/icons/Email';
import HomeIcon from '@material-ui/icons/Home';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import NoteIcon from '@material-ui/icons/Note';
import PermScanWifiIcon from '@material-ui/icons/PermScanWifi';
import PersonIcon from '@material-ui/icons/Person';
import PetsIcon from '@material-ui/icons/Pets';
import RoomIcon from '@material-ui/icons/Room';
import SaveIcon from '@material-ui/icons/Save';
import WcIcon from '@material-ui/icons/Wc';
import React, { useState } from 'react';

const AjudaNovoAssistido = ({ onClose }) => {
    const [aberto, setAberto] = useState(true);

    const fechar = () => {
        setAberto(false);
        if (onClose) {
            onClose();
        }
    };

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={'lg'}
                open={aberto}
                onClose={fechar}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    <LiveHelpIcon />
                    {" Atalhos Assistido"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    Página para adicionar e editar assistidos.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="h6" align="center">
                                    Atalhos para salvar o assistido
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <SaveIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary="pagedown"
                                            secondary="Salvar o assistido e adicionar um novo assistido" />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={6}>
                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <SaveIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary="pageup"
                                            secondary="Salvar o assistido e ir para o cadastro de cirurgias" />
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="h6" align="center">
                                    Atalhos para navegar nos campos (letras maiúsculas e minúsculas)
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <PersonIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary="alt+n"
                                            secondary="Foco no campo Nome" />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={3}>
                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <WcIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary="alt+m"
                                            secondary="Marcar como sexo masculino" />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={3}>
                                <List>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <WcIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary="alt+f"
                                        secondary="Marcar como sexo feminino" />
                                </List>
                            </Grid>
                            <Grid item xs={3}>
                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <HomeIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary="alt+e"
                                            secondary="Foco no campo Endereço" />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={3}>
                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <RoomIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary="alt+t"
                                            secondary="Foco no campo Estado" />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={3}>
                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <RoomIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary="alt+c"
                                            secondary="Foco no campo Cidade" />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={3}>
                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <RoomIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary="alt+p"
                                            secondary="Foco no campo CEP" />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={3}>
                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <EmailIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary="alt+l"
                                            secondary="Foco no campo E-mail" />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={3}>
                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <PermScanWifiIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary="alt+i"
                                            secondary="Marcar como origem internet" />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={3}>
                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <NoteIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary="alt+v"
                                            secondary="Marcar como origem PCD" />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={3}>
                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <PetsIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary="alt+a"
                                            secondary="Marcar como tipo Animal" />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={3}>
                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <PetsIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary="alt+h"
                                            secondary="Marcar como tipo Humano" />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={3}>
                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <PetsIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary="alt+w"
                                            secondary="Marcar como tipo planta" />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={3}>
                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <HomeIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary="alt+q"
                                            secondary="Marcar como local da cirurgia preferido Casa do Consolador" />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={3}>
                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <LocalHospitalIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary="alt+s"
                                            secondary="Foco no campo Sintomas (Se for inclusão)" />
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={fechar} color="primary" autoFocus>
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default AjudaNovoAssistido;
