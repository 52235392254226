import { withSnackbar } from 'notistack';
import React from 'react';
import CpRelatorioVibracionalLote from '../../components/cp-tratamentos/cp-relatorio-vibracional-lote';

const PgRelatorioVibracionalLote = ({ }) => {

    return (
        <div className="App">
            <h1>Relatório de Vibracionais</h1>
            <CpRelatorioVibracionalLote />
        </div>
    );
}

export default withSnackbar(PgRelatorioVibracionalLote);
