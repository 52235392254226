import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import TipoAssistidoHumanoIcon from '@material-ui/icons/EmojiPeople';
import TipoAssistidoPlantaIcon from '@material-ui/icons/LocalFlorist';
import TipoAssistidoAnimalIcon from '@material-ui/icons/Pets';
import SaveIcon from '@material-ui/icons/Save';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ConfiguracoesCirurgiaService from '../../services/configuracao_cirurgia/index';
import { strTimeToIsoTime, isoTimeToStrTime } from '../../services/date-utils';
import { Local, TipoAssistido } from '../../components/assistidos/enums';

const estadoInicial = {
    id: null,
    tipoAssistido: '',
    local: '',
    diaDaSemana: '',
    horaInicio: '',
    horaFim: '',
    quantidadeIntervalo: '',
    duracaoIntervalo: 15,
};

const diasDaSemana = [
    { value: 0, label: 'Domingo' },
    { value: 1, label: 'Segunda-feira' },
    { value: 2, label: 'Terça-feira' },
    { value: 3, label: 'Quarta-feira' },
    { value: 4, label: 'Quinta-feira' },
    { value: 5, label: 'Sexta-feira' },
    { value: 6, label: 'Sábado' },
];

function CpEditarConfiguracaoCirurgia({ configuracoes, configuracaoId, onSalvarConfiguracao }) {
    const [configuracao, setConfiguracao] = useState(estadoInicial);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const service = new ConfiguracoesCirurgiaService();

    useEffect(() => {
        if (!!configuracoes?.length && configuracaoId) {
            const configuracaoInicial = configuracoes.find(c => c.id == configuracaoId);
            setConfiguracao(configuracaoInicial);
        }
    }, [configuracaoId, configuracoes]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setConfiguracao((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const salvar = () => {
        let valido = true;
        if (!configuracao.tipoAssistido) {
            enqueueSnackbar('Informe o Tipo Assistido!', { variant: 'error' });
            valido = false;
        }
        if (!configuracao.local) {
            enqueueSnackbar('Informe o Local!', { variant: 'error' });
            valido = false;
        }
        if (!configuracao.diaDaSemana && configuracao.diaDaSemana !== 0) {
            enqueueSnackbar('Informe o Dia da Semana!', { variant: 'error' });
            valido = false;
        }
        if (!configuracao.horaInicio) {
            enqueueSnackbar('Informe a Hora Início!', { variant: 'error' });
            valido = false;
        }
        if (!configuracao.horaFim) {
            enqueueSnackbar('Informe a Hora Fim!', { variant: 'error' });
            valido = false;
        }
        if (!configuracao.quantidadeIntervalo) {
            enqueueSnackbar('Informe a Quantidade de Cirurgias por Intervalo!', { variant: 'error' });
            valido = false;
        }

        if (valido) {
            const configExistenteComTipoLocal = configuracoes && configuracoes.filter(c => c.id !== configuracao.id && c.local === configuracao.local && c.tipoAssistido === configuracao.tipoAssistido);
            if (!!configExistenteComTipoLocal.length) {
                const tipoAssistido = TipoAssistido.porDbVal(configuracao.tipoAssistido).desc;
                const local = Local.porDbVal(configuracao.local).desc;
                enqueueSnackbar(
                    `Já existe uma configuração para "${tipoAssistido}"/"${local}". Edite a configuração existente`,
                    { variant: 'error' }
                );
                valido = false;
            }
        }

        //Essa validacao somente fará sentido quando multiplas configs para o mesmo tipo e local forem habilitadas
        // if (valido) {
        //     configuracao.horaInicio = strTimeToIsoTime(configuracao.horaInicio);
        //     configuracao.horaFim = strTimeToIsoTime(configuracao.horaFim);

        //     const { id, tipoAssistido, diaDaSemana, horaInicio, horaFim, local } = configuracao;
        //     const inicio = new Date(`1970-01-01T${horaInicio}`);
        //     const fim = new Date(`1970-01-01T${horaFim}`);

        //     if (fim < inicio) {
        //         enqueueSnackbar('Horário Fim tem que ser depois do Horário Início!', { variant: 'error' });
        //         valido = false;
        //     }

        //     const colisaoDeHorario = configuracoes && configuracoes
        //         .filter(c => c.id !== id && c.local === local && c.tipoAssistido === tipoAssistido && c.diaDaSemana === diaDaSemana)
        //         .find(c => {
        //             const inicioExistente = new Date(`1970-01-01T${c.horaInicio}`);
        //             const fimExistente = new Date(`1970-01-01T${c.horaFim}`);
        //             return (inicio < fimExistente && fim > inicioExistente);
        //         });

        //         if (!!colisaoDeHorario) {
        //         const inicioExistente = isoTimeToStrTime(colisaoDeHorario.horaInicio);
        //         const fimExistente = isoTimeToStrTime(colisaoDeHorario.horaFim);
        //         enqueueSnackbar(
        //             `Já existe uma configuração para o horário das ${inicioExistente} às ${fimExistente}`,
        //             { variant: 'error' }
        //         );
        //         valido = false;
        //     }
        // }

        if (valido) {
            service.salvarConfiguracao(configuracao)
                .then(res => {
                    if (configuracao.id) {
                        history.push('/configcirurgias');
                    } else {
                        setConfiguracao(estadoInicial);
                        onSalvarConfiguracao(res);
                    }
                    enqueueSnackbar('Configuração salva com sucesso!', { variant: 'success' });
                })
                .catch(err => {
                    enqueueSnackbar('Erro ao salvar a configuração!', { variant: 'error' });
                });
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
                <FormControl fullWidth>
                    <InputLabel shrink id="tipoAssistidoLabel">
                        Tipo Assistido *
                    </InputLabel>
                    <Select
                        autoFocus
                        labelId="tipoAssistidoLabel"
                        id="tipoAssistido"
                        name="tipoAssistido"
                        value={configuracao.tipoAssistido}
                        onChange={handleChange}>
                        <MenuItem value={"H"}>
                            <TipoAssistidoHumanoIcon style={{ fontSize: '18px', verticalAlign: 'middle', margin: '2px 8px 0 0' }} />
                            Humano
                        </MenuItem>
                        <MenuItem value={"A"}>
                            <TipoAssistidoAnimalIcon style={{ fontSize: '18px', verticalAlign: 'middle', margin: '2px 8px 0 0' }} />
                            Animal
                        </MenuItem>
                        <MenuItem value={"P"}>
                            <TipoAssistidoPlantaIcon style={{ fontSize: '18px', verticalAlign: 'middle', margin: '2px 8px 0 0' }} />
                            Planta
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <TextField
                    select
                    label="Local"
                    name="local"
                    value={configuracao.local}
                    onChange={handleChange}
                    fullWidth
                    required
                >
                    <MenuItem value="ASSISTIDO">No Lar</MenuItem>
                    <MenuItem value="CONSOLADOR">Casa do Consolador</MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <TextField
                    select
                    label="Dia da Semana"
                    name="diaDaSemana"
                    value={configuracao.diaDaSemana}
                    onChange={handleChange}
                    fullWidth
                    required
                >
                    {diasDaSemana.map((dia) => (
                        <MenuItem key={dia.value} value={dia.value}>
                            {dia.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <TextField
                    label="Hora Início"
                    type="time"
                    name="horaInicio"
                    value={configuracao.horaInicio}
                    onChange={handleChange}
                    fullWidth
                    required
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <TextField
                    label="Hora Fim"
                    type="time"
                    name="horaFim"
                    value={configuracao.horaFim}
                    onChange={handleChange}
                    fullWidth
                    required
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <TextField
                    label="Quantidade de cirurgias por intervalo"
                    type="number"
                    name="quantidadeIntervalo"
                    value={configuracao.quantidadeIntervalo}
                    onChange={handleChange}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={salvar}
                    startIcon={configuracao.id ? <SaveIcon /> : <AddBoxIcon />}
                >
                    {configuracao.id ? 'Salvar' : 'Adicionar'}
                </Button>
                {configuracao.id && <>&nbsp; <Button component={Link} to={`/configcirurgias`} className="button" color="secondary">Cancelar</Button></>}
            </Grid>
        </Grid>
    );
}

export default CpEditarConfiguracaoCirurgia;