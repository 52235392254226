import {
    Paper,
    Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ConfiguracoesCirurgiaService from '../../services/configuracao_cirurgia';
import CpEditarConfiguracaoCirurgia from './cp-editar-configuracao-cirurgias';

const PgEditarConfiguracaoCirurgias = () => {
    const { id } = useParams();
    const service = new ConfiguracoesCirurgiaService();
    const history = useHistory();

    const [configuracoes, setConfiguracoes] = useState([]);

    useEffect(() => {
        if (id) {
            service.obterConfiguracoes()
                .then(res => {
                    if (!res.length || res.filter(c => c.id == id).length !== 1) {
                        history.push('/configcirurgias');
                    } else {
                        console.log(res);
                        setConfiguracoes(res);
                    }
                })
                .catch(err => {
                    history.push('/configcirurgias');
                });
        }
    }, [id]);

    return (
        <>
            <Paper className="acoes" style={{ padding: '10px 20px', margin: '10px 0', backgroundColor: '', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h4'>Editar Configuração de Cirurgias</Typography>
            </Paper>
            <Paper className="app-form">
                {!!configuracoes ? (
                    <CpEditarConfiguracaoCirurgia configuracoes={configuracoes} configuracaoId={id} />
                ) : (
                    <h2>Carregando...</h2>
                )}
            </Paper>
        </>
    );
};

export default PgEditarConfiguracaoCirurgias;