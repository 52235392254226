import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect } from 'react';

export default function CpModalAssistidos(props) {
    const [open, setOpen] = React.useState(false);
    const [assistidos, setAssistidos] = React.useState([]);
    const [redirecionar, setRedirecionar] = React.useState(false);
    const [idSelecionado, setIdSelecionado] = React.useState(0);

    useEffect(() => {
        if (props.open) {
            setOpen(true);
        }

        if (props.assistidos) {
            setAssistidos(props.assistidos);
        }

    }, [props, props.open, setOpen, setAssistidos]);

    const handleClose = () => {
        setOpen(false);
        props.onFecharModalAssistidos();
    };

    const selecionarAssistido = (id) => {
        setIdSelecionado(id);
        setRedirecionar(true);
    }

    const carregarAssistido = () => {
        window.location.href = `/assistidos/${idSelecionado}`;
    }

    return (
        <div>
            {redirecionar && carregarAssistido()}
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Existem outros assistidos com o {props.campo} informado!</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Já existem assistidos cadastrados com o {props.campo} informado.
                    Selecione um cadastro existente caso necessário, ou clique em cancelar para continuar o cadastro.
                </DialogContentText>
                <Table style={{ tableLayout: 'auto' }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Nº Controle</TableCell>
                            <TableCell>Nome</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {assistidos && assistidos.map(row => (
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                    {row.id}
                                </TableCell>
                                <TableCell>
                                    {row.nome}
                                </TableCell>
                                <TableCell align="left">
                                    <Button onClick={() => selecionarAssistido(row.id)} color="primary">
                                        Carregar
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
