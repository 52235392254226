import DateFnsUtils from '@date-io/date-fns';
import { Checkbox, FormControl, InputLabel } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import AddBoxIcon from '@material-ui/icons/AddBox';
import SaveIcon from '@material-ui/icons/Save';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import ptLocale from 'date-fns/locale/pt-BR';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Link, useHistory } from "react-router-dom";
import Atalhos from '../../pages/pg-sintomas/atalhos-teclado';
import { toISO, isoDateToJsDate } from '../../services/date-utils';
import SintomaService from '../../services/sintomas';
import { Local } from '../assistidos/enums';

export default function CpEditarSintomas({
    assistidoId,
    onAdicionarSintoma,
    sintoma
}) {
    const editando = !!sintoma;
    const { enqueueSnackbar } = useSnackbar();
    const sintomaService = new SintomaService();
    const history = useHistory();

    const descricaoRef = useRef();

    const valorInicialNovo = {
        descricao: '',
        descricaoAssistido: '',
        alta: false,
        hospitalizado: false,
        data: null,
        local: false,
    };

    const valorInicial = editando ? {
        descricao: sintoma.descricao,
        descricaoAssistido: sintoma.descricaoAssistido,
        alta: sintoma.alta,
        hospitalizado: sintoma.hospitalizado,
        data: isoDateToJsDate(sintoma.dataConsulta),
        local: Local.porDbVal(sintoma.localTratamento).boolVal,
    } : valorInicialNovo;

    const [descricao, setDescricao] = useState(valorInicial.descricao);
    const [descricaoAssistido, setDescricaoAssistido] = useState(valorInicial.descricaoAssistido);
    const [alta, setAlta] = useState(valorInicial.alta);
    const [hospitalizado, setHospitalizado] = useState(valorInicial.hospitalizado);
    const [data, setData] = useState(valorInicial.data);
    const [local, setLocal] = useState(valorInicial.local);

    const salvarRedirecionarAssistido = () => {
        salvarSintoma(`/assistidos/${assistidoId}/assistido`);
    }

    const salvarRedirecionarCirurgias = () => {
        salvarSintoma(`/assistidos/${assistidoId}/cirurgias`);
    }

    const salvarSintomaOnClick = () => {
        if (editando) {
            salvarSintoma(`/assistidos/${assistidoId}/sintomas`);
        } else {
            salvarSintoma('');
        }
    }

    const normalizarString = (str) => {
        // Remover espaços duplos e fazer trim
        return str && typeof str === 'string' ? str.replace(/\s{2,}/g, ' ').trim() : str;
    }

    const salvarSintoma = (redirectUrl) => {
        const descricaoNormalizada = normalizarString(descricao);
        const descricaoAssistidoNormalizada = normalizarString(descricaoAssistido);
        if (!descricaoNormalizada && !descricaoAssistidoNormalizada) {
            enqueueSnackbar('Informe o resumo dos sintomas ou os sintomas enviados pelo assistido!', {
                variant: 'error',
            });
            setDescricao(descricaoNormalizada);
            setDescricaoAssistido(descricaoAssistidoNormalizada);
            return;
        }
        const sintomaParaSalvar = {
            assistidoId: assistidoId,
            descricao: descricaoNormalizada,
            descricaoAssistido: descricaoAssistidoNormalizada,
            dataConsulta: toISO(data),
            alta: alta,
            hospitalizado: hospitalizado,
            localTratamento: Local.porBoolVal(local).dbVal
        };

        if (editando) {
            sintomaParaSalvar['id'] = sintoma.id;
        }

        sintomaService.salvarSintoma(sintomaParaSalvar)
            .then(res => {
                if (onAdicionarSintoma) {
                    onAdicionarSintoma(res);
                }
                const msg = editando ? 'Sintoma atualizado com sucesso!' : 'Sintoma adicionado com sucesso!';
                enqueueSnackbar(msg, {
                    variant: 'success',
                });

                if (redirectUrl) {
                    history.push(redirectUrl);
                } else {
                    setDescricao(valorInicialNovo.descricao);
                    setDescricaoAssistido(valorInicialNovo.descricaoAssistido);
                    setAlta(valorInicialNovo.alta);
                    setHospitalizado(valorInicialNovo.hospitalizado);
                    setData(valorInicialNovo.data);
                }
            })
            .catch(err => {
                enqueueSnackbar('Ocorreu um erro inesperado ao salvar o sintoma!', {
                    variant: 'error',
                });
            });
    }

    const onDataChange = (e) => {
        try {
            if (!e) {
                setData(null);
            } else {
                const data = new Date(e);
                setData(data);
            }
        } catch {

        }
    }

    useHotkeys(Atalhos.SALVAR_IR_ASSISTIDO.sequence, salvarRedirecionarAssistido, { keyup: true });
    useHotkeys(Atalhos.SALVAR_IR_CIRURGIAS.sequence, salvarRedirecionarCirurgias, { keyup: true });
    useHotkeys(Atalhos.FOCAR_DESCRICAO.sequence, () => descricaoRef.current.focus(), { keyup: true });

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={12} >
                <TextField
                    id="sintoma"
                    name="sintoma"
                    label="Resumo dos sintomas"
                    fullWidth
                    value={descricao}
                    ref={descricaoRef}
                    onChange={({ target }) => setDescricao(target.value)}
                    multiline
                    InputProps={{
                        autoComplete: 'off',
                    }}
                    inputProps={{
                        maxLength: 5000,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    autoFocus
                />
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
                <TextField
                    id="descricaoAssistido"
                    name="descricaoAssistido"
                    label="Sintomas enviados pelo assistido"
                    fullWidth
                    value={descricaoAssistido}
                    onChange={({ target }) => setDescricaoAssistido(target.value)}
                    multiline
                    InputProps={{
                        autoComplete: 'off',
                    }}
                    inputProps={{
                        maxLength: 5000,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12} lg={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                    <KeyboardDatePicker
                        id="data"
                        label="Data da consulta"
                        name="nascimento"
                        disableToolbar={true}
                        format="dd/MM/yyyy"
                        value={data}
                        onChange={(date) => onDataChange(date)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        KeyboardButtonProps={{
                            'aria-label': 'alterar data',
                        }}
                        invalidDateMessage="Formato de data inválido. Utilize dd/mm/yyyy"
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={12} lg={2}>
                <FormControl component="fieldset" style={{ width: '100%' }}>
                    <InputLabel shrink>
                        Situação do assistido
                    </InputLabel>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={hospitalizado}
                                onChange={() => setHospitalizado(!hospitalizado)}
                                value={hospitalizado}
                                color="primary"
                            />
                        }
                        label="Hospitalizado"
                        className="bottom-offset"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} lg={2}>
                <FormControl component="fieldset" style={{ width: '100%' }}>
                    <InputLabel shrink>
                        Situação do sintoma
                    </InputLabel>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={alta}
                                onChange={() => setAlta(!alta)}
                                value={alta}
                                color="primary"
                            />
                        }
                        label="Alta"
                        className="bottom-offset"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} lg={3}>
                <FormControl style={{ width: '100%' }}>
                    <InputLabel shrink >
                        Local preferencial do tratamento
                    </InputLabel>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={local}
                                onChange={() => { setLocal(!local) }}
                                value={local}
                                color="primary"
                            />
                        }
                        label="Casa do Consolador"
                        className="bottom-offset"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={salvarSintomaOnClick}
                    startIcon={editando ? <SaveIcon /> : <AddBoxIcon />}
                >
                    {editando ? 'Salvar' : 'Adicionar'}
                </Button>
                {editando && <>&nbsp; <Button component={Link} to={`/assistidos/${assistidoId}/sintomas`} className="button" color="secondary">Cancelar</Button></>}
            </Grid>
        </Grid>
    );
}
