import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';

const emailVendors = [
    'gmail.com',
    'hotmail.com',
    'yahoo.com',
    'yahoo.com.br',
    'uol.com.br',
    'outlook.com',
    'terra.com.br',
    'icloud.com',
];

const CpEmailAutocomplete = ({ id, name, label, value, fullWidth, inputRef, onChange, onBlur }) => {

    useEffect(() => {
        setInputValue(value || '');
    }, [value]);

    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        if (onChange) {
            onChange(newInputValue);
        }
        if (onChange) {
            onChange(newInputValue);
        }
        if (newInputValue.includes('@')) {
            const suggestions = emailVendors.filter(vendor => {
                const emailHint = newInputValue.split('@')[1].toLowerCase();
                return vendor.toLowerCase().startsWith(emailHint);
            }).map(suggestion => newInputValue.split('@')[0].toLowerCase() + '@' + suggestion);
            if (suggestions.length === 0 || (suggestions.length === 1 && suggestions[0] === newInputValue)) {
                setSuggestions([]);
            }
            if (suggestions.length === 0 || (suggestions.length === 1 && suggestions[0] === newInputValue)) {
                setSuggestions([]);
            }
            setSuggestions(suggestions);
        } else {
            setSuggestions([]);
        }
    };

    return (
        <Autocomplete
            id={id}
            name={name}
            freeSolo
            options={suggestions}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    fullWidth={fullWidth}
                    ref={inputRef}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onBlur={onBlur}
                />
            )}
        />
    );
};

export default CpEmailAutocomplete;