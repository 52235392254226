const locais = [
    'AA AUDITIVAS',
    'AA CEREBRAIS',
    'AA CEREBRAIS MEDIAS',
    'AA CORONARIAS',
    'AA FEMURAIS',
    'AA ILIACO-FEMORO-POPLITEA',
    'AA MMII',
    'AA PEDIOSAS',
    'AA PUDENDAS',
    'AA PULMONARES',
    'AA RENAIS',
    'AA TEMPORAIS',
    'AA TEMPORAIS/OLHOS',
    'AA VERTEBROBASILARES',
    'AA VV MMII',
    'ABDOMEN',
    'ABDOMINAL',
    'ADENOIDES',
    'ADENOIDES/AMIGDALAS',
    'ADRENAIS',
    'ADRENAIS/OVARIOS',
    'ADRENAIS/RINS',
    'ALCAS DELGADAS',
    'ALCAS INTESTINAIS',
    'ALVEOLOS',
    'AMIGDALAS',
    'AMIGDALAS/ADENOIDES',
    'ANEL LINFATICO',
    'ANEXOS',
    'ANTEBRACO DIR.',
    'ANUS',
    'AORTA',
    'AORTA ABDOMINAL',
    'AORTA TORACOABDOMINAL',
    'AP JUSTAGLOMERULARES',
    'ARCOS COSTAIS',
    'ART ESTERNOCONDRAIS',
    'ARTERIAS CEREBRAIS',
    'ARTERIAS TEMPORAIS',
    'ARTICULACAO',
    'ARTICULACAO/COLUNA',
    'ARTICULACOES',
    'ARTICULACOES/COLUNA',
    '',
    'ATMS',
    'AXILA DIR.',
    'AXILA ESQ.',
    'AXILAS',
    'BACIA',
    'BACIA/COXOFEMURAL',
    'BACO',
    'BASICO',
    'BEXIGA',
    'BEXIGA/PROSTATA',
    'BEXIGA/URETRA',
    'BOCA',
    'BOLSA ESCROTAL',
    'BRONQUIOS',
    'BRONQUIOS/PULMOES',
    'CABELUDO',
    'CALCANEO DIR.',
    'CALCANEO ESQ.',
    'CALCANEOS',
    'CALCANHARES',
    'CALCULOS BILIARES',
    'CALCULOS RENAIS',
    'CANAIS LACRIMAIS',
    'CAPILARES PULMONARES',
    'CARDIA',
    'CARDIACO',
    'CARDIACO/LABIRINTOS',
    'CARDIACO/RETINAS',
    'CARDIACO/SAFENAS',
    'CAROTIDAS',
    'CAROTIDAS/AA CEREBRAIS',
    'CAUDA EQUINA',
    'CEREBELO',
    'CEREBRO',
    'CHACRA GASTRICO',
    'CHACRA GENESICO',
    'CHACRA LARINGEO',
    'CIATICO',
    'CIATICO DIR.',
    'CIATICO ESQ.',
    'CIATICOS',
    'CIATICOS/RAMOS',
    'COCCIX',
    'COCLEA',
    'COCLEAS',
    'COCLEAS/NN AUDITIVOS',
    'COLO DO UTERO',
    'COLO UTERINO',
    'COLO UTERO/VG',
    'COLONS',
    'COLONS/DELGADO',
    'COLONS/OVARIOS',
    'COLONS/RETO',
    'COLONS/SIGMOIDE/RETO',
    'COLUNA',
    'COLUNA CERVICAL',
    'COLUNA CERVICAL/ATMS',
    'COLUNA CERVICAL/LOMBAR',
    'COLUNA CERVICAL/OMBROS',
    'COLUNA CERVICO DORSAL',
    'COLUNA CERVICO-DORSAL',
    'COLUNA DORSAL',
    'COLUNA DORSO LOMBAR',
    'COLUNA DORSO-LOMBAR',
    'COLUNA GERAL',
    'COLUNA GERAL/ARTICULACOES',
    'COLUNA GERAL/CIATICO',
    'COLUNA GERAL/CIATICOS',
    'COLUNA GERAL/JOELHOS',
    'COLUNA LOMBAR',
    'COLUNA LOMBAR/BACIA',
    'COLUNA LOMBAR/CIATICO',
    'COLUNA LOMBAR/CIATICO E',
    'COLUNA LOMBAR/CIATICOS',
    'COLUNA LOMBAR/QUADRIS',
    'COLUNA LOMBO SACRA',
    'COLUNA LOMBO-SACRA',
    'COLUNA/ARTICULACOES',
    'COLUNA/BACIA',
    'COLUNA/BACIA/COXO FEMURAL',
    'COLUNA/CIATICO',
    'COLUNA/CIATICO DIR.',
    'COLUNA/CIATICO ESQ.',
    'COLUNA/CIATICOS',
    'COLUNA/CIATICOS/JOELHOS',
    'COLUNA/COXOFEMURAIS',
    'COLUNA/ESPLENICO',
    'COLUNA/JOELHO DIR.',
    'COLUNA/JOELHO ESQ.',
    'COLUNA/JOELHOS',
    'COLUNA/MMII',
    'COLUNA/MMSS',
    'COLUNA/MMSS/MMII',
    'COLUNA/MSD',
    'COLUNA/MSE',
    'COLUNA/OMBRO DIR.',
    'COLUNA/OMBRO ESQ.',
    'COLUNA/OMBROS',
    'COLUNA/PES',
    'COLUNS',
    'CONJUNTIVAS',
    'CORACAO',
    'CORDAS VOCAIS',
    'CORNEA DIR.',
    'CORNEA ESQ.',
    'CORNEAS',
    'CORNETOS NASAIS',
    'COROANRIO',
    'CORONARIA',
    'CORONARIAS',
    'CORONARIAS/CAROTIDAS',
    'CORONARIAS/NODO SINOATRIAL',
    'CORONARIAS/VENTRICULO ESQ.',
    'CORONARIAS/VENTRICULOS',
    'CORONARIO',
    'CORONARIO/FRONTAL',
    'CORPOS CALOSOS',
    'CORPOS CAVERNOSOS',
    'COTOVELO DIR.',
    'COTOVELO ESQ.',
    'COTOVELOS',
    'COURO CABELUDO',
    'COXA DIR.',
    'COXA ESQ.',
    'COXOFEMURAIS',
    'COXOFEMURAL',
    'COXOFEMURAL DIR.',
    'COXOFEMURAL ESQ.',
    'CRADIACO',
    'CRISTALINO',
    'CRISTALINO DIR.',
    'CRISTALINO ESQ.',
    'CRISTALINOS',
    'DELGADO',
    'DELGADO/COLONS',
    'DENTES',
    'DISCOS INTERVERTEBRAIS',
    'DUODENO',
    'ENCEFALO',
    'ENCEFALO/CEREBELO',
    'ENCEFALO/MEDULA',
    'ENCEFALO/MEDULA ESPINHAL',
    'ENDOMETRIO',
    'ESFINCTER ANAL EXTERNO',
    'ESFINCTER URETRAL EXTERNO',
    'ESFINCTERES URETRAIS',
    'ESOFAGO',
    'ESOFAGO/CARDIA/ESTOMAGO',
    'ESOFAGO/ESTOMAGO',
    'ESOFAGO/ESTOMAGO/DUODENO',
    'ESPLENICO',
    'ESPLENICO/HIPOTALAMO',
    'ESPLENICO/ADRENAIS',
    'ESPLENICO/COLONS',
    'ESPLENICO/DELGADO/COLONS',
    'ESPLENICO/FIGADO',
    'ESPLENICO/MEDULA OSSEA',
    'ESPLENICO/OVARIOS',
    'ESPLENICO/PANCREAS',
    'ESPLENICO/PANCREAS/FIGADO',
    'ESPLENICO/PANCREAS/RINS',
    'ESPLENICO/PARATIREOIDES',
    'ESPLENICO/PELE',
    'ESPLENICO/PELE GERAL',
    'ESPLENICO/RINS',
    'ESPLENICO/TIMO',
    'ESTOMAGO',
    'ESTOMAGO/DUODENO',
    'ESTOMAGO/DUODENO/COLONS',
    'ESTOMAGO/DUODENO/DELGADO',
    'ESTOMAGO/DUODENO/PANCREAS',
    'ESTOMAGO/DUODENO/VESICULA',
    'ESTOMAGO/VESICULA',
    'FACE',
    'FARINGE',
    'FARINGE/LARINGE',
    'FARINGE/LARINGE/TRAQUEIA',
    'FEIXE DE HIS',
    'FEMUR DIR.',
    'FEMUR ESQ.',
    'FEMURES',
    'FETO',
    'FIGADO',
    'FIGADO/ESPLENICO',
    'FIGADO/PANCREAS',
    'FIGADO/PULMOES',
    'FIGADO/RINS',
    'FLORA INTESTINAL',
    'FOLICULOS PILOSOS COURO',
    'FRONTAL',
    'FRONTAL/LOBOS FRONTAIS',
    'GANGLIOS SIMPATICOS TORAX',
    'GARGANTA',
    'GASTRICO',
    'GENESICO',
    'GENGIVAS',
    'GERAL',
    'GERAL/CORONARIO',
    'GERAL/CORONARIO/FRONTAL',
    'GLANDULAS LACRIMAIS',
    'GLANDULAS SALIVARES',
    'GLOBOS OCULARES',
    'HALUX DIR.',
    'HALUX ESQ.',
    'HEMORROIDAS',
    'HERNIA INGUINAL BILATERAL',
    'HERNIA INGUINAL DIR HERNIA INGUINAL ESQ',
    'HERNIA UMBILICAL',
    'HIPOFISE',
    'HIPOFISE/OVARIOS',
    'HIPOTALAMO',
    'INTESTINO DELGADO',
    'INTESTINO DELGADO/COLONS',
    'JOELHO',
    'JOELHO DIR.',
    'JOELHO ESQ.',
    'JOELHOS',
    'JOELHOS/COLUNA',
    'JOELHOS/OMBROS',
    'JOELHOS/PES',
    'JOELHOS/TORNOZELOS',
    'LABIRINTOS',
    'LABIRINTOS/AA AUDITIVAS',
    'LARINGE',
    'LARINGE/TRAQUEIA',
    'LARINGEO',
    'LIGAMENTOS PARALOMBARES',
    'LIGAMENTOS PARAVERTEBRAIS',
    'LIGAMENTOS REDONDOS',
    'LIGAMENTOS REDONDOS UTERO',
    'LINFATICOS MMII',
    'LINFONODOS CERVICAIS',
    'LINGUA',
    'LOBO FRONTAL',
    'LOBO FRONTAL/PARIETAL',
    'LOBO PARIETAL DIR.',
    'LOBO PARIETAL ESQ.',
    'LOBOS FRONTAIS',
    'LOBOS FRONTAIS/PARIETAIS',
    'LOBOS PARIETAIS',
    'LOBOS TEMPORAIS',
    'LOJA PROSTATICA',
    'LOMBARES',
    'MACULAS',
    'MAMA DIR.',
    'MAMA ESQ.',
    'MAMAS',
    'MAMAS/OVARIOS',
    'MAMAS/UTERO',
    'MANDIBULA',
    'MAO DIR.',
    'MAO ESQ.',
    'MAOS',
    'MAOS/PES',
    'MASTOCITOS',
    'MASTOCITOS/TIMO',
    'MAXILARES',
    'MEDIASTINO',
    'MEDULA ESPINHAL',
    'MEDULA ESPINHAL CERVICAL',
    'MEDULA OSSEA',
    'MENINGE',
    'MENINGES',
    'MESENTERICO',
    'MID',
    'MIE',
    'MIOCARDIO',
    'MIOCARDIO/RINS',
    'MIOCARDIO/VALVULAS CARDIACAS',
    'MMII',
    'MMSS',
    'MMSS/MMII',
    'MSD',
    'MSE',
    'MUCOSA BUCAL',
    'MUCOSA NASAL',
    'MUSC ABDOMINAL',
    'MUSC PERIVESICAL',
    'MUSCULATURA ABDOMINAL',
    'MUSCULATURA COLONICA',
    'MUSCULATURA COLONS',
    'MUSCULATURA PARAVERTEBRAL',
    'MUSCULATURA PERIVESICAL',
    'MUSCULOS MMII',
    'MUSCULOS PARAVERTEBRAIS',
    'NARIZ',
    'NERVO OPTICO',
    'NERVO OPTICO DIR.',
    'NERVO OPTICO ESQ.',
    'NERVO OTICO',
    'NERVOS AUDITIVOS',
    'NERVOS FACIAIS',
    'NERVOS INTERCOSTAIS',
    'NERVOS OPTICOS',
    'NERVOS PUDENDOS',
    'NN AUDITIVOS',
    'NN OPTICOS',
    'NODO SINOATRIAL',
    'NODO SINOATRIAL/FEIXE HIS',
    'NUCLEO DA BASE',
    'NUCLEO DE WERNICK',
    'NUCLEOS DA BASE',
    'OLHO DIR.',
    'OLHO ESQ.',
    'OLHOS',
    'OLHOS/AA TEMPORAIS',
    'OLHOS/OUVIDOS',
    'OMBRO DIR.',
    'OMBRO ESQ.',
    'OMBROS',
    'OMBROS/JOELHOS',
    'ORELHAS',
    'OROFARINGE',
    'OSSOS',
    'OSSOS GERAL',
    'OSSOS MMII',
    'OUVIDO DIR.',
    'OUVIDO ESQ.',
    'OUVIDOS',
    'OVARIO',
    'OVARIO DIR.',
    'OVARIO ESQ.',
    'OVARIOS',
    'OVARIOS/ADRENAIS',
    'OVARIOS/COURO CABELUDO',
    'OVARIOS/HIPOFISE',
    'OVARIOS/PARATIREOIDES',
    'OVARIOS/TROMPAS',
    'OVARIOS/UTERO',
    'PALPEBRAS',
    'PANCREAS',
    'PANCREAS/ESPLENICO',
    'PANCREAS/ESPLENICO/RINS',
    'PANCREAS/ESTOMAGO/DUODENO',
    'PANCREAS/FIGADO',
    'PANCREAS/RINS',
    'PARATIREOIDES',
    'PARATIREOIDES/ESPLENICO',
    'PARATIREOIDES/OVARIOS',
    'PAREDE ABDOMINAL',
    'PATA TRASEIRA E',
    'PATAS',
    'PE',
    'PE DIR.',
    'PE ESQ.',
    'PELE',
    'PELE DA FACE',
    'PELE DAS MAOS',
    'PELE DAS PERNAS',
    'PELE DO ROSTO',
    'PELE DOS MMII',
    'PELE DOS PES',
    'PELE FACE',
    'PELE GERAL',
    'PELE MAOS',
    'PELE MMII',
    'PELE PES',
    'PELE ROSTO',
    'PELVE',
    'PENIS',
    'PERICARDIO',
    'PERINEO',
    'PERNA DIR.',
    'PERNA ESQ.',
    'PERNAS',
    'PES',
    'PESCOCO',
    'PILARES DIAFRAGMATICOS',
    'PINEAL',
    'PLACENTA',
    'PLACENTA/CORDAO UMBELICAL',
    'PLANTA DOS PES',
    'PLANTA PE DIR.',
    'PLANTA PE ESQ.',
    'PLEURA',
    'PLEURAS',
    'PLEXO CERVICOBRAQUIAL DIR.',
    'PLEXO CERVICOBRAQUIAL ESQ.',
    'PLEXO HEMORROIDARIO',
    'PLEXO HIPOGASTRICO',
    'PLEXO MESENTERICO',
    'PLEXO PAMPINIFORME',
    'PLEXO SACRAL',
    'PLEXO SACRAL/CIATICOS',
    'PLEXO SOLAR',
    'PLEXOS BRAQUIAIS',
    'PLEXOS CERVICOBRAQUIAIS',
    'PLEXOS MESENTERICOS',
    'PROSTATA',
    'PROSTATA/BEXIGA',
    'PULMAO',
    'PULMAO DIR.',
    'PULMAO ESQ.',
    'PULMOES',
    'PULMOES/ESPLENICO',
    'PULMOES/FIGADO',
    'PUNHO DIR.',
    'PUNHO ESQ.',
    'PUNHOS',
    'QUADRIL',
    'QUADRIS',
    'QUADRIL DIR',
    'QUADRIL ESQ',
    'QUIASMA OPTICO',
    'RAIZES DENTARIAS',
    'REGIAO CERVICAL',
    'REGIAO CERVICAL ANTERIOR',
    'REGIAO INGUINAL',
    'REGIAO INGUINAL DIR.',
    'REGIAO INGUINAL ESQ.',
    'REGIAO PERIANAL',
    'REGIAO UMBILICAL',
    'REGIOES INGUINAIS',
    'RETINA',
    'RETINA DIR.',
    'RETINA ESQ.',
    'RETINAS',
    'RETINAS/AA MMII',
    'RETO',
    'RIM DIR.',
    'RIM ESQ.',
    'RINS',
    'RINS/ADRENAIS',
    'RINS/BEXIGA',
    'RINS/BEXIGA/URETERES',
    'RINS/ESPLENICO',
    'RINS/PANCREAS',
    'RINS/URETERES',
    'RINS/URETERES/BEXIGA',
    'RINS/VIAS URINARIAS',
    'SAFENAS',
    'SEIOS DA FACE',
    'SEIOS DA FACE/ANEL LINFATICO',
    'SEPTO NASAL',
    'SIGMOIDE/RETO',
    'SISTEMA N. CENTRAL',
    'SISTEMA VENOSO MMII',
    'TALAMO',
    'TESTICULO',
    'TESTICULOS',
    'TIMICO',
    'TIMO',
    'TIMO PERISPIRITUAL',
    'TIMO/ESPLENICO',
    'TIMO/MASTOCITOS',
    'TIMPANOS',
    'TIREOIDE',
    'TIREOIDE/OVARIOS',
    'TIREOIDES',
    'TORAX',
    'TORNOZELO DIR.',
    'TORNOZELO ESQ.',
    'TORNOZELOS',
    'TRAQUEIA',
    'TRAQUEIA/BRONQUIOS',
    'TRAQUEIA/BRONQUIOS/PULMOES',
    'TROMPAS',
    'TROMPAS DE EUSTAQUIO',
    'TROMPAS/UTERO',
    'TUNEIS DO CARPO',
    'TUNEL DO CARPO',
    'ULCERA VARICOSA',
    'UMBIGO',
    'UNHAS',
    'UNHAS DOS PES',
    'URETERES/BEXIGA',
    'URETRA',
    'UTERO',
    'UTERO/ANEXOS',
    'UTERO/COLO UTERO',
    'UTERO/FETO',
    'UTERO/OVARIOS',
    'UTERO/TROMPAS',
    'VALVULA AORTICA',
    'VALVULA MITRAL',
    'VALVULA MITRAL/TRICUSPIDE',
    'VALVULAS CARDIACAS',
    'VEIA PORTA',
    'VEIAS MMII',
    'VENTRICULO ESQ.',
    'VENTRICULOS',
    'VENTRICULOS CEREBRAIS',
    'VERTEBRAS',
    'VERTEBRAS LOMBARES',
    'VERTEBRAS LOMBOSACRAS',
    'VESICULA',
    'VESICULA BILIAR',
    'VESICULA SEMINAL',
    'VESICULA/PANCREAS',
    'VG',
    'VG/COLO UTERO',
    'VISICULA',
    'VV CEREBRAIS',
    'VV CORDAO ESPERMATICO',
    'VV MID',
    'VV MIE',
    'VV MMII',
    'VV PORTA HEPATICA'
];
export default locais;
