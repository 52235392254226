import api, { URL_SINTOMAS } from "../api_env_config";

class SintomaService {

    deletarSintoma = async (id) => {
        return new Promise((resolve, reject) => {
            const url = `${URL_SINTOMAS}/${id}`;
            api.delete(url).then(response => {
                resolve(response.data.data);
            })
            .catch(err => {
                reject("Error: " + err);
            })
        })
    };

    salvarSintoma = async (sintoma) => {
        return new Promise((resolve, reject) => {
            const url = `${URL_SINTOMAS}`;
            api.post(url, sintoma).then(response => {
                resolve(response.data.data);
            })
            .catch(err => {
                reject("Error: " + err);
            })
        })
    };

    procurarSintomaPorId = async (id) => {
        return new Promise((resolve, reject) => {
            const url = `${URL_SINTOMAS}/${id}`;
            api.get(url).then(response => {
                resolve(response.data.data);
            }).catch(err => {
                reject("Error: " + err);
            })
        })
    };

}

export default SintomaService;
 