import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SaveIcon from '@material-ui/icons/Save';
import { useSnackbar, withSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import AssistidoObrigatorio from '../../components/assistido-obrigatorio';
import { Acao } from '../../components/assistidos/cp-acoes-assistido';
import CpBarraAcoes from '../../components/assistidos/cp-barra-acoes';
import CpResumoAssistido from '../../components/assistidos/cp-resumo-assistido';
import CpAjudaTratamentos from '../../components/cp-ajuda/cp-ajuda-tratamentos';
import CpTratamentos from '../../components/cp-tratamentos/cp-tratamentos';
import AssistidoService from '../../services/assistidos';
import Atalhos from './atalhos-teclado';
import './pg-tratamentos.css';
import { decorateAssistido } from '../../components/assistidos/utils';

const PgTratamentos = ({ match }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [assistido, setAssistido] = useState('');
    const [id, setId] = useState(match.params.id);
    const [mostrarAjuda, setMostrarAjuda] = useState(false);
    const cpTratamentosRef = useRef();

    const service = new AssistidoService();

    const carregarAssistido = (id) => {
        service.procurarAssistidoPorId(id)
            .then(res => {
                console.log("Assistido encontrado", res);
                setAssistido(res);
            })
            .catch(err => {
                enqueueSnackbar(`Ocorreu um problema ao obter o assistido número de controle ${id}`, {
                    variant: 'error',
                });
                console.log("Erro ao carregar assistido", err);
            });
    }

    useEffect(() => {
        if (id) {
            carregarAssistido(id);
        } else {
            enqueueSnackbar('Escolha um Assistido!', {
                variant: 'info',
            });
        }
    }, [id]);

    const ajudaOnClick = () => {
        setMostrarAjuda(!mostrarAjuda);
    }

    const ajudaOnClose = () => {
        setMostrarAjuda(!mostrarAjuda);
    }
    
    const onTratamentosUpdate = (tratamentos) =>{
        const updatedAssistido = {
            ...assistido,
            tratamentos: tratamentos
        };
        decorateAssistido(updatedAssistido);
        setAssistido(updatedAssistido);
    }
    
    useHotkeys(Atalhos.AJUDA.sequence, ajudaOnClick);

    return (
        <AssistidoObrigatorio idAssistido={id} assistido={assistido}>
            <main>
                {mostrarAjuda && <CpAjudaTratamentos onClose={ajudaOnClose} />}

                <CpResumoAssistido assistido={assistido} />

                <CpBarraAcoes assistido={assistido} destacarAcao={Acao.Tratamentos}>
                    <Button
                        variant="contained"
                        color="default"
                        size="small"
                        startIcon={<HelpOutlineIcon />}
                        onClick={ajudaOnClick}
                    >
                        Ajuda
                    </Button>
                </CpBarraAcoes>

                <CpTratamentos assistido={assistido} onTratamentosUpdate={onTratamentosUpdate}/>
            </main>
        </AssistidoObrigatorio>
    );
}

export default withSnackbar(PgTratamentos);
