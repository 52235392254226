import {
    Paper,
    Typography
} from '@material-ui/core';
import React from 'react';
import CpConfiguracoesCirurgia from './cp-configuracao-cirurgias';

const PgConfiguracoesCirurgia = () => {

    return (
        <>
            <Paper className="acoes" style={{ padding: '10px 20px', margin: '10px 0', backgroundColor: '', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h4'>Configurações Cirurgias</Typography>
            </Paper>
            <CpConfiguracoesCirurgia />
        </>
    );
};

export default PgConfiguracoesCirurgia;