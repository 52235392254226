import { FormControl, InputLabel, MenuItem, Paper, Select, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { listTratamentos } from '../cp-tratamentos/cp-lista-tratamentos';
import CpRelatorioCromo from './cp-relatorio-cromo';
import CpRelatorioTratamento from './cp-relatorio-tratamento';
import CpRelatorioVibracional from './cp-relatorio-vibracional';
import CpReportTratamentosEmailOld from '../assistidos/cp-report-tratamentos-email';

export default function CpRelatorioEmail({ assistido }) {

    const [tratamentoSelecionado, setTratamentoSelecionado] = useState(null);

    useEffect(() => {
        if (!!assistido.tratamentos?.length) {
            setTratamentoSelecionado(assistido.tratamentos[0] || null);
        }
    }, [assistido]);

    const handleTratamentoChange = (event) => {
        const tratamento = assistido.tratamentos.find(t => t.id === event.target.value);
        setTratamentoSelecionado(tratamento);
    }

    if (!assistido.tratamentos?.length) {
        return (
            <Paper style={{ margin: '5px 0' }}>
                <Grid container style={{ padding: '20px' }} >
                    <Typography variant='subtitle2'>Não existem tratamentos cadastrados. Cadastre os tratamentos para visualizar o relatório</Typography>
                </Grid>
            </Paper>
        )
    }

    return (
        <>
            <Paper style={{ margin: '5px 0' }}>
                <Grid container style={{ padding: '20px' }} >
                    <Grid item xs={12} lg={12}>
                        <FormControl fullWidth>
                            <InputLabel id="tratamento-label">Tratamento</InputLabel>
                            <Select
                                labelId="tratamento-label"
                                value={tratamentoSelecionado?.id || ''}
                                onChange={handleTratamentoChange}
                            >
                                {assistido.tratamentos.map((tratamento) => (
                                    <MenuItem key={tratamento.id} value={tratamento.id}>
                                        {tratamento.dataPrescricao} - {listTratamentos(tratamento).slice(0, 80)}{listTratamentos(tratamento).length > 80 && '...'}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Paper>

            <Paper style={{ margin: '5px 0' }} elevation={3}>
                <Grid container style={{ padding: '20px' }} >
                    <CpRelatorioTratamento assistido={assistido} tratamento={tratamentoSelecionado} />
                    <CpReportTratamentosEmailOld assistido={assistido} tratamento={tratamentoSelecionado} />
                </Grid>
            </Paper>
        </>
    );
}
