// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report-table td,
.report-table th {
    border: 1px solid black;
}

.report-table {
    border-collapse: collapse;
    width: 100%;
}
.report-table td{
    padding: 0 2px;
}
`, "",{"version":3,"sources":["webpack://./src/components/report/estilo-relatorio.css"],"names":[],"mappings":"AAAA;;IAEI,uBAAuB;AAC3B;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;AACA;IACI,cAAc;AAClB","sourcesContent":[".report-table td,\n.report-table th {\n    border: 1px solid black;\n}\n\n.report-table {\n    border-collapse: collapse;\n    width: 100%;\n}\n.report-table td{\n    padding: 0 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
