export const translatedCountries = {
    Afghanistan: 'Afeganistão',
    Albania: 'Albânia',
    Algeria: 'Argélia',
    'American Samoa': 'Samoa Americana',
    Andorra: 'Andorra',
    Angola: 'Angola',
    Anguilla: 'Anguilla',
    'Antigua and Barbuda': 'Antígua e Barbuda',
    Argentina: 'Argentina',
    Armenia: 'Armênia',
    Aruba: 'Aruba',
    Australia: 'Austrália',
    Austria: 'Áustria',
    Azerbaijan: 'Azerbaijão',
    Bahamas: 'Bahamas',
    Bahrain: 'Bahrein',
    Bangladesh: 'Bangladesh',
    Barbados: 'Barbados',
    Belarus: 'Bielorrússia',
    Belgium: 'Bélgica',
    Belize: 'Belize',
    Benin: 'Benin',
    Bermuda: 'Bermuda',
    Bhutan: 'Butão',
    Bolivia: 'Bolívia',
    'Bosnia and Herzegovina': 'Bósnia e Herzegovina',
    Botswana: 'Botswana',
    Brazil: 'Brasil',
    'British Indian Ocean Territory': 'Território Britânico do Oceano Índico',
    Brunei: 'Brunei',
    Bulgaria: 'Bulgária',
    'Burkina Faso': 'Burkina Faso',
    Burundi: 'Burundi',
    'Cape Verde': 'Cabo Verde',
    Cambodia: 'Camboja',
    Cameroon: 'Camarões',
    Canada: 'Canadá',
    'Cayman Islands': 'Ilhas Caimã',
    'Central African Republic': 'República Centro-Africana',
    Chad: 'Chade',
    Chile: 'Chile',
    China: 'China',
    Colombia: 'Colômbia',
    Comoros: 'Comores',
    'Congo - Kinshasa': 'Congo - Kinshasa',
    'Congo - Brazzaville': 'Congo - Brazzaville',
    'Cook Islands': 'Ilhas Cook',
    'Costa Rica': 'Costa Rica',
    'Côte d’Ivoire': 'Costa do Marfim',
    Croatia: 'Croácia',
    Cuba: 'Cuba',
    Cyprus: 'Chipre',
    'Czech Republic': 'República Tcheca',
    Denmark: 'Dinamarca',
    Djibouti: 'Djibouti',
    Dominica: 'Dominica',
    'Dominican Republic': 'República Dominicana',
    Ecuador: 'Equador',
    Egypt: 'Egito',
    'El Salvador': 'El Salvador',
    'Equatorial Guinea': 'Guiné Equatorial',
    Eritrea: 'Eritreia',
    Estonia: 'Estônia',
    Eswatini: 'Essuatíni',
    Ethiopia: 'Etiópia',
    'Falkland Islands': 'Ilhas Falkland',
    'Faroe Islands': 'Ilhas Faroe',
    Fiji: 'Fiji',
    Finland: 'Finlândia',
    France: 'França',
    'French Guiana': 'Guiana Francesa',
    'French Polynesia': 'Polinésia Francesa',
    Gabon: 'Gabão',
    Gambia: 'Gâmbia',
    Georgia: 'Geórgia',
    Germany: 'Alemanha',
    Ghana: 'Gana',
    Gibraltar: 'Gibraltar',
    Greece: 'Grécia',
    Greenland: 'Groenlândia',
    Grenada: 'Granada',
    Guadeloupe: 'Guadalupe',
    Guam: 'Guam',
    Guatemala: 'Guatemala',
    Guinea: 'Guiné',
    'Guinea-Bissau': 'Guiné-Bissau',
    Guyana: 'Guiana',
    Haiti: 'Haiti',
    Honduras: 'Honduras',
    'Hong Kong': 'Hong Kong (RAE da China)',
    Hungary: 'Hungria',
    Iceland: 'Islândia',
    India: 'Índia',
    Indonesia: 'Indonésia',
    Iran: 'Irã',
    Iraq: 'Iraque',
    Ireland: 'Irlanda',
    Israel: 'Israel',
    Italy: 'Itália',
    Jamaica: 'Jamaica',
    Japan: 'Japão',
    Jordan: 'Jordânia',
    Kazakhstan: 'Cazaquistão',
    Kenya: 'Quênia',
    Kiribati: 'Kiribati',
    'Korea, North': 'Coreia do Norte',
    'Korea, South': 'Coreia do Sul',
    Kuwait: 'Kuwait',
    Kyrgyzstan: 'Quirguistão',
    Laos: 'Laos',
    Latvia: 'Letônia',
    Lebanon: 'Líbano',
    Lesotho: 'Lesoto',
    Liberia: 'Libéria',
    Libya: 'Líbia',
    Liechtenstein: 'Liechtenstein',
    Lithuania: 'Lituânia',
    Luxembourg: 'Luxemburgo',
    Macau: 'Macau (RAE da China)',
    'North Macedonia': 'Macedônia do Norte',
    Madagascar: 'Madagáscar',
    Malawi: 'Malawi',
    Malaysia: 'Malásia',
    Maldives: 'Maldivas',
    Mali: 'Mali',
    Malta: 'Malta',
    'Marshall Islands': 'Ilhas Marshall',
    Martinique: 'Martinica',
    Mauritania: 'Mauritânia',
    Mauritius: 'Maurício',
    Mayotte: 'Mayotte',
    Mexico: 'México',
    Micronesia: 'Micronésia',
    Moldova: 'Moldávia',
    Monaco: 'Mônaco',
    Mongolia: 'Mongólia',
    Montenegro: 'Montenegro',
    Montserrat: 'Montserrat',
    Morocco: 'Marrocos',
    Mozambique: 'Moçambique',
    Myanmar: 'Mianmar (Birmânia)',
    Namibia: 'Namíbia',
    Nauru: 'Nauru',
    Nepal: 'Nepal',
    Netherlands: 'Países Baixos',
    'New Caledonia': 'Nova Caledônia',
    'New Zealand': 'Nova Zelândia',
    Nicaragua: 'Nicarágua',
    Niger: 'Níger',
    Nigeria: 'Nigéria',
    Niue: 'Niue',
    'Norfolk Island': 'Ilha Norfolk',
    'Northern Mariana Islands': 'Ilhas Marianas do Norte',
    Norway: 'Noruega',
    Oman: 'Omã',
    Pakistan: 'Paquistão',
    Palau: 'Palau',
    'Palestinian Territories': 'Territórios Palestinos',
    Panama: 'Panamá',
    'Papua New Guinea': 'Papua-Nova Guiné',
    Paraguay: 'Paraguai',
    Peru: 'Peru',
    Philippines: 'Filipinas',
    Poland: 'Polônia',
    Portugal: 'Portugal',
    'Puerto Rico': 'Porto Rico',
    Qatar: 'Catar',
    Réunion: 'Reunião',
    Romania: 'Romênia',
    Russia: 'Rússia',
    Rwanda: 'Ruanda',
    Samoa: 'Samoa',
    'San Marino': 'San Marino',
    'São Tomé and Príncipe': 'São Tomé e Príncipe',
    'Saudi Arabia': 'Arábia Saudita',
    Senegal: 'Senegal',
    Serbia: 'Sérvia',
    Seychelles: 'Seicheles',
    'Sierra Leone': 'Serra Leoa',
    Singapore: 'Singapura',
    'Sint Maarten': 'Sint Maarten',
    Slovakia: 'Eslováquia',
    Slovenia: 'Eslovênia',
    'Solomon Islands': 'Ilhas Salomão',
    Somalia: 'Somália',
    'South Africa': 'África do Sul',
    'South Sudan': 'Sudão do Sul',
    Spain: 'Espanha',
    'Sri Lanka': 'Sri Lanka',
    Sudan: 'Sudão',
    Suriname: 'Suriname',
    Sweden: 'Suécia',
    Switzerland: 'Suíça',
    Syria: 'Síria',
    Taiwan: 'Taiwan',
    Tajikistan: 'Tajiquistão',
    Tanzania: 'Tanzânia',
    Thailand: 'Tailândia',
    'Timor-Leste': 'Timor-Leste',
    Togo: 'Togo',
    Tokelau: 'Tokelau',
    Tonga: 'Tonga',
    'Trinidad and Tobago': 'Trinidad e Tobago',
    Tunisia: 'Tunísia',
    Turkey: 'Turquia',
    Turkmenistan: 'Turcomenistão',
    Tuvalu: 'Tuvalu',
    Uganda: 'Uganda',
    Ukraine: 'Ucrânia',
    'United Arab Emirates': 'Emirados Árabes Unidos',
    'United Kingdom': 'Reino Unido',
    'United States': 'Estados Unidos',
    Uruguay: 'Uruguai',
    Uzbekistan: 'Uzbequistão',
    Vanuatu: 'Vanuatu',
    'Vatican City': 'Vaticano',
    Venezuela: 'Venezuela',
    Vietnam: 'Vietnã',
    'Wallis and Futuna': 'Wallis e Futuna',
    'Western Sahara': 'Saara Ocidental',
    Yemen: 'Iêmen',
    Zambia: 'Zâmbia',
    Zimbabwe: 'Zimbábue'
};