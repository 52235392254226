import React from 'react';
import CpPesquisarAssistidos from '../../components/assistidos/cp-pesquisar-assistidos';

const PesquisarAssistidos = () => {
    return (
        <CpPesquisarAssistidos pesquisarAoCarregar={false} />
    );
}

export default PesquisarAssistidos;
