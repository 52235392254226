
import { Grid } from '@material-ui/core';
import React from 'react';


export const renderText = (texto) => {
    return (
        <>
            <Grid item xs={12} lg={12}>
                <p style={{ textAlign: 'justify' }} >{texto}</p>
            </Grid>
        </>
    );
};

export const renderInfo = (titulo, texto) => {
    return (
        <>
            <Grid item xs={12} lg={12}>
                <h3>
                    {titulo}
                </h3>
            </Grid>
            <Grid item xs={12} lg={12}>
                <p style={{ textAlign: 'justify' }} >{texto}</p>
            </Grid>
        </>
    );
};

export const renderTitle = (texto) => {
    return (
        <>
            <Grid item xs={12} lg={12} style={{ textAlign: 'center' }}>
                <h3 style={{ margin: '5px 0' }}>
                    {texto}
                </h3>
            </Grid>
        </>
    );
};
export const renderSubTitle = (texto) => {
    return (
        <>
            <Grid item xs={12} lg={12} style={{ textAlign: 'center' }}>
                <h4 style={{ margin: '5px 0' }}>
                    {texto}
                </h4>
            </Grid>
        </>
    );
};