import Grid from '@material-ui/core/Grid';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import TratamentosService from '../../services/tratamentos';
import CpListaTratamentos from './cp-lista-tratamentos';
import CpEditarTratamentos from './cp-editar-tratamentos';
import { Paper, Typography } from '@material-ui/core';


function CpTratamentos({ assistido, onTratamentosUpdate }) {
    const [tratamentos, setTratamentos] = useState(assistido.tratamentos);

    const service = new TratamentosService();

    const atualizarTratamentos = (tratamentos) => {
        setTratamentos(tratamentos);
        if (onTratamentosUpdate) {
            onTratamentosUpdate(tratamentos);
        }
    };

    return (
        <>
            <Paper className="app-form">
                <CpEditarTratamentos assistidoId={assistido.id} onSalvar={atualizarTratamentos} />
            </Paper>
            <Paper className="app-form" style={{ margin: '5px 0' }}>
                <CpListaTratamentos
                    lista={tratamentos}
                    onAtualizarLista={atualizarTratamentos}
                />
            </Paper>
        </>
    );
}

export default CpTratamentos;